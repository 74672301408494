@use '@carbon/type/scss/font-family';
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp-ag--columns__header {
    font-weight: font-family.font-weight('semibold');
    padding-bottom: $spacing-05;
    margin-bottom: $spacing-05;
    border-bottom: 1px solid $border-subtle-01;
}

.hrp-ag--columns__option {
    margin-bottom: $spacing-03;
}

.hrp-ag--columns__menu-content {
    margin-bottom: $spacing-05;
}

.hrp-ag--columns__action-dropdown-footer-secondary-actions {
    padding-bottom: $spacing-03;
}
