@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;
@use '../../carbon/custom/variables' as *;
@use '../../carbon/custom/tokens' as *;
@use '@carbon/colors';

// Catalog List
.#{$prefix}--catalog-item {
    border: 1px solid $border-subtle-01;
    padding: $spacing-05;
    display: flex;
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;

    @include breakpoint-down(md) {
        width: 100%;
        max-width: $card-width;
        flex: none !important;
    }

    @include breakpoint-down(lg) {
        flex-wrap: wrap;
        flex-direction: row;
        position: relative;
        flex: 0 0 $card-width;
        max-width: $card-width;

        &__wrapper--list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .swatch__wrapper,
        .swatch-select {
            width: 100%;
        }
    }

    @include breakpoint-up(md) {
        &__wrapper--list {
            margin: 0 -#{$spacing-05};
        }
    }

    @media (min-width: convert.rem(450px)) {
        margin-left: $spacing-05;
        margin-right: $spacing-05;
    }
}

.#{$prefix}--catalog-item__wrapper--grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: -#{$spacing-05};

    @include breakpoint-down(md) {
        justify-content: center;
    }

    @include breakpoint-up(lg) {
        margin-left: -#{$spacing-05};
        margin-right: -#{$spacing-05};
        width: inherit;
    }
}

.#{$prefix}--catalog-item__short-description {
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: break-word;
    font-size: 0.8rem;
    line-height: 1rem;
    margin-bottom: $spacing-03;
    overflow: hidden;
    color: $text-tertiary;
}

// Add to cart wrapper
.#{$prefix}--catalog-item__add-to-cart-wrapper {
    align-self: stretch;
    display: grid;
    grid-template-areas: 'price quantity-unit' 'used-price used-price' 'actions actions';
    grid-template-rows: min-content min-content 1fr;
    column-gap: $spacing-01;
    row-gap: $spacing-01;
}

.#{$prefix}--catalog-item__price,
.#{$prefix}--catalog-item__price-wrapper {
    grid-area: price;
}

.#{$prefix}--catalog-item__price-wrapper {
    height: 28px;
}

.#{$prefix}--catalog-item__quantity-unit {
    grid-area: quantity-unit;
    padding-top: 6px;
    line-height: 20px;
    padding-left: 10px;
    position: relative;
    font-weight: font-family.font-weight('semibold');
}

.#{$prefix}--catalog-item__quantity-unit:before {
    content: '/';
    padding-right: $spacing-02;
    font-size: 12px;
    font-weight: font-family.font-weight('semibold');
    position: absolute;
    left: 2px;
    bottom: 3px;
}

.#{$prefix}--catalog-item__used-price {
    grid-area: used-price;
}

.#{$prefix}--catalog-item__actions {
    grid-area: actions;
}
// end of add to cart wrapper

.#{$prefix}--catalog-item--type-related .#{$prefix}--catalog-item {
    max-width: 256px;
    background: colors.$white;
}

.#{$prefix}--catalog-item--highlighted {
    background-color: $layer-02;

    .#{$prefix}--number input[type=number] {
        border-color: $background;
        background-color: $background;
    }
}

.#{$prefix}--catalog-item--list,
.#{$prefix}--catalog-item--grid {
    .#{$prefix}--catalog-item__name {
        margin-bottom: $spacing-04;
        @include type-style('body-short-01');

        a {
            color: $text-primary;
            text-decoration: none;
        }
    }

    .#{$prefix}--catalog-item__additional-info {
        @include type-style('label-01');
    }

    .#{$prefix}--catalog-item__additional {
        white-space: nowrap;
    }

    .#{$prefix}--catalog-item__actions-secondary .button--icon {
        background-color: #fff;
        height: 40px;
        border-radius: 20px;

        &:hover,
        &:active {
            background-color: $layer-01;
        }
    }

    .#{$prefix}--catalog-item__actions-secondary .button__icon * {
        fill: $button-primary !important;
    }

    .#{$prefix}--catalog-item__add-to-cart-wrapper {
        flex: 0 0 convert.rem(230px);
        max-width: convert.rem(230px);
    }

    .#{$prefix}--catalog-item__stock-wrapper {
        margin-left: $spacing-03;

        .#{$prefix}----tooltip__trigger--definition {
            border-bottom: none;
        }
    }
}


.#{$prefix}--catalog-item--list,
.#{$prefix}--catalog-item--grid,
body {
    .#{$prefix}--catalog-item__stock-info-text {
        @include type-style('body-short-01');
        font-weight: font-family.font-weight('semibold');
    }

    .#{$prefix}--catalog-item__stock-info-icon {
        margin-left: $spacing-02;
    }

    .#{$prefix}--catalog-item__stock-info--green .#{$prefix}--catalog-item__stock-info-text {
        color: $support-success;
    }

    .#{$prefix}--catalog-item__stock-info--gray .#{$prefix}--catalog-item__stock-info-text {
        color: $text-tertiary;
    }
}

.#{$prefix}--catalog-item--list {
    @include breakpoint-up(lg) {
        align-items: center;

        .#{$prefix}--catalog-item__image-wrapper {
            width: 180px;
            flex: 0 0 180px;

            img {
                max-width: 100%;
            }
        }

        .#{$prefix}--catalog-item__main {
            flex-grow: 1;
            margin: 0 $spacing-05;
            width: 100%;
        }

        .#{$prefix}--catalog-item__actions {
            align-self: flex-end;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .#{$prefix}--catalog-item__add-to-cart-wrapper {
            align-self: stretch;
            text-align: right;
            grid-template-columns: 1fr min-content;
        }

        .#{$prefix}--catalog-item__actions-secondary {
            white-space: nowrap;
        }

        .#{$prefix}--catalog-item__secondary-actions {
            display: flex;
            align-items: center;
            margin-top: $spacing-04;
        }

        .#{$prefix}--catalog-item__sku:after {
            content: ' |';
        }

        .#{$prefix}--catalog-item__sku-wrapper {
            @include type-style('body-short-01');
            font-weight: font-family.font-weight('semibold');
        }
    }
}

@mixin mobile-catalog-item {
    .#{$prefix}--catalog-item__main {
        margin-top: $spacing-02;
        height: fit-content;
        align-self: flex-end;
    }

    .#{$prefix}--catalog-item__name a {
        @include type-style('body-short-02');
        font-weight: font-family.font-weight('semibold');
    }

    .#{$prefix}--catalog-item__image-wrapper,
    .#{$prefix}--catalog-item__main,
    .#{$prefix}--catalog-item__add-to-cart-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .#{$prefix}--catalog-item__image-wrapper .product-image-photo {
        width: 260px;
        height: 160px;
        object-fit: contain;
    }

    .#{$prefix}--catalog-item__actions-secondary {
        position: absolute;
        top: $spacing-04;
        right: $spacing-04;
        text-align: right;
    }

    .#{$prefix}--catalog-item__tag-wrapper {
        margin-top: $spacing-04;
        margin-left: -#{$spacing-02};
    }

    .#{$prefix}--catalog-item__stock-wrapper {
        margin-top: $spacing-04;
        margin-left: 0;
    }

    .#{$prefix}--catalog-item__sku {
        display: block;
    }

    .#{$prefix}--catalog-item__price {
        .price-box .price {
            display: flex;
            align-items: center;

            &__value--old {
                margin-left: $spacing-02;
            }
        }
    }

    .#{$prefix}--catalog-item__sku-wrapper {
        @include type-style('label-01');
        color: $text-secondary;
        font-weight: font-family.font-weight('semibold');
    }

    .#{$prefix}--catalog-item__add-to-cart-wrapper {
        grid-template-columns: min-content 1fr;
        height: min-content;
        align-self: flex-end;
    }

    .#{$prefix}--catalog-item__actions {
        margin-top: $spacing-02;
    }
}

.#{$prefix}--catalog-item--list {
    @include breakpoint-down(lg) {
        @include mobile-catalog-item();
    }
}

.#{$prefix}--catalog-item--grid {
    flex-wrap: wrap;
    flex: 0 0 $card-width;
    max-width: $card-width;

    .#{$prefix}--catalog-item__image img {
        width: 254px;
        height: 160px;
    }

    @include mobile-catalog-item();
}
