@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;
@use '@carbon/type/scss/font-family';

@import 'extends/accordion';
@import 'extends/buttons';
@import 'extends/form-elements';
@import 'extends/link';
@import 'extends/number-input';
@import 'extends/overflow-menu';
@import 'extends/select';
@import 'extends/tags';
@import 'extends/tabs';
@import 'extends/tile';
@import 'extends/tooltips';
@import 'extends/dropdown';

// Typography
.#{$prefix}--heading-5 {
    @include type-style('productive-heading-02');
    margin-bottom: $spacing-06;
}

// Common
.#{$prefix}--color-green {
    color: $support-success;
}

.#{$prefix}--content-switcher {
    height: auto;
    margin-bottom: 1rem;
}

.#{$prefix}--stock-info {
    &, &__label {
        font-size: .875rem;
    }
}

.#{$prefix}--block-header {
    background: $layer-01;
    padding: $spacing-05 0 $spacing-06 $spacing-05;
}

.#{$prefix}--block-header__title {
    @include type-style('productive-heading-03');
    color: $text-primary;
}

.#{$prefix}--block-header__description {
    @include type-style('body-short-01');
    color: $text-secondary;
}

.#{$prefix}--content-block {
    padding: var(--content-padding);
}

.#{$prefix}--btn--text-black {
    color: $text-primary;
}

.#{$prefix}--btn__collapsible {
    & > &__collapse-icon, &--active > &__expand-icon {
        display: none;
    }

    &--active > &__collapse-icon {
        display: inline;
    }

    @include breakpoint-up(lg) {
        &--active {
            position: relative;
        }

        &--active &__triangle-wrapper {
            position: absolute;
            z-index: 10000; // TODO: bookmarks
            bottom: -10px;
            left: 50%;
            filter: drop-shadow(0 -2px 2px rgba(0,0,0,.05));
        }

        &--active &__triangle {
            display: block;
            width: 12px;
            height: 6px;
            margin: 0 -6px;
            background-color: $background;
            clip-path: polygon(50% 0,0 100%,100% 100%);
        }
    }
}

.#{$prefix}--btn--same-size {
    white-space: nowrap;
    margin-left: 0;
    padding-right: 12px;
    width: 100%;
    text-align: center;

    span {
        margin: 0 auto;
    }
}

.#{$prefix}--structured-list--product-compare {
    .#{$prefix}--structured-list-td {
        padding: $spacing-04 0;
        vertical-align: middle;
    }

    .#{$prefix}--structured-list-th {
        padding: 0;
        height: auto;
    }

    .#{$prefix}--structured-list-th--shipping-radio {
        padding-left: 0;
        padding-right: 0;
        width: 30px;
    }

    .#{$prefix}--structured-list-th--shipping-info {
        width: calc(100% - 140px);
    }

    .#{$prefix}--structured-list-th--shipping-actions {
        width: 80px;
    }
}

.#{$prefix}--structured-list-row {
    &--unreaded {
        border-left: 4px solid $button-primary-active;
    }

    /* disable hover on .#{$prefix}--structured-list-row--message */
    .#{$prefix}--structured-list--selection &--message:hover:not(&--header-row):not(&--selected) {
        background-color: inherit;
        border-color: inherit;
    }
}

.#{$prefix}--navigation--notification .#{$prefix}--structured-list-td {
    overflow-wrap: anywhere;
}

// modal
.#{$prefix}--modal-header {
    &--with-switcher {
        position: relative;
    }

    &__language {
        position: absolute;
        top: $spacing-05;
        right: $spacing-05;
    }
}
// end of modal

.#{$prefix}--divider {
    width: 0;
    height: 22px;
    border-right: 1.5px solid $border-subtle-01;

    &--floated {
         margin: 0 $spacing-04;
    }
}

.#{$prefix}--dialog {
    &__header {
        padding-bottom: $spacing-05;
        margin-bottom: $spacing-05;
        border-bottom: 1px solid $border-subtle-01;
        display: flex;
        justify-content: space-between;
        line-height: 1.2;
    }

    &__header-title,
    &__list-title,
    strong,
    a {
        color: $text-primary;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__list--main {
        display: flex;
        flex-wrap: wrap;
    }

    &__list--sublist {
        margin-bottom: $spacing-05;
    }

    &__list-item {
        min-width: 220px;
    }

    &__list-title {
        font-weight: font-family.font-weight('semibold');
        display: block;
        color: $text-primary;
    }

    &__list--sublist &__list-item,
    &__list-title,
    &__list--sublist .nav.item {
        margin-bottom: $spacing-03;
        line-height: convert.rem(18px);
    }
}

// responsive radio-button-group
.#{$prefix}--radio-button-group--responsive {
    @include breakpoint-down(md) {
        flex-wrap: wrap;

        .#{$prefix}--radio-button-wrapper {
            flex: 0 0 100%;
            margin-top: $spacing-02;
            margin-bottom: $spacing-02;
        }

        .#{$prefix}--radio-button__label {
            justify-content: flex-start !important;
        }
    }
}

// responsive
.#{$prefix}--content-switcher--responsive {
    @include breakpoint-down(md) {
        flex-wrap: wrap;

        .#{$prefix}--content-switcher-btn {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .#{$prefix}--content-switcher-btn:first-child {
            border-radius: convert.rem(4px) convert.rem(4px) 0 0;
        }

        .#{$prefix}--content-switcher-btn:last-child {
            border-radius: 0 0 convert.rem(4px) convert.rem(4px);
        }
    }
}


// modal
.#{$prefix}--modal-footer a.#{$prefix}--btn {
    max-width: none;
    -webkit-box-flex: 1;
    flex: 1;
    height: 4rem;
    margin: 0;
}

.#{$prefix}--modal-footer a.#{$prefix}--btn,
.#{$prefix}--modal-footer button.#{$prefix}--btn {
    padding-top: 0;
    padding-bottom: 0;
}

// content-switcher
.#{$prefix}--content-switcher-btn {
    justify-content: center;

    &.#{$prefix}--content-switcher--selected {
        background-color: $button-primary;
    }
}

// colorized icons
.#{$prefix}--icon {
    &--error {
        fill: $support-error;
    }

    &--success {
        fill: $support-success;
    }

    &--info {
        fill: $support-info;
    }

    &--notice,
    &--warning {
        fill: $support-warning;
    }
}

// table
.#{$prefix}--table {
}

.#{$prefix}--td--heading {
    vertical-align: middle;
    padding-right: $spacing-04;
}

.#{$prefix}--th {
    font-weight: font-family.font-weight('semibold');
}

.#{$prefix}--th--vb {
    vertical-align: bottom;
}

.#{$prefix}--th--rotate {
    height: 140px;
    white-space: nowrap;

    div {
        transform: translate(-5px, 110px) rotate(270deg);
        width: 30px;
    }
}

// range
.#{$prefix}--slider__range-label:last-of-type {
    margin-right: 0;
}

.#{$prefix}--slider__range-label {
    font-family: font-family('sans');
}
// end of range
