@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.blog-post-view {
    .blog-post__meta {
        margin-top: 0;

        @include breakpoint-up(lg) {
            display: flex;

            > *:not(:last-child):after {
                content: '|';
                padding: 0 $spacing-04;
            }

            .blog-post-view__meta-label {
                display: inline !important;
            }
        }
    }

    &__featured-image-wrapper {
        margin-bottom: $spacing-05;
    }

    &__featured-image {
        width: 100%;
    }

    &__product-item {
        max-width: 256px;
    }

    &__product-item img {
        max-width: 100%;
    }

    &__footer {
        margin-top: $spacing-05;
    }

    &__description [data-content-type="row"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
