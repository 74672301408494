@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;

.#{$prefix}--accordion__item--lg {
    border-top: none;
    margin-bottom: $spacing-05;

    .#{$prefix}--accordion__heading {
        background-color: $layer-02;
        padding: convert.rem(12px) 0;
    }

    &.#{$prefix}--accordion__item--active .#{$prefix}--accordion__content--checkout-step {
        border-color: $layer-02;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        padding: $spacing-05;
        display: block;
    }

    &.#{$prefix}--accordion__item--active .#{$prefix}--accordion__content--checkout-products {
        display: block;
        padding: 0;
    }

    &:last-child {
        border-bottom: none;
    }
}

.#{$prefix}--accordion__item--simple,
.#{$prefix}--accordion__item--simple:last-child {
    border-top: none;
    border-bottom: none;
}

.#{$prefix}--accordion__item--simple .#{$prefix}--accordion__heading:hover:before {
    display: none;
}

.#{$prefix}--accordion__item--simple,
.#{$prefix}--accordion__item--simple:last-child {
    border-top: none;
    border-bottom: none;
}

.#{$prefix}--accordion__item--simple .#{$prefix}--accordion__heading:hover:before {
    display: none;
}

@include breakpoint-up(lg) {
    .#{$prefix}--accordion__item--simple .#{$prefix}--accordion__arrow {
        display: none;
    }

    .#{$prefix}--accordion__item--simple .#{$prefix}--accordion__heading {
        pointer-events: none;
    }
}

.#{$prefix}--accordion__heading--static {
    cursor: inherit;

    &:hover:before {
        background-color: inherit;
    }
}

.#{$prefix}--accordion__arrow--disable-rotate {
    transform: inherit !important;
}

.#{$prefix}--accordion__heading--lg {
    padding: $spacing-04 0;
}

.#{$prefix}--accordion__arrow--pointer {
    cursor: pointer;
}

.#{$prefix}--accordion__icon {
    margin-left: $spacing-05;
}

.#{$prefix}--accordion__title--lg {
    flex: 0 0 150px;
    font-weight: font-family.font-weight('semibold');
    white-space: nowrap;

    @include breakpoint-down(lg) {
        margin-right: auto;
    }
}

.#{$prefix}--accordion__title--info {
    @include ellipsis;

    @include breakpoint-down(lg) {
        display: none;
    }
}

.#{$prefix}--accordion__text {
    @include type-style('body-long-01');
}

.#{$prefix}--accordion__content--checkout-step,
.#{$prefix}--accordion__content--checkout-products {
    display: none;
    border: none;
}

.#{$prefix}--accordion__content--related {
    padding-right: 0;
    padding-left: 0;
}

.#{$prefix}--accordion--light {
    .#{$prefix}--accordion__heading {
        background: transparent;
        padding: 0;
    }

    .#{$prefix}--accordion__title {
        margin-left: 0;
    }

    .#{$prefix}--accordion__content.#{$prefix}--accordion__content--checkout-step {
        border: none;
        padding: 0;
    }

    .#{$prefix}--fieldset {
        margin-bottom: 3rem;
    }

    .#{$prefix}--form-item {
        margin-bottom: 2rem;
    }
}
