@use '@carbon/layout/scss/spacing' as *;

.hrp--countdown__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px !important;
}

.hrp--countdown__wrapper .hrp--countdown__title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: $spacing-03;
}
