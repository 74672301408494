@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../carbon/custom/config' as *;

.#{$prefix}--btn-link {
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    text-decoration: none;
    cursor: pointer;

    @include breakpoint-down(lg) {
        margin: 0;
        padding: $spacing-04;
        height: min-content;
    }
}

.#{$prefix}--btn-link:hover {
    text-decoration: underline;
}

.#{$prefix}--btn-link__icon {
    width: 16px;
    height: 16px;
    margin: 1px 0;
    display: inline-block;
    margin-right: $spacing-03;
    fill: $button-primary;

    @include breakpoint-down(lg) {
        margin: 0;
    }
}

.#{$prefix}--btn-link__label {
    line-height: 18px;
    display: inline-block;
    color: $text-primary;
}
