@use '@carbon/motion' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/type/scss/font-family';

.navigation {
    .menu-icon {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        fill: #B6CAD7;
    }

    .category-item > a, .all-category > a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 42px;
    }

    a.level-top,
    .category-head a {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    @include breakpoint-down(lg) {
        li.parent > a.level-top {
            pointer-events: none;
        }

        .category-item.parent > a {
            width: fit-content !important;
        }
    }
}

@include breakpoint-up(lg) {
    .navigation {
        .ui-menu {
            outline: none;
            box-shadow: none;
        }

        svg,
        .menu-icon {
            width: 30px;
            height: 30px;
            margin-right: 15px;
            flex: 0 0 30px
        }

        svg,
        svg *,
        .menu-icon {
            fill: $text-primary;
        }

        .category-item, .all-category {
            > a {
                color: $text-primary;
                background-color: $background;
                cursor: pointer;
                padding: convert.rem(6px) $spacing-04;
                line-height: convert.rem(18px);
                font-weight: font-family.font-weight('semibold');
                position: relative;
                width: 100%;
                margin: 0;
                text-decoration: none;
                box-sizing: border-box;
                transition: background-color motion(standard, productive) $duration-fast-02;

                span:not(.ui-icon) {
                    margin-right: $spacing-04;
                }

                &:hover {
                    background-color: $layer-hover-01;
                }

                &:focus {
                    outline: none;
                }
            }

            &:hover > a {
                background-color: $layer-hover-01;
            }
        }

        .category-item {
            &.parent {
                position: relative;

                > a:after {
                    content: '';
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgYXJpYS1oaWRkZW49InRydWUiIHN0eWxlPSJ3aWxsLWNoYW5nZTp0cmFuc2Zvcm0iPjxwYXRoIGQ9Ik0yMiAxNiAxMiAyNmwtMS40LTEuNCA4LjYtOC42LTguNi04LjZMMTIgNnoiLz48L3N2Zz4=");
                    background-size: cover;
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-left: auto;
                    order: 2;
                    margin-top: -1px;
                    margin-bottom: -1px;
                }

                &:hover > .submenu {
                    display: block !important;
                }
            }

            .submenu {
                position: absolute !important;
                display: none !important;
                left: 100% !important;
                min-width: 240px;
            }

            .submenu:not(.bottom) {
                top: -20px !important;
            }

            .submenu.bottom {
                bottom: -20px !important;
            }

            .level0.submenu:not(.bottom) {
                top: -23px !important;
            }

            .level0.submenu.bottom {
                bottom: -23px !important;
            }

            .level0.submenu:not(.column-2, .bottom) {
                top: -15px !important;
            }

            .level0.submenu.bottom:not(.column-2) {
                bottom: -13px !important;
            }
        }

        .category-head {
            margin-bottom: $spacing-05 !important;
        }

        .category-head svg, .category-head svg * {
            fill: #fff !important;
        }

        .column-2 > .category-head {
            position: absolute;
            top: 30px;
        }

        .category-head a {
            color: white;
            align-items: center;
            text-decoration: none;
            font-size: 15px;
            font-weight: font-family.font-weight('semibold');
            height: convert.rem(30px);
        }

        .category-head a span {
            text-wrap: wrap;
        }

        .level0.submenu {
            background: $button-primary;
            padding: 30px 0;

            &:not(.column-2) {
                padding: 20px 0;
            }

            &.column-2 {
                width: 600px;
                padding-top: 4.6rem;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 100%;
                    left: 50%;
                    border-left: 1px solid #0d457d;
                }
            }

            > li {
                break-inside: avoid;
                white-space: nowrap;
                width: 230px;
                margin: 0 35px;
                float: left;

                .submenu {
                    top: -20px !important;
                }

                > a:hover {
                    color: #9db7cc;
                }

                > a:hover svg, > a:hover .menu-icon {
                    fill: #9db7cc;
                }
            }

            .category-item > a {
                padding: 0;
                line-height: 1;
                background-color: transparent;
                color: #fff;
                height: convert.rem(28px);
            }
        }

        .level1.submenu {
            background: $button-secondary;
            padding: 20px 0;
            z-index: 1;
            left: calc(100% + 10px) !important;

            &:before {
                content: '';
                position: absolute;
                width: 10px;
                height: 100%;
                top: 0;
                left: -10px;
            }


            &.column-2 {
                width: 560px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 100%;
                    left: 50%;
                    border-left: 1px solid #1c2229;
                }
            }

            > li {
                break-inside: avoid;
                white-space: nowrap;
                min-width: 230px;
                margin: 0 25px;
                float: left;

                > a:hover {
                    color: $button-primary;
                }
            }

            .category-item > a {
                padding: 0;
                line-height: 1;
                background-color: transparent;
                color: #fff;
            }
        }
    }
}
