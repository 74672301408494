@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/variables.overrides' as vars;
@use '../carbon/custom/config' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;

// Used in fixdirect
.hrp--header--fixdirect .hrp--header {
    flex-wrap: wrap;
    height: inherit;

    @include breakpoint-up(lg) {
        &__main--active:after,
        &__sub--active:after {
            top: 136px;
        }
    }

    @include breakpoint-down(lg) {
        &__nav--lg {
            display: none;
        }
    }

    @include breakpoint-down(vars.$mobile-menu-breakpoint-fixdirect) {
        &__sub {
            top: 56px;
        }

        &__global {
            margin-left: auto;
        }

        &__global &__language {
            display: none;
        }

        &__nav--lg {
            display: none;
        }

        &__decorate-wrapper {
            border: none;
        }
    }

    @include breakpoint-up(vars.$mobile-menu-breakpoint-fixdirect) {
        &__name {
            margin-right: 0;
        }

        a.hrp--header__menu-item {
            height: 2.5rem;
            padding-left: $spacing-04;
            padding-right: $spacing-04;
        }

        a.hrp--header__menu-item:hover {
            background: transparent;
            text-decoration: underline;
        }

        &__wrapper {
            border-bottom: 1px solid $border-subtle-01;
        }

        &__nav {
            margin-left: auto;
        }

        &__decorate-wrapper {
            display: none;
        }
    }

    &__catalog {
        flex: 0 0 100%;
        order: 3;
        margin-top: $spacing-03;
    }
}

.#{$prefix}--navigation--fixdirect {
    @include breakpoint-down(vars.$mobile-menu-breakpoint-fixdirect) {
        &--account, &--bookmarks, &--minicart, &--notification, &--bookmarks {
            top: 56px;
        }
    }
}

body.fixdirect {
    .#{$prefix}--btn--tertiary {
        color: $text-primary;
    }

    .#{$prefix}--btn--tertiary:hover {
        color: $background;
    }

    .#{$prefix}--btn--tertiary:active {
        background-color: $button-primary-active;
        color: $background;
    }

    .#{$prefix}--btn--tertiary:focus {
        background-color: $button-primary-hover;
        color: $background;
    }
}
