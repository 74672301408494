@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.table.table--with-head {
    margin-top: 0;
}

.table.table--with-head .table__row:first-child .table__td {
    padding-top: $spacing-07;
}

.table.table--with-head .table__row:last-child .table__td {
    border-bottom: none;
}

.table-head {
    position: relative;
    z-index: 1;
    min-height: 40px;
    background: $support-info;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 20px 10px -20px 10px;
    padding: $spacing-03 $spacing-05;
    line-height: 1.5;
}

.table-head strong {
    color: $text-primary;
    padding: 4px 8px;
    background-color: $text-inverse;
}
