@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/themes/scss/tokens' as *;

$filter__transition                               : all 0.3s ease-in-out, outline-offset 1ms !default;

$filters__skip-button-height         : 48px !default;
$filters__skip-button-padding        : 0 16px !default;
$filters__skip-button-margin-bottom  : 8px !default;

// Swatch
$filter__swatch-margin                            : .75rem .75rem 0 0 !default;
$filter__swatch-margin\@large                     : .75rem .75rem 0 0 !default;
$filter__swatch-option-size                       : 35px !default;
$filter__swatch-option-background                 : $background !default;
$filter__swatch-option-color                      : $text-primary !default;
$filter__swatch-option-border                     : 2px solid $border-subtle-01 !default;
$filter__swatch-option-border-color-hover         : $button-primary !default;
$filter__swatch-option-border-color--active       : $button-primary !default;

// Color
$filter__color-padding                            : 0 8px 0 0 !default;
$filter__color-size\@large                        : 25% !default;
$filter__color-option-width                       : 56px !default;
$filter__color-option-height                      : 80px !default;
$filter__color-option-border                      : 2px solid transparent !default;
$filter__color-option-after-top                   : 6px !default;
$filter__color-option-after-left                  : 10px !default;
$filter__color-option-after-size                  : 32px !default;
$filter__color-option-after-border-radius         : 0 !default;
$filter__color-option-after-border-color          : $button-primary !default;
$filter__color-sample-size                        : 32px !default;
$filter__color-sample-margin                      : 8px !default;
$filter__color-sample-border                      : 1px solid $layer-02 !default;
$filter__color-sample-border-radius               : 0 !default;
$filter__color-label-font-size                    : 12px !default;
$filter__color-label-color                        : $toggle-off !default;

// Range
$filter__range-margin                             : 0 0 16px 0 !default;

.filter {
    &s__skip-button {
        @include visually-hidden($usePseudo: true);
        transition: none;

        &:focus {
            height: $filters__skip-button-height;
            padding: $filters__skip-button-padding;
            margin-bottom: $filters__skip-button-margin-bottom;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__swatch-item {
        margin: $filter__swatch-margin;

        @include breakpoint-up('lg') {
            display: flex;
            justify-content: center;
            margin: $filter__swatch-margin\@large;
        }
    }

    &__swatch-option {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: $filter__swatch-option-size;
        min-height: $filter__swatch-option-size;
        border: $filter__swatch-option-border;
        background-color: $filter__swatch-option-background;
        color: $filter__swatch-option-color;
        transition: $filter__transition;
        cursor: pointer;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            border-color: $filter__swatch-option-border-color-hover;
        }

        &--active {
            border-color: $filter__swatch-option-border-color--active;
        }
    }

    &__color-item {
        padding: $filter__color-padding;

        @include breakpoint-up('lg') {
            display: flex;
            justify-content: center;
            flex-basis: $filter__color-size\@large;
        }
    }

    &__color-option {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: $filter__color-option-width;
        width: $filter__color-option-width;
        height: $filter__color-option-height;
        max-height: $filter__color-option-height;
        text-decoration: none;

        &:after {
            content: '';
            position: absolute;
            top: $filter__color-option-after-top;
            left: $filter__color-option-after-left;
            width: $filter__color-option-after-size;
            height: $filter__color-option-after-size;
            background-color: transparent;
            border: $filter__color-option-border;
            border-radius: $filter__color-option-after-border-radius;
            transition: $filter__transition;
        }

        &--active,
        &:hover,
        &:focus {
            text-decoration: none;

            &:after {
                border-color: $filter__color-option-after-border-color;
            }
        }
    }

    &__color-sample {
        width: $filter__color-sample-size;
        height: $filter__color-sample-size;
        margin: $filter__color-sample-margin;
        border: $filter__color-sample-border;
        border-radius: $filter__color-sample-border-radius;
    }

    &__color-label {
        max-width: 100%;
        font-size: $filter__color-label-font-size;
        color: $filter__color-label-color;
        word-break: break-all;
        text-align: center;
    }

    &__range {
        margin: $filter__range-margin;
    }
}
