@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/type/scss/font-family';

// checkout shipping info
.shipping {
    &__name {
        display: block;
        font-weight: font-family.font-weight('semibold');
    }

    @include breakpoint-down(lg) {
        &__separator {
            display: none;
        }
        &__phone {
            display: block;
        }
    }

    &__selector {
        margin: $spacing-06 0;

        &--desktop {
            table-layout: fixed;
            margin-top: $spacing-07;
            margin-bottom: $spacing-07;
            min-width: inherit;
        }

        @include breakpoint-down(md) {
            &--mobile {
                display: block;
            }

            &--desktop {
                display: none;
            }
        }

        @include breakpoint-up(md) {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
            }
        }
    }

    &__select {
        width: 100% !important;
        max-width: inherit !important;
    }

    &__actions {
        display: flex;
        margin-top: $spacing-03;
    }

    &__action {
        margin-right: $spacing-03;
    }
}

.shipping-customer-address {
    &__add-new {
        display: none;
    }

    &__add-new--active {
        display: block;
    }

    &__collapsible-button--expanded {
        display: none;
    }
}
