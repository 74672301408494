@use '@carbon/styles/scss/utilities/focus-outline' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../carbon/custom/config' as *;
@use '../carbon/custom/variables' as *;

@import 'owl.carousel/vendor/core';
@import 'owl.carousel/vendor/animate';
@import 'owl.carousel/vendor/autoheight';
@import 'owl.carousel/vendor/lazyload';
@import 'owl.carousel/vendor/video';


.owl-stage {
    display: flex !important;
}

.owl-item {
    float: none !important;
}

.owl-item .#{$prefix}--catalog-item--grid {
    min-height: 100%;
    margin: 0 !important;
}

.owl-prev, .owl-next {
    font-size: 25px !important;
    width: 50px;
    height: 50px;
    margin: -25px 0;
    line-height: 50px;
    position: absolute;
    top: 50%;

    &:focus {
        @include focus-outline('outline');
    }
}

.owl-prev {
    left: 0;
}

.owl-next {
    right: 0;
}

.owl-carousel.#{$prefix}--catalog-item__wrapper {
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
}

.owl-carousel.owl-loaded.#{$prefix}--catalog-item__wrapper {
    margin-left: 0;
    margin-right: 0;
}

.owl-carousel.#{$prefix}--catalog-item--grid {
    width: $card-width;
}

@media (min-width: 1400px) {
    .owl-carousel.owl-loaded.#{$prefix}--catalog-item__wrapper .slick-prev {
        left: calc(-1rem - 40px);
    }
    .owl-carousel.owl-loaded.#{$prefix}--catalog-item__wrapper .slick-next {
        right: calc(-1rem - 40px);
    }
}

@media (max-width: 1400px) {
    .owl-carousel.owl-loaded.#{$prefix}--catalog-item__wrapper .slick-prev,
    .owl-carousel.owl-loaded.#{$prefix}--catalog-item__wrapper .slick-next {
        top: 10rem;
    }
}
