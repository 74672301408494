@use "../../../../styles/atom/variables" as *;
@use "../../../../styles/atom/mq" as *;

//
//  Default appearance styles
//  _____________________________________________

[data-content-type$='block'] {
    .block {
        p:last-child {
            margin-bottom: $spacer;
            margin-top: 0;
        }
    }
}
