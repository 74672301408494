.link--visually-hidden {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
}

.gallery-placeholder__image {
    width: 100%;
}
