@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;

$dashboard-items-padding: 15px !default;
$dashboard-items-margin : 15px !default;

.dashboard-items {
    &__title {
        margin-bottom: $dashboard-items-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $layer-01;
    }

    &__content {
        margin: 0;
    }

    &__address {
        margin-bottom: $dashboard-items-margin;
    }

    &__item {
        padding: $dashboard-items-padding 0;
        margin-bottom: $dashboard-items-margin;
    }
}
