.#{$prefix}--dropdown-text__inner {
    display: inline-flex;
}

.#{$prefix}--dropdown-link {
    display: inline-flex;
    width: 100%;
}

.#{$prefix}--dropdown-link__icon {
    margin-right: $spacing-03;
}
