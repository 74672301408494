@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../../carbon/custom/config' as *;

.hrp-ag--header-row {
    .#{$prefix}--form-item {
        width: 100%;
    }
}

.hrp-ag--header-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: $spacing-05;

    @include breakpoint-down(lg) {
        margin-bottom: $spacing-03;
    }

    @include breakpoint-up(lg) {
        margin-bottom: $spacing-05;
    }
}

.hrp-ag--header-actions > *:empty {
    display: none;
}

.hrp-ag--header-action--data-grid {
    display: flex;

    .#{$prefix}--overflow-menu {
        width: min-content;
        position: relative;
    }

    .#{$prefix}--overflow-menu:first-child {
        margin-left: auto;
    }

    @include breakpoint-up(md) {
        margin-left: auto;

        .#{$prefix}--overflow-menu {
            margin-left: auto;
        }
    }

    @include breakpoint-down(md) {
        flex-wrap: wrap;
        flex: 0 0 100%;
        width: 100%;

        .#{$prefix}--overflow-menu {
            width: 100%;
        }

        > button.bx--btn {
            justify-content: center;
        }

        button.hrp-ag--overflow-menu,
        button.bx--btn {
            width: 100%;
            max-width: 100%;
        }
    }
}

.hrp-ag--header-action--listing-massaction {
    @include breakpoint-down(md) {
        margin-left: auto;
    }
}


.hrp-ag--overflow-menu--mobile-right .#{$prefix}--dropdown-list {
    z-index: 8999;
}

@include breakpoint-down(md) {
    .hrp-ag--overflow-menu--mobile-right .#{$prefix}--dropdown-list {
        right: 0;
        left: inherit;
    }
}
