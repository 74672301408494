@use "../../../../styles/atom/variables" as *;
@use "../../../../styles/atom/mq" as *;

//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='slider'] {
    display: none;

    &.slick-initialized {
        display: block;
    }

    .slick-track {
        display: flex;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
        min-height: inherit;

        > div {
            min-height: inherit;
            overflow: hidden;
            width: 100%;
        }
    }
}
