@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../../carbon/custom/config' as *;

.#{$prefix}--number__input-wrapper {
    display: flex;

    .quantity-update__input {
        order: 2;
        padding-left: 0 !important;
        padding-right: 0 !important;
        min-width: inherit !important;
        text-align: center;
        border-bottom: none !important;
    }

    .quantity-update__button--plus {
        order: 3;
        border-left: convert.rem(1px) solid #dcdcdc;
    }

    .quantity-update__button--minus {
        order: 1;
        border-right: convert.rem(1px) solid #dcdcdc;
    }

    .quantity-update__button--plus,
    .quantity-update__button--minus {
        width: convert.rem(40px);
        height: convert.rem(40px);
        flex: 0 0 convert.rem(40px);
        border-bottom: none;
        transition: background-color 0.3s;
        background-color: #f3f3f3;

        &:before,
        &:after {
            content: none;
        }
    }

    .quantity-update__button--plus:hover,
    .quantity-update__button--minus:hover {
        background-color: #dcdcdc;
    }
}
