@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/utilities/focus-outline' as *;
@use '@carbon/motion' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../../carbon/custom/config' as *;

$select__margin-bottom                   : $spacing-04 !default;
$select__padding                         : 0 $spacing-04 0 $spacing-04 !default;

.select {
    margin-bottom: $select__margin-bottom;
}

.#{$prefix}--select-input,
.select__field {
    @include type-style('body-short-01');
    @include focus-outline('reset');
    height: convert.rem(40px);
    appearance: none;
    display: block;
    width: convert.rem(224px);
    min-width: convert.rem(128px);
    max-width: convert.rem(448px);
    padding: 0 convert.rem(42px) 0 $spacing-05; // 1.5rem + chevron width
    color: $text-primary;
    background-color: $field-01;
    border: none;
    border-bottom: none;
    border-radius: 0;
    cursor: pointer;
    transition: background-color $duration-fast-01 motion(standard, productive),
    outline $duration-fast-01 motion(standard, productive);

    &:hover {
        background-color: $layer-hover-01;
    }

    background-image: url('data:image/svg+xml;utf8,<svg focusable="false" preserveAspectRatio="xMidYMid meet" style="will-change: transform;" xmlns="http://www.w3.org/2000/svg" class="bx--select__arrow" width="10" height="6" viewBox="0 0 10 6" aria-hidden="true"><path d="M5 6L0 1 .7.3 5 4.6 9.3.3l.7.7z"></path></svg>');
    background-position: right 15px center;
    background-repeat: no-repeat;
}
