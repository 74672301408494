@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;

.hrp--banner {
    &__list > & {
        display: none;

        &:first-child {
            display: block;
        }
    }

    &__image {
        width: 100%;
    }
}


.hrp--banner--generic,
.hrp--banner--category--top,
.hrp--banner--category--main,
.hrp-banner--category--sidebar {
    @media (min-width: 672px) {
        [data-content-type="slider"],
        [data-content-type="slider"] .slick-list {
            height: auto !important;
            aspect-ratio: 1248 / 300;
        }
    }

    [data-content-type="slide"],
    [data-content-type="banner"],
    [data-content-type="image"] {
        height: auto !important;
        aspect-ratio: 1248 / 300;

        @media (max-width: 671px) {
            &[data-has-mobile-image="true"] {
                aspect-ratio: 288 / 240;
            }
        }
    }

    [data-content-type="image"] img {
        margin-top: 0;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}

.hrp--banner--generic,
.hrp--banner--category--top,
.hrp--banner--category--main {
    > .widget > [data-content-type="row"] {
        box-sizing: border-box !important;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 1280px !important;
    }

    [data-content-type="row"][data-appearance="contained"] {
        padding-left: 0;
        padding-right: 0;
    }

    [data-content-type="image"] {
        overflow: hidden;
        position: relative;
    }
}

.hrp--banner--sidebar {
    [data-content-type="row"] {
        padding-left: 0;
        padding-right: 0;
    }

    .bx--catalog-list__sidebar-additional & {
        margin-top: $spacing-06;
    }

    [data-content-type="image"] {
        overflow: hidden;
        position: relative;
        aspect-ratio: 6 / 5;
    }

    [data-element="main"] > img {
        margin: 0 !important;
    }
}

.hrp--banner--generic {
    overflow-x: hidden;

    &:not(.hrp--banner--category--top):not(.hrp--banner--category--main) {
        margin-top: $spacing-05;
        margin-bottom: $spacing-06;

        [data-content-type="row"] {
            @include breakpoint-down(md) {
                padding-left: $spacing-04 !important;
                padding-right: $spacing-04 !important;
            }

            @include breakpoint-up(md) {
                padding-left: $spacing-05 !important;
                padding-right: $spacing-05 !important;
            };
        }
    }

    &.hrp--banner--mainpage {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    &.hrp--banner--mainpage > .widget:last-child {
        [data-content-type="slider"],
        [data-content-type="banner"],
        [data-content-type="image"] {
            margin-bottom: 0;
        }
    }

    [data-content-type="slider"] {
        position: relative;
    }

    [data-element="content"] {
        @extend .cms-content;
        @extend .cms-content--without-margin;
    }

    [data-content-type="slide"],
    [data-content-type="banner"] {
        [data-element="empty_link"],
        [data-background-type="image"],
        [data-element="overlay"],
        [data-element="wrapper"] {
            height: 100% !important;
            min-height: 100% !important;
            display: block;
        }

        [data-element="wrapper"] {
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
        }
    }

    [data-content-type="slider"],
    [data-content-type="banner"],
    [data-content-type="image"] {
        margin-bottom: $spacing-06;
    }

    div[data-content-type="slider"] .slick-list,
    div[data-content-type="slider"] .slick-track,
    div[data-content-type="slider"] .slick-slide,
    div[data-content-type="slider"] .slick-slide div {
        height: 100%;
    }

    .slick-track {
        display: flex;
    }

    .slick-dots {
        bottom: 0;
    }

    .slick-dots li {
        margin: 0 5px;
    }

    .slick-dots li button {
        width: 10px;
        height: 10px;
        background: none;
    }

    .slick-dots li.slick-active button {
        background: $color-white;
    }

    [data-content-type="banner"] [data-element="wrapper"],
    [data-content-type="slide"] [data-element="wrapper"] {
        display: flex;
        align-items: center;
    }

    [data-element="button"].pagebuilder-button-secondary {
        background: none;
        border: 1px solid $color-white;
        padding: 14px;

        &:hover {
            background: $color-white;
            color: $color-black;
        }
    }

    // removed all of the overlay design
    [data-element="overlay"] {
        display: none !important;
    }
}

.hrp--banner--category--main {
    @include breakpoint-down(lg) {
        div[data-content-type="slider"].slick-initialized {
            margin-top: $spacing-06;
        }

        > .widget {
            margin-top: $spacing-05;
        }
    }

    @include breakpoint-up(xlg) {
        [data-element="overlay"] {
            width: 50% !important;
            margin-right: 0 !important;
            margin-left: auto !important;
        }
    }
}

// TODO: should be part of critical.css
.hrp--banner--top {
    .pagebuilder-overlay.pagebuilder-poster-overlay {
        padding: 0;
    }

    [data-content-type="button-item"] {
        margin: 0;
    }

    [data-content-type="banner"] {
        margin: 0;
        aspect-ratio: inherit;
    }

    [data-element="content"] {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80px;
        flex-wrap: wrap;

        > * {
            padding: 10px;
        }

        > *:empty {
            display: none;
        }

        a {
            color: $color-white;
            border: 1px solid $color-white;
            text-decoration: none;
            font-size: 14px;
            height: 38px;
            line-height: 35px;
            padding-left: 20px;
            padding-right: 20px;
            display: inline-block;
            transition: background-color 0.3s, color 0.3s;
        }

        p > a {
            margin-left: 20px;
        }

        a:hover {
            background-color: $color-white;
            color: $color-black;
        }
    }
}
// ENDofTODO
