@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

.#{$prefix}--related-product {
    &__image-wrapper img {
        width: convert.rem(95px);
        height: auto;
    }

    &s__block {
        border-top: 1px solid $border-subtle-01;
        margin-top: $spacing-06;
        padding-top: $spacing-06;

        .#{$prefix}--sidebar-block {
            margin: 0;
            padding: 0;
        }
    }

    &s__title {
        @include reset;
        @include type-style('heading-02');

        color: $text-primary;
        margin-bottom: $spacing-05;
    }

    &s__block:not(.product-view__related--used) &s__list-item {
        border: 1px solid $border-subtle-01;
        padding: $spacing-04 $spacing-03;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $spacing-04;

        @include breakpoint-up('md') {
            > * {
                margin: 0 $spacing-03;
            }
        }

        @include breakpoint-down('md') {
            flex-wrap: wrap;
        }
    }

    .product-view__related--used &s__list-item {
        border: none;
        padding: 0;
        margin: 0 0 15px;
        display: grid;

        > * {
            margin: 0;
        }

        .#{$prefix}--sidebar-block__list-item {
            line-height: inherit;
            min-height: inherit;
        }

        .#{$prefix}--form-inline {
            align-items: center;
        }

        @include breakpoint-up(lg) {
            grid-template-areas: "title title form" "price stock form";
            row-gap: 5px;
            column-gap: 10px;
            grid-template-columns: 1fr 200px;
        }

        @include breakpoint-down(lg) {
            grid-template-areas: 'title' 'price' 'stock' 'form';
            row-gap: 5px;
        }
    }

    &__title {
        @include type-style('body-short-01');

        a {
            color: $text-primary;
            text-decoration: none;
        }
    }

    &__title-wrapper {
        grid-area: title;
        max-width: 100%;
    }

    &__price {
        grid-area: price;
        white-space: nowrap;
    }

    &__price {
        &--special,
        &--regular {
            font-weight: font-family.font-weight('semibold');
        }

        span {
            display: block;
        }
    }

    &__stock {
        grid-area: stock;
    }

    &__add-to-cart {
        grid-area: form;
    }

    @include breakpoint-down('md') {
        &__image-wrapper {
            margin: 0 auto;
        }

        &s__block--bundle &__title-wrapper {
            margin: $spacing-03 0;
        }

        &s__block--used &s__list-item > div {
            margin: 0 $spacing-03;
        }

        &s__block--used &__add-to-cart {
            margin-top: $spacing-03 !important;
            margin-left: $spacing-03;
            margin-right: $spacing-03;
            max-width: 100%;
        }

        &__add-to-cart {
            margin-top: $spacing-03 !important;
        }
    }
}
