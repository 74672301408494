@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';

.hrp--sales-quotation-total--wrapper {
    text-align: right;
}

.hrp--sales-quotation-total {
    max-width: 100%;
    display: inline-block;
}

.hrp--sales-quotation-total__value {
    display: inline-block;
    width: 140px;
    padding-left: 5px;
}

.hrp--sales-quotation-total__value--highlighted {
    @include type-style('productive-heading-03');
    color: $text-primary;
    font-weight: font-family.font-weight('semibold');
}

@include breakpoint-up(lg) {
    .hrp--sales-quotation-totals {
        width: 280px;
        margin-left: auto;
        text-align: right;
    }

    .hrp--sales-quotation-totals__title {
        float: left;
    }

    .hrp--sales-quotation-total__value {
        line-height: 24px;
    }
}

@include breakpoint-down(md) {
    .hrp--sales-quotation-totals__title {
        display: block;
        margin-bottom: $spacing-05;
        font-size: 13px;
    }

    .hrp--sales-quotation-total--total-price .hrp--sales-quotation-total__value {
        font-size: 16px;
    }

    .hrp--sales-quotation-total--net-price,
    .hrp--sales-quotation-total--gross-price {
        margin-bottom: $spacing-01 !important;
    }

    .hrp--sales-quotation-total--net-price,
    .hrp--sales-quotation-total--gross-price,
    .hrp--sales-quotation-total--vat-total {
        .hrp--sales-quotation-total__value {
            font-size: 13px;
        }
    }
}

@include breakpoint-down(lg) {
    .hrp--sales-quotation-total {
        min-width: 100%;
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-bottom: $spacing-04;

        &.hrp--sales-quotation-total--total-price {
            margin-bottom: 0;
            margin-top: $spacing-05;
        }
    }

    .hrp--sales-quotation-totals {
        width: calc(70% - 15px);
        float: right;
        font-size: 12px;
    }

    .hrp--sales-quotation-totals-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .hrp--sales-quotation-totals-wrapper:after {
        content: '';
        display: block;
        clear: both;
    }

    .hrp--sales-quotation-totals__title {
        width: calc(30% - 15px);
        float: left;
    }

    .hrp--sales-quotation-total--total-price {
        margin-left: 0;
    }

    .hrp--sales-quotation-total__value {
        margin-left: auto;
        text-align: right;
    }
}
