@use '@carbon/themes/scss/tokens' as *;
@use '../custom/config' as *;

// links
.#{$prefix}--link {
    display: inline;

    &--light {
        &, &:visited {
            color: $link-primary;
            text-decoration: none;
        }
    }

    &--dark {
        &, &:visited {
            color: $text-primary;
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.#{$prefix}--link:hover {
    .#{$prefix}--link__icon {
        fill: $link-primary-hover;
    }
}

.#{$prefix}--link, .#{$prefix}--link:visited {
    .#{$prefix}--link__icon {
        fill: $link-primary;
    }
}

.#{$prefix}--link--with-icon {
    display: inline-flex !important;
    align-items: center;
    white-space: nowrap;
}
