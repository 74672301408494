@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

$mmspn_border_opac: 0;
$mmspn_item_height: 34px;
$mmspn_item_indent: 5px;
$mmspn_line_height: 34px;
$mmspn_panel_offset: 100%;
$mmspn_arrow_size: 5px;
$mmspn_arrow_weight: 1px;
$mmspn_arrow_opac: 1;
$mmspn_navbar_opac: 1;

@import 'mmenu-light/modules/offcanvas-drawer/index';
@import 'mmenu-light/modules/sliding-panels-navigation/index';

.mm-ocd {
    @include breakpoint-up(lg) {
        top: 80px;
    }

    @include breakpoint-down(lg) {
        top: 114.66px;
    }

    &--open {
        background-color: rgba(0, 0, 0, 0.75);
    }
}

#{$mm_prefix} {
    color: $text-primary;
    background: $background;

    &--navbar > #{$mm_prefix}--open {
        position: relative !important;
    }
}

#{$mm_prefix} a, #{$mm_prefix} span {
    line-height: var(--mm-spn-line-height);
    height: var(--mm-spn-line-height);
}

#{$mm_prefix} .#{$prefix}--dialog__list-item a, #{$mm_prefix} .#{$prefix}--dialog__list-item span {
    line-height: 1.2;
    min-height: var(--mm-spn-line-height);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
}

#{$mm_prefix} a {
    padding-left: $spacing-04;
}

#{$mm_prefix} a span {
    padding: 0;
}

#{$mm_prefix}#{$mm_prefix}--navbar:after {
    text-align: left;
    padding-left: $spacing-07;
    color: $link-primary;
    line-height: 50px;
    height: 50px;
}

#{$mm_prefix}#{$mm_prefix}--navbar:before {
    margin-left: 8px;
    color: $link-primary;
    top: 25px;
    background-color: transparent;
}

#{$mm_prefix}#{$mm_prefix} li:not(.parent):before {
    content: none;
}

.mm-spn.mm-spn--navbar.mm-spn--main.mm-spn--main:after {
    content: none;
}

.mm-spn.mm-spn--navbar:not(.mm-spn--main).mm-spn--main:after {
    text-align: center;
    padding-left: $spacing-04;
    color: $text-primary;
    line-height: 50px;
}

.mm-spn.mm-spn--navbar.mm-spn--main ul {
    top: 0;
}

.mm-spn.mm-spn--navbar:not(.mm-spn--main) ul {
    top: 51px;
    padding-bottom: 20px;
}

.mm-spn--main ul:after {
    display: none !important;
}
