@use '@carbon/styles/scss/type' as *;
@use '@carbon/colors' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

// Tile Article

a.tile--article,
.tile--common {
  display: flex;
  text-decoration: none;
  color: $text-primary;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  background: $background;
  margin-bottom: $spacing-05;
  width: 100%;
}

a.tile--article.tile--dark,
.tile--common.tile--dark {
  background: $gray-90;
  color: $white-0;
}

.tile--common.tile--dark .tile__info-title {
  color: $white-0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  text-decoration: none;
}

a.tile--article.tile--dark:hover,
a.tile--common.tile--dark:hover {
  background: $gray-80;
}

.tile--article .tile__img,
.tile--common .tile__img {
  margin: 0;
  overflow: hidden;
  border-bottom: none;
}

.tile__img span {
  height: 100%;
}

.tile--article .tile__img img,
.tile--common .tile__img img {
  width: 100%;
  object-fit: cover;
}

.tile--article .tile__info,
.tile--common .tile__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing-05;
  padding-right: 25%;

  @include breakpoint('lg') {
    padding-right: $spacing-05;
  }

  @include breakpoint('xlg') {
    padding-right: 30%;
  }
}

.tile--article .tile__info h5,
.tile--common .tile__info h5 {
  text-decoration: none;
  color: $text-primary;
  @include type-style('body-short-02');
}

.tile--article.tile--dark .tile__info h5,
.tile--common.tile--dark .tile__info h5 {
  color: $white-0;
}

.tile--article .tile__caption p,
.tile--common .tile__caption p {
  @include type-style('caption-01');
  padding: 0;
  max-width: 75%;
}

// Tile Resource

.tile--resource {
  // full bleed for tiles on mobile
  @include breakpoint('sm') {
    width: 100%;
  }

  @include breakpoint('md') {
    width: 100%;
    margin: 0;
  }
}

.tile--resource--no-margin .tile--resource {
  margin-bottom: 0;
}

.tile--resource a.#{$prefix}--tile {
  text-decoration: none;
  color: $text-primary;
  padding: $spacing-05;
  position: relative;
  background: $white-0;
  height: 100%;

  &:hover {
    background: $layer-hover-01;
  }
}

.tile--resource.tile--dark a.#{$prefix}--tile {
  background: $gray-90;
  color: $white-0;
}

.tile--resource.tile--dark a.#{$prefix}--tile:hover {
  background: $gray-80;
}

.tile--resource .tile__info {
  width: 100%;

  @include breakpoint('lg') {
    width: 75%;
  }
}

.tile--resource .tile__info h5 {
  display: block;
  width: 100%;
  text-decoration: none;
  color: $text-primary;
  margin: 0 0 $spacing-03 0;
  padding: 0 0 0 0;
  @include type-style('body-short-01');
}

.tile--resource.tile--dark .tile__info h5 {
  color: $white-0;
}

.tile--resource .tile__description {
  padding-bottom: 0;
  color: $text-primary;
}

.tile--dark .tile__description {
  color: $white-0;
}

.tile--resource .tile__img {
  width: 32px;
  height: 32px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: absolute;
  left: $spacing-05;
  bottom: $spacing-05;

  img {
    width: 100%;
    margin: 0;
  }
}

.tile--resource .tile__link-icon {
  position: absolute;
  right: $spacing-05;
  bottom: $spacing-05;
  width: 20px;
  height: 20px;

  svg {
    fill: $black-100;
  }
}

.tile--dark .tile__link-icon {
  svg {
    fill: $white-0;
  }
}

// Grouped tiles
.tile--group > div .tile--resource {
  @include breakpoint('sm') {
    border-bottom: 1px solid $gray-20;

    &.tile--dark {
      border-bottom-color: $black-100;
    }
  }

  @include breakpoint('md') {
    border: 1px solid transparent;
    border-right: none;
    border-bottom: none;
  }
}

.tile--group > div:nth-child(2n + 2) {
  @include breakpoint('md') {
    .tile--resource {
      border-left: 1px solid $gray-20;

      &.tile--dark {
        border-left-color: $black-100;
      }
    }
  }
}

.tile--group > div:nth-child(n + 3) {
  @include breakpoint('md') {
    .tile--resource {
      border-top: 1px solid $gray-20;

      &.tile--dark {
        border-top-color: $black-100;
      }
    }
  }
}

.tile--group {
  a.tile--article.tile--dark,
  .tile--common.tile--dark {
    border-color: $black-100;
  }
}

a.tile--article .tile__caption p,
.tile--common .tile__caption p {
  color: $text-secondary;
}

a.tile--article.tile--dark .tile__caption p,
.tile--common.tile--dark .tile__caption p {
  color: $gray-30;
}
