@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';

.hrp--hr {
    border: solid $border-subtle-01;
    border-width: 0 0 convert.rem(1px) 0;
}

.hrp--hr--wide {
    border: solid #d3d3d3;
    border-width: 0 0 convert.rem(2px) 0;
}
