@use '@carbon/layout/scss/spacing' as *;

$input__margin-bottom                : $spacing-04 !default;
$input__label-margin-right           : $spacing-04 !default;

.input {
    margin-bottom: $input__margin-bottom;

    &--inline {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .input__label {
            //@extend .label--inline;
            margin-right: $input__label-margin-right;
        }
    }

    &__field {
        width: 100%;
        line-height: normal;

        &::placeholder {
        }

        &:focus {
            //@include focus-input();
        }

        &--textarea {
        }
    }
}
