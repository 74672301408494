@use '@carbon/styles/scss/components/notification' as *;
@use '@carbon/themes/scss/generated/tokens' as themeTokens;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../carbon/custom/config' as *;


@mixin notification--experimental($color, $background-color) {
    border-left: 3px solid $color;
    background: $background-color;

    .#{$prefix}--inline-notification__icon,
    .#{$prefix}--toast-notification__icon,
    .#{$prefix}--actionable-notification__icon {
        fill: $color;
    }
}

// messages
.message {
    min-height: convert.rem(48px);
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
    padding: .75rem 1rem;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
    line-height: 1.3;

    &.error,
    &--error {
        @include notification--experimental($support-error, $background); // TODO: update $notification-background-error value
    }

    &.success,
    &--success {
        @include notification--experimental($support-success, $background); // TODO: update $notification-background-success value
    }

    &.empty,
    &.info,
    &--info {
        @include notification--experimental($support-info, $background); // TODO: update $notification-background-info value
    }

    &.notice,
    &--notice,
    &.warning,
    &--warning {
        @include notification--experimental($support-warning, $background); // TODO: update $notification-background-warning value
    }
}
