@use '../carbon/custom/type' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

*:focus:not(.focus-visible),
*:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}

.devnull {
    display: none;
}

.swatch-opt .mage-error {
    flex: 0 0 100%;
}

.permanent-error-message,
.mage-error {
    display: block;
    color: $support-error;
    font-weight: 400;
    overflow: visible;
    margin-top: $spacing-02;
    @include type-style('label-01');
}

.bx--checkbox, .checkbox__field {
    top: inherit;
    left: inherit;
}

#recaptcha-invisible {
    z-index: 2;
    position: relative;
}

.modals-overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.table--clean .data {
    color: var(--bx-text-secondary, #525252);
    text-align: right;
}

.fast-checkout-success-index-quotation .hrp-layout--gray {
    background-color: #FFF;
}

.snow-menu-highlighted a{
    color: #ff0000;
    font-weight: bold;
}
