@use '@carbon/themes/scss/tokens' as *;

.hrp--tfa__wrapper {
    border: 1px solid $border-subtle-01;
}

.hrp--tfa__provider {
    display: none;
}

.hrp--tfa__image {
    width: 100%;
}

.hrp--tfa__paragraph {
    font-size: 14px;
}
