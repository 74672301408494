@use 'variables.overrides' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use 'custom/config' as *;
@use 'custom/tokens' as *;

.hrp--footer {
    &__logo {
        width: $footer-logo-width--sm;
        height: $footer-logo-height--sm;
        max-width: 100%;
    }

    @include breakpoint-up(md) {
        &__logo {
            width: $footer-logo-width--md;
            height: $footer-logo-height--md;
        }
    }
}
