@use 'sass:math';

#{$mm_prefix} {
    //  The menu
    &#{$mm_prefix}--vertical {
        //  Enable scrolling.
        overflow-y: auto;
    }

    //  Listviews
    &#{$mm_prefix}--vertical {
        ul {
            //  Undo the fix for weird iOS Safari issue.
            width: 100%;
            padding-right: 0;
            //  /Undo fix.

            //  Undo sliding submenus.
            //  Also undoes the top, left, right, bottom and z-index properties :)
            position: static;

            //  Submenus
            ul {
                display: none;
                padding-left: $mmspn_item_indent; // IE11 fallback
                padding-left: var(--mm-spn-item-indent);

                //  Because padding-bottom doesn't work if it is scrollable.
                &:after {
                    height: math.div($mmspn_item_height, 2); // IE11 fallback
                    height: calc(var(--mm-spn-item-height) / 2);
                }
            }

            //  Show opened submenus.
            &#{$mm_prefix}--open {
                display: block;
            }
        }
    }

    //  Listitems
    &#{$mm_prefix}--vertical {
        //  Rotate the button.
        li#{$mm_prefix}--open:before {
            transform: rotate(135deg) translate(-50%, 0);
        }

        //  Remove border from last subchild.
        ul ul li {
            &:last-child:after {
                content: none;
                display: none;
            }
        }
    }
}
