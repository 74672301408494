@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;

.hrp--time-slot__header {
    line-height: 40px;
    font-weight: bold;
}

.hrp--time-slot__link {
    border: solid #dcdcdc;
    border-width: 0 1px 1px 1px;
    line-height: 1.83rem;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: $text-primary;
    text-align: center;
}

.hrp--time-slot__list {
    border-top: 1px solid #dcdcdc;
}

.hrp--time-slot__link--active {
    outline: rgb(2, 122, 44) solid 3px !important;
    outline-offset: -3px !important;
}

.hrp--time-slot__nav {
    margin-top: $spacing-04;
    display: flex;
}

.hrp--time-slot__next,
.hrp--time-slot__prev {
    border: 1px solid #dcdcdc;
    width: calc(50% - $spacing-04);
}

.hrp--time-slot__next {
    margin-left: auto;
}

@include breakpoint-down(md) {
    .hrp--time-slot__list {
        display: grid;
        grid-template-columns: auto auto;
    }

    .hrp--time-slot__list-item:nth-child(2n) a {
        border-left: 0;
    }
}
