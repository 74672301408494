@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;

.table--product {
    border-color: $layer-01;
    vertical-align: center;

    tr {
        background: #fff;
    }

    td {
        border-color: $layer-01;
        background: #fff;
        vertical-align: middle;

        @include breakpoint-down(md) {
            display: grid;
            grid-template-areas: 'before content';
            grid-template-columns: 90px 1fr;
            column-gap: $spacing-04;
        }
    }
}

.table__product-price {
    font-size: 24px;
}

.table__additional-info {
    grid-area: additional-info;
    font-size: 14px;
}

.table__product-details {
    display: grid;
    grid-template-areas: 'image' 'title' 'additional-info' 'sku';
    row-gap: $spacing-03;
    grid-template-columns: auto;

    @include breakpoint(lg) {
        grid-template-areas: 'image title' 'image additional-info' 'image sku';
        grid-template-columns: 80px minmax(0, 1fr);
        column-gap: $spacing-05;
    }

    .lazyload-wrapper {
        display: block;
        align-items: center;
    }
}

.lazyload-wrapper,
.table__product-image  {
    grid-area: image;
}

.table--product img,
.table__product-image {
    height: auto;
    display: block;
    width: 100%;

    @include breakpoint-up(md) {
        width: 80px;
    }
}

.table__product-title {
    grid-area: title;
    font-size: 16px;
    margin: 0;
}

.table__product-sku {
    grid-area: sku;
    color: #8b8b8b;
    font-size: 13px;
}

.table__product-td--price > div {
    display: inline-block;
}

@include breakpoint(lg) {
    .table__product-td--status {
        width: 120px;
    }

    .table__product-td--quantity {
        width:80px;
    }

    .table__product-td--price {
        width: 155px;
    }
}

@include breakpoint-down(md) {
    .table--product-sm {
        background: transparent;
        border: none;
    }

    .table--product-sm .table__row--product-sm {
        display: grid;
        grid-template-columns: 34px 85px calc(33% - 120px) repeat(5, 1fr) 50px;
        grid-template-areas:
            'radio-button   image       title       title       title       title       title       title       quantity'
            'price-1        price-1     price-1     price-2     price-2     price-2     price-3     price-3     price-3';
        padding-top: 0;
        padding-bottom: 0;
        border: 1px solid #ededed;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            border-bottom: 1px solid #ededed;
            left: $spacing-03;
            right: $spacing-03;
            bottom: 65px;
        }

        .table__td--image, .table__td--title, .table__td--quantity {
            padding-top: $spacing-05;
        }

        .table__td {
            display: block;
            padding-left: $spacing-03;
            padding-right: $spacing-03;
        }

        .table__td--quantity {
            display: flex;
        }

        .table__td--quantity,
        .table__td--radio-button > div {
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .table__td--radio-button > div .#{$prefix}--radio-button__appearance {
            margin: 0;
        }

        .table__td--price-1, .table__td--price-2, .table__td--price-3 {
            padding-top: $spacing-05 !important;
            padding-bottom: $spacing-05;
        }

        .table__td--title,
        .table__td--price-2 {
            padding-left: 0;
            padding-right: 0;
        }

        .table__td--quantity {
            text-align: right;
        }

        .table__td--price {
            .#{$prefix}--label {
                margin-bottom: 0;
            }

            .table__td-price {
                font-weight: font-family.font-weight('semibold');
            }
        }
    }

    .table__td--radio-button {
        grid-area: radio-button;
    }

    .table__td--image {
        grid-area: image;
    }

    .table__td--title {
        grid-area: title;
    }

    .table__td--quantity {
        grid-area: quantity;
    }

    .table__td--price-1 {
        grid-area: price-1;
    }

    .table__td--price-2 {
        grid-area: price-2;
    }

    .table__td--price-3 {
        grid-area: price-3;
    }
}
