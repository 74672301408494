@use '@carbon/styles/scss/components/button/tokens' as *;

body .fotorama {
    &__nav-wrap--vertical .fotorama__nav__shaft {
        background: transparent !important;
    }

    &__nav-wrap--vertical.fotorama__nav-wrap {
        margin-top: -15px;
    }

    &__thumb-border {
        background: none;
        border: 1px solid $button-primary !important;
    }
}

.fotorama__nav__frame.fotorama__nav__frame--thumb:not(.fotorama__active) .fotorama__thumb {
    background: #ebebeb;
}

.fotorama__nav__frame.fotorama__nav__frame--thumb.fotorama__active .fotorama__thumb {
    background: #fff;
}
