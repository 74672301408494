@use '@carbon/styles/scss/type' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;

.appointment-booking {
    &__error-item,
    &__worksheet-item {
        border: 1px solid $border-subtle-01;
        margin: $spacing-04 0;
        position: relative;
    }

    &__worksheet-item {
        padding-bottom: $spacing-05;
    }

    &__error-item-remove-action,
    &__worksheet-item-remove-action {
        position: absolute !important;
        top: $spacing-05;
        right: $spacing-05;
    }

    &__title {
        @include type-style('body-short-01');
        color: $text-primary;
        margin-top: $spacing-04;
        margin-bottom: $spacing-04;
    }

    &__error-item-actions,
    &__worksheet-item-actions {
        margin-bottom: $spacing-05;
    }
}
