@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;

.table.table--radio {
    border-bottom: 3px solid $support-info;
}

.table--radio .table__td-title,
.table--radio .table__td a {
    color: #171717;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
}

.table--radio .table__td a:hover {
    text-decoration: underline;
}

.table--radio .table__td-description {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #565656;
    margin-top: $spacing-04;
}

.table--radio .table__td {
    background: $background;
    border-right: none;
    vertical-align: middle;

    .table__td--radio-button {
        width: 0;
        padding-right: 0;
        border-bottom: none;
    }
}

.table__td--quantity {
    font-weight: font-family.font-weight('semibold');
}

.table--radio.stock {
    font-size: 12px;
}

@include breakpoint-up(md) {
    .table.table--radio {
        border-top: 1px solid $border-subtle-01;
        border-left: none;
        border-right: none;
    }

    .table--radio .#{$prefix}--label {
        margin-bottom: .15rem;
    }

    .table--radio .table__td-title,
    .table--radio .table__td a {
        font-size: 14px;
    }

    .table--radio .table__row:first-child {
        border-top: 1px solid $border-subtle-01;
    }

    .table--radio .table__row--empty-space {
        padding: 0 !important;
        height: 15px;
    }

    .table--radio .table__td--empty-space {
        padding: 0;
        height: 15px;
    }

    .table--radio .table__row--selected .table__td-price--total,
    .table--radio .table__row--selected .table__td-price,
    .table--radio .table__row--selected .table__td--quantity {
        font-size: 16px;
        font-weight: font-family.font-weight('semibold');
    }

    .table--radio .table__td--no-wrap {
        span {
            white-space: nowrap;
        }
    }

    .table--radio.stock.available {
        color: $support-success;
        font-size: 14px;
        font-weight: font-family.font-weight('semibold');
        letter-spacing: 0.16px;
        line-height: 18px;
    }

    .table__td--radio-button {
        width: 61px;
    }

    .table__td--image {
        width: 102px;
    }
}

@include breakpoint(lg) {
    .table--radio .table__td:first-child {
        border-left: 1px solid $border-subtle-01;
    }

    .table--radio .table__td:last-child {
        border-right: 1px solid $border-subtle-01;
    }

    .table--radio .table__td--empty-space {
        border-top: 1px solid $border-subtle-01 !important;
        border-bottom: 1px solid $border-subtle-01 !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .table--radio .table__td--price {
        text-align: right;
    }

    .table--radio .table__td-price {
        white-space: nowrap;
    }
}

@include breakpoint-down(md) {
    .table--radio .table__td--image img {
        max-width: 100%;
        height: auto !important;
    }

    .table--radio .#{$prefix}--label {
        margin-bottom: 0;
    }

    .table--radio .#{$prefix}--catalog-item__stock-info-text,
    .table--radio .table__td--quantity {
        font-size: 12px;
    }

    .table.table--radio .table__row .table__td--image {
        display: flex;
        align-items: center;
    }
}

@include breakpoint-between(lg, xlg) {
    .table--radio .table__td {
        padding: 16px 8px;
    }

    .table--radio .table__td--radio-button {
        max-width: 40px;
    }

    .table--radio .table__td--image {
        display: none;
    }
}

@include breakpoint-between(md, lg) {
    // NOTE: if --radio is needed for mobile, this part should be transferred into a mixin
    .table--radio .table__row {
        padding: $spacing-05 $spacing-05 $spacing-02 $spacing-10;
        background: $background;
        border: 1px solid $border-subtle-01;
        border-bottom: none;
        position: relative;
        display: block;
    }

    .table--radio .table__td:first-child {
        position: absolute;
        top: 25px;
        left: 20px;
        display: block;
        padding: 0;
        width: auto;
    }

    .table--radio .table__td {
        padding: 0;
        display: block;
        border: none;
        margin-bottom: $spacing-04;
    }

    .table--radio .table__td:before {
        content: none;
    }

    .table--radio .table__row--empty-space {
        border-top: 1px solid $border-subtle-01;
        border-left: 0 !important;
        border-right: 0 !important;
    }

    .table--radio .table__td--empty-space {
        border: 0 !important;
    }

    .table--radio .#{$prefix}--label--warranty-time {
        margin-top: $spacing-04;
    }
}

