@use '@carbon/styles/scss/components/notification/mixins' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;
@use '@carbon/layout/scss/spacing' as *;

.cookie-message {
    position: fixed;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 10;
    max-width: 100%;

    &.#{$prefix}--toast-notification--low-contrast {
        @include notification--experimental($support-info, $background);
    }

    @include breakpoint-down(md) {
        left: 0;
        bottom: 0;
        margin-bottom: 0;
    }
}

.cookie-message__content {
    padding-top: $spacing-05;
    padding-bottom: $spacing-05;

    @include breakpoint-up(md) {
        display: flex;
        align-items: flex-start;
    }
}

.cookie-message__allow {
    margin-bottom: $spacing-06;
}

.cookie-message__button {
    margin-top: $spacing-01;
    margin-bottom: $spacing-01;
}
