@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp--attachment {
    display: flex;
    align-items: center;
}

.hrp--attachment__container {
    border: 1px solid $border-subtle-01;
    padding: $spacing-05;
}

.hrp--attachment__container--larger {
    padding: $spacing-05 $spacing-06;
}

.hrp--attachment__icon {
    margin-right: $spacing-03;
}

.hrp--attachment__divider {
    margin-left: $spacing-03;
    margin-right: $spacing-03;
    color: $layer-accent-01;
}

.hrp--attachment__link {
    text-transform: uppercase;
}

.hrp--attachment__divider {
    color: $text-placeholder;
}
