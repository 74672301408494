@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../../carbon/custom/config' as *;

.#{$prefix}--data-table--ag {
    th, th .#{$prefix}--table-sort {
        background-color: $layer-01;
    }

    th[aria-sort="true"] .#{$prefix}--table-sort {
        cursor: inherit;
    }

    td {
        background-color: #fff;
    }

    .hrp-ag--tooltip-content {
        line-clamp: 2;
        box-orient: vertical;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }

    @include breakpoint-up(lg) {
        th .bx--table-sort__flex {
            height: 70px;
            justify-content: flex-start;
        }

        .hrp-ag--thead th:last-child .#{$prefix}--table-sort {
            padding-right: $spacing-03;
        }

        .hrp-ag--td {
            height: 60px;
        }

        .hrp-ag--td.data-grid-actions-cell {
            width: 10px;
        }

        .#{$prefix}--table-sort {
            padding-left: $spacing-03;
            padding-right: $spacing-03;
        }

        .hrp-ag--td,
        .hrp-ag--tbody .hrp-ag--th {
            padding-left: $spacing-03;
            padding-right: $spacing-03;
        }

        .hrp-ag--td__mobile-th {
            display: none;
        }

        .hrp-ag--td__is-limited {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .hrp-ag--td.hrp-ag--td--pr-0 {
            padding-right: 0;
            font-size: 11px;
        }

        .#{$prefix}--table-sort__icon {
            width: 16px;
            height: 16px;
            margin-right: 0;
        }

        th .#{$prefix}--table-sort__flex {
            font-size: 13px;
        }

        .hrp-ag--td:first-child,
        th:first-child .#{$prefix}--table-sort,
        th:first-child .#{$prefix}--dropdown-text {
            padding-left: $spacing-04 !important;
            padding-right: $spacing-05 !important;

            .bx--dropdown__arrow {
                right: 0;
            }
        }

        th:last-child .#{$prefix}--table-sort {
            padding-right: $spacing-04 !important;
        }

        .hrp-ag--td--width-md {
            width: 250px;
            max-width: 250px;
        }

        .hrp-ag--td--font-size-sm {
            font-size: 11px;
        }
    }

    @include breakpoint-down(lg) {
        thead {
            display: none;
        }

        .hrp-ag--tbody,
        .hrp-ag--tbody .hrp-ag--tr,
        .hrp-ag--tbody .hrp-ag--tr:hover,
        .hrp-ag--tbody .hrp-ag--tr .hrp-ag--td,
        .hrp-ag--tbody .hrp-ag--tr .hrp-ag--td:hover {
            background: none;
        }

        .hrp-ag--tbody .hrp-ag--tr .hrp-ag--td,
        .hrp-ag--tbody .hrp-ag--tr:hover .hrp-ag--td {
            border: none;
        }

        .hrp-ag--tr--no-data {
            display: block;
            border: none;
        }

        .hrp-ag--tr--no-data td,
        .hrp-ag--tr--no-data tr:hover td {
            border: none !important;
            background: none !important;
        }

        .hrp-ag--tr {
            padding-top: $spacing-05;
            padding-bottom: $spacing-05;
            border-bottom: 1px solid $border-subtle-01;
            display: block;
            height: auto;
        }

        .hrp-ag--tr:first-child {
            border-top: 1px solid $border-subtle-01;
        }

        .hrp-ag--td {
            display: flex;
            padding: 0;
        }

        .hrp-ag--td:not(:last-child) {
            margin-bottom: 8px;
        }

        .hrp-ag--td:not(.hrp-ag--td__has-mobile-th):before,
        .hrp-ag--td__has-mobile-th .hrp-ag--td__mobile-th,
        .hrp-ag--cell-content {
            min-width: 50%;
            max-width: 50%;
            width: 50%;
            display: block;
        }

        .hrp-ag--td:not(.hrp-ag--td__has-mobile-th):before,
        .hrp-ag--td__has-mobile-th .hrp-ag--td__mobile-th {
            padding-right: 10px;
            color: #171717;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.16px;
            line-height: 18px;
        }

        .hrp-ag--td:not(.hrp-ag--td__has-mobile-th):before {
            content: attr(data-th);
        }

        .hrp-ag--td.hrp-ag--td--disable-before:before {
            content: none;
        }
    }

    @include breakpoint-down(md) {
        // overflow: hidden; TODO: doublecheck

        .hrp-ag--cell-content {
            overflow-wrap: anywhere;
        }
    }
}
