@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;

.hrp--bundle-products__list {
    border-top: 1px solid $border-subtle-01;
}

.hrp--bundle-products__list-item {
    border-left: 1px solid $border-subtle-01;
    border-right: 1px solid $border-subtle-01;
    border-bottom: 1px solid $border-subtle-01;
    display: grid;

    grid-template-areas:
        'quantity      image       description';
    grid-template-columns: 100px 100px 1fr;
    column-gap: $spacing-03;
}

.hrp--bundle-product__image-wrapper {
    grid-area: image;
    padding: $spacing-03 $spacing-05;
    min-height: 65px;
    box-sizing: content-box;

    img {
        display: block;
        margin: 0 auto;
        width: 65px !important;
        height: 65px !important;
    }
}

.hrp--bundle-product__content-wrapper {
    grid-area: description;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.hrp--bundle-product__title-wrapper {
    color: $text-primary;
    text-decoration: none;
}

.hrp--bundle-product__sku-wrapper {
    color: #8c8c8c;
    margin-top: $spacing-02;
}

.hrp--bundle-product__quantity-wrapper {
    grid-area: quantity;
    border-right: 1px solid $border-subtle-01;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

@include breakpoint-down(lg) {
    .hrp--bundle-products__list-item {
        grid-template-columns: 70px 70px 1fr;
        column-gap: 0;
    }

    .hrp--bundle-product__image-wrapper {
        padding: $spacing-03;
        min-height: 50px;

        img {
            width: 50px !important;
            height: 50px !important;
        }
    }

    .hrp--bundle-product__quantity-wrapper {
        font-size: 18px;
    }
}
