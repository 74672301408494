@use '@carbon/layout/scss/spacing' as *;

.hrp-ag--chips__data-grid-filters-current {
    margin: $spacing-06 0;
    display: none;
}

.hrp-ag--chips__data-grid-filters-current--visible {
    display: block;
}
