@use '../carbon/variables.overrides' as vars;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../carbon/custom/config' as *;

.#{$prefix}--navigation {
    position: absolute;
    background-color: $background;
    display: none;
    box-shadow: 0 .5rem 1rem 0 #00000040;
    left: 0;
    z-index: 9100;

    &.#{$prefix}--navigation--expanded {
        display: block;
    }

    &--minicart {
        border: none;
        color: $text-primary;

        .ui-dialog > & {
            display: block !important;
        }
    }

    &--autocomplete {
        padding: $spacing-05;
        width: 100% !important;
        display: grid;
        display: -ms-grid;
    }

    &--account, &--bookmarks {
        padding: $spacing-05;
    }

    &__category--expanded &__category-items {
        display: block;
        visibility: inherit;
    }

    svg {
        fill: $text-primary;
    }

    @include breakpoint-down(vars.$mobile-menu-breakpoint) {
        top: 100%;
        bottom: inherit;
        width: 100%;
        background-color: $background;

        &--category-menu {
            padding: $spacing-03 0;
        }

        &--account, &--bookmarks, &--minicart, &--notification, &--bookmarks {
            box-shadow: none;
            border-top: 1px solid $border-subtle-01;
            border-bottom: 1px solid $border-subtle-01;
        }

        &--minicart {
            padding: $spacing-05;
            min-height: convert.rem(48px);
        }

        &--autocomplete {
            box-shadow: none;
            padding: 0;
            border-top: 1px solid $border-subtle-01;
        }
    }

    @include breakpoint-up(vars.$mobile-menu-breakpoint) {
        top: 100%;
        bottom: inherit;
        width: 320px;

        &--category-menu, &--account, &--notification, &--bookmarks, &--minicart {
            margin-top: -#{$spacing-03};
        }

        &--account, &--notification, &--bookmarks, &--minicart {
            left: inherit;
            right: 0;
        }

        &--account {
            width: inherit;
            font-size: convert.rem(14px);
        }

        &--notification {
            width: 320px;
        }

        &--minicart {
            width: inherit;
            max-width: 600px;
        }

        &--autocomplete {
            margin-top: $spacing-03;

            &:before {
                right: 1.35rem;
                right: inherit;
            }
        }
    }

    @include breakpoint-down(md) {
        &--account, &--bookmarks, &--minicart, &--notification, &--bookmarks {
            top: 55px;
        }
    }

    @include breakpoint-between(md, sm) {
        &--account, &--bookmarks, &--minicart, &--notification, &--bookmarks {
            top: 65px;
        }
    }
}
