@use "../../../styles/atom/variables" as *;

//
//  Product -- Full Width
//  _____________________________________________

.product-full-width-section {
    padding: 0.5em 0;

    .block.review-add {
        margin-top: 2.7rem;
    }
}

.page-layout-product-full-width {
    .block.related {
        margin-top: 2.7rem;
    }
}

.page-main-details .product-section-title {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: $spacer--medium;
    padding-bottom: $spacer--medium;
}

.additional-attributes-wrapper .additional-attributes {
    border: none;
    width: auto;

    > tbody > tr {
        > th {
            border: none;
            padding: $spacer $spacer--large $spacer--medium 0;
        }
        > td {
            border: none;
            padding: $spacer $spacer $spacer--medium;
        }
    }
}

//
//  Page -- Full Width
//  _____________________________________________

.cms-index-index.page-layout-cms-full-width .nav-sections {
    margin-bottom: 0;
}
