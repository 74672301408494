@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;

[data-content-type="row"][data-appearance="contained"] {
    padding-left: $spacing-05;
    padding-right: $spacing-05;
}

[data-element="main"] > figure:first-child {
    margin-top: 0;
}

[data-content-type="column"] > *:last-child:not([data-content-type="text"]),
[data-content-type="text"] > *:last-child {
    margin-bottom: $spacing-06;
}

[data-content-type="row"] > [data-element="inner"] > [data-content-type="buttons"] {
    margin-bottom: $spacing-03;
}

[data-content-type="buttons"] [data-content-type="button-item"] {
    margin: 0 $spacing-03 $spacing-03 0;
}

[data-content-type="column"] {
    padding-left: $spacing-04;
    padding-right: $spacing-04;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

[data-content-type="slide"],
[data-content-type="banner"],
[data-content-type="slider"] {
    overflow: hidden;
    min-height: inherit !important;
}

// Rows and Columns

@include breakpoint-down(md) {
    [data-content-type="column-group"] {
        display: block !important;
    }

    [data-content-type="column"] {
        width: 100% !important;
        max-width: 100% !important;

        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    [data-content-type="column"] img {
        width: 100%;
    }

    [data-content-type="column"] [data-element="mobile_image"][data-is-small="true"] {
        width: auto;
        max-width: inherit;
        margin: 0 auto;
    }
}
