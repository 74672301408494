@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/utilities/focus-outline' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../carbon/custom/config' as *;

// [data-invalid]
input.mage-error,
.#{$prefix}--text-input__field-wrapper.mage-error,
.#{$prefix}--text-area__wrapper.mage-error > .#{$prefix}--text-area--invalid,
.#{$prefix}--select-input__wrapper.mage-error,
.#{$prefix}--list-box.mage-error,
.#{$prefix}--combo-box.mage-error .#{$prefix}--text-input {
    @include focus-outline('invalid');
}

// -requirement
.mage-error {
    display: block;
    color: $support-error;
    font-weight: 400;
    overflow: visible;
    margin-top: $spacing-02;

    @include type-style('label-01');
}

.input {
    @extend .#{$prefix}--form-item;

    &__field {
        @extend .#{$prefix}--text-input;
    }

    &-text {
        @extend .#{$prefix}--text-input;
    }
}

.input__label, .label {
    @extend .#{$prefix}--label;
}

// from: node_modules/carbon-components/scss/components/checkbox/_checkbox.scss
.#{$prefix}--checkbox:checked ~ .#{$prefix}--checkbox-label::before {
    background-color: $text-primary;
    border-color: $text-primary;
    border-width: 1px;
}

.#{$prefix}--checkbox:checked ~ .#{$prefix}--checkbox-label::after {
    transform: scale(1) rotate(-45deg);
}

.#{$prefix}--checkbox:checked:focus ~ .#{$prefix}--checkbox-label::before {
    // We can't use outline here because of the rounded corners so have to increase the width/height to fake an outline.
    border-color: $button-primary;
    border-width: 3px;
    width: convert.rem(20px);
    height: convert.rem(20px);
    left: -2px;
    top: 0;
}
// endof form

.checkbox {
    @extend .#{$prefix}--form-item;
    @extend .#{$prefix}--checkbox-wrapper;

    &__icon {
        display: none;
    }

    &__field {
        @extend .#{$prefix}--checkbox;
    }

    &__label {
        @extend .#{$prefix}--checkbox-label;
    }
}

.#{$prefix}--text-input__field-wrapper,
.#{$prefix}--text-area__wrapper {
    width: 100%;
    flex-wrap: wrap;
}

.#{$prefix}--text-input--dark-disabled:disabled {
    -webkit-text-fill-color: $text-primary;
}
