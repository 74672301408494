@use '../../config' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/motion' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/styles/scss/theme' as *;
@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/utilities/convert' as *;
@use '@carbon/styles/scss/utilities/component-reset' as *;
@use '@carbon/styles/scss/utilities/focus-outline' as *;
@use '@carbon/styles/scss/utilities/high-contrast-mode' as *;
@use '@carbon/styles/scss/utilities/z-index' as *;

@use '@carbon/styles/scss/components/button';

/// Modal styles
/// @access public
/// @group modal
@mixin modal($mobile: false) {
    .#{$prefix}--modal {
        position: fixed;
        z-index: z('modal');
        top: 0;
        left: 0;
        display: flex;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        background-color: $overlay;
        content: '';
        opacity: 0;
        transition: opacity $duration-moderate-02 motion(exit, expressive),
        visibility 0ms linear $duration-moderate-02;
        visibility: hidden;

        &.is-visible {
            opacity: 1;
            transition: opacity $duration-moderate-02 motion(entrance, expressive),
            visibility 0ms linear;
            visibility: inherit;

            @media screen and (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        // V11: Question for design: do we have an updated tokens for fields that exist on `layer`?
        .#{$prefix}--pagination,
        .#{$prefix}--pagination__control-buttons,
        .#{$prefix}--text-input,
        .#{$prefix}--text-area,
        .#{$prefix}--search-input,
        .#{$prefix}--select-input,
        .#{$prefix}--dropdown,
        .#{$prefix}--dropdown-list,
        .#{$prefix}--number input[type='number'],
        .#{$prefix}--date-picker__input,
        .#{$prefix}--multi-select {
            background-color: $field-02;
        }
    }

    .#{$prefix}--modal.is-visible .#{$prefix}--modal-container {
        transform: translate3d(0, 0, 0);
        transition: transform $duration-moderate-02 motion(entrance, expressive);
    }

    // -----------------------------
    // Modal container
    // -----------------------------
    .#{$prefix}--modal-container {
        display: grid;
        overflow: hidden;

        @if($mobile) {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            max-height: 100%;
        } @else {
            position: static;
            width: 84%;
            height: auto;
            max-height: 90%;
        }

    background-color: $layer;
        grid-template-columns: 100%;
        grid-template-rows: auto 1fr auto;
        // make modal edge visible on high contrast themes (#3880)
        outline: 3px solid transparent;
        outline-offset: -3px;
        transform: translate3d(0, -24px, 0);
        transform-origin: top center;
        transition: transform $duration-moderate-02 motion(exit, expressive);

        @include breakpoint(md) {
            position: static;
            width: 84%;
            height: auto;
            max-height: 90%;
        }

        @include breakpoint(lg) {
            width: 60%;
            max-height: 84%;
        }

        @include breakpoint(xlg) {
            width: 48%;
        }

        .#{$prefix}--modal-container-body {
            display: contents;
        }
    }

    // -----------------------------
    // Modal content
    // -----------------------------
    .#{$prefix}--modal-content {
        @include type-style('body-01');

        position: relative;
        // Required to accommodate focus outline's negative offset:
        padding-top: $spacing-03;
        // anything besides text/p spans full width, with just 16px padding
        padding-right: $spacing-05;
        padding-left: $spacing-05;
        margin-bottom: $spacing-09;
        color: $text-primary;
        font-weight: 400;
        grid-column: 1/-1;
        grid-row: 2/-2;
        overflow-y: auto;

        &:focus {
            @include focus-outline('outline');
        }
    }

    //TO-DO: remove .#{$prefix}--modal-content__regular-content in v11 since hasForm has been deprecated
    // text/p gets 20% right padding
    .#{$prefix}--modal-content p,
    .#{$prefix}--modal-content__regular-content {
        @include type-style('body-01');
        // padding should take into account the left and right padding of modal container
        padding-right: calc(20% - $spacing-07);
    }

    //TO-DO: remove .#{$prefix}--modal-content--with-form in v11 since hasForm has been deprecated\
    // anything besides text/p spans full width, with just 16px padding
    .#{$prefix}--modal-content--with-form {
        padding-right: $spacing-05;
    }

    // -----------------------------
    // Modal header
    // -----------------------------
    .#{$prefix}--modal-header {
        padding-top: $spacing-05;
        padding-right: $spacing-09;
        padding-left: $spacing-05;
        margin-bottom: $spacing-03;
        grid-column: 1/-1;
        grid-row: 1/1;
    }

    .#{$prefix}--modal-header__label {
        @include type-style('label-01');

        margin-bottom: $spacing-02;
        color: $text-secondary;
    }

    .#{$prefix}--modal-header__heading {
        @include type-style('heading-03');

        color: $text-primary;
    }

    // -----------------------------
    // XS Modal
    // -----------------------------
    .#{$prefix}--modal-container--xs {
        //text always spans full width in xs modals
        .#{$prefix}--modal-content__regular-content {
            padding-right: $spacing-05;
        }

        .#{$prefix}--modal-content p {
            //.#{$prefix}--modal-content already has 16px padding so this doesn't need any
            padding-right: 0;
        }

        @include breakpoint(md) {
            width: 48%;
        }

        @include breakpoint(lg) {
            width: 32%;
            max-height: 48%;
        }

        @include breakpoint(xlg) {
            width: 24%;
        }
    }

    // -----------------------------
    // SM Modal
    // -----------------------------
    .#{$prefix}--modal-container--sm {
        //text spans full width in sm modals below 1056
        .#{$prefix}--modal-content__regular-content {
            padding-right: $spacing-05;
        }

        .#{$prefix}--modal-content p {
            //.#{$prefix}--modal-content already has 16px padding so this doesn't need any
            padding-right: 0;
        }

        @include breakpoint(md) {
            width: 60%;
        }

        @include breakpoint(lg) {
            width: 42%;
            max-height: 72%;

            .#{$prefix}--modal-content p,
            .#{$prefix}--modal-content__regular-content {
                padding-right: 20%;
            }
        }

        @include breakpoint(xlg) {
            width: 36%;
        }
    }

    // -----------------------------
    // LG Modal
    // -----------------------------
    .#{$prefix}--modal-container--lg {
        @include breakpoint(md) {
            width: 96%;
        }

        @include breakpoint(lg) {
            width: 84%;
            max-height: 96%;
        }

        @include breakpoint(xlg) {
            width: 72%;
        }
    }

    // -----------------------------
    // Modal overflow
    // -----------------------------
    // Required so overflow-indicator disappears at end of content
    .#{$prefix}--modal-scroll-content > *:last-child {
        padding-bottom: $spacing-07;
    }

    .#{$prefix}--modal-content--overflow-indicator {
        position: absolute;
        bottom: $spacing-09;
        left: 0;
        width: 100%;
        height: rem(32px);
        background-image: linear-gradient(to bottom, transparent, $layer);
        content: '';
        grid-column: 1/-1;
        grid-row: 2/-2;
        pointer-events: none;
    }

    // Safari-only media query
    // won't appear correctly with CSS custom properties
    // see: code snippet and tabs overflow indicators
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
            .#{$prefix}--modal-content--overflow-indicator {
                background-image: linear-gradient(to bottom, rgba($layer, 0), $layer);
            }
        }
    }

    .#{$prefix}--modal-content:focus
    ~ .#{$prefix}--modal-content--overflow-indicator {
        width: calc(100% - 4px);
        margin: 0 2px 2px;
    }

    @media screen and (-ms-high-contrast: active) {
        .#{$prefix}--modal-scroll-content > *:last-child {
            padding-bottom: 0;
        }

        .#{$prefix}--modal-content--overflow-indicator {
            display: none;
        }
    }

    // -----------------------------
    // Modal footer
    // -----------------------------
    .#{$prefix}--modal-footer {
        display: flex;
        height: rem(64px);
        justify-content: flex-end;
        margin-top: auto;
        grid-column: 1/-1;
        grid-row: -1/-1;
    }

    .#{$prefix}--modal-footer .#{$prefix}--btn {
        max-width: none;
        height: rem(64px);
        flex: 0 1 50%;
        padding-top: $spacing-05;
        padding-bottom: $spacing-07;
        margin: 0;
    }

    .#{$prefix}--modal-footer--three-button .#{$prefix}--btn {
        flex: 0 1 25%;
        align-items: flex-start;
    }

    // -----------------------------
    // Modal close btn
    // -----------------------------
    .#{$prefix}--modal-close {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        overflow: hidden;
        width: 3rem;
        height: 3rem;
        padding: rem(12px);
        border: 2px solid transparent;
        background-color: transparent;
        cursor: pointer;
        transition: background-color $duration-fast-02 motion(standard, productive);

        &:hover {
            background-color: $layer-hover;
        }

        &:focus {
            border-color: $focus;
            outline: none;
        }
    }

    .#{$prefix}--modal-close::-moz-focus-inner {
        border: 0;
    }

    .#{$prefix}--modal-close__icon {
        width: rem(20px);
        height: rem(20px);
        fill: $icon-primary;
    }

    .#{$prefix}--body--with-modal-open {
        overflow: hidden;
    }

    .#{$prefix}--body--with-modal-open .#{$prefix}--tooltip,
    .#{$prefix}--body--with-modal-open .#{$prefix}--overflow-menu-options {
        z-index: z('modal');
    }

    // Windows HCM fix
    /* stylelint-disable */
    .#{$prefix}--modal-close__icon {
        @include high-contrast-mode('icon-fill');
    }

    .#{$prefix}--modal-close:focus {
        @include high-contrast-mode('focus');
    }
    /* stylelint-enable */
}
