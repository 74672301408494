@use '@carbon/layout/scss/spacing' as *;

.wishlist {
    &__item {
        position: relative;
    }

    &__item-actions {
        position: absolute;
        top: $spacing-04;
        right: $spacing-04;
    }
}
