@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

$table__padding                  : 16px !default;
$table__color                    : $layer-selected-inverse !default;
$table__background               : $layer-02 !default;
$table__font-size                : 14px !default;
$table__line-height              : 1.5 !default;
$table__border-radius            : 0 !default;
$table__border                   : 1px solid $border-subtle-01 !default;
$table__th-background            : $layer-01 !default;
$table__td-background            : $layer-02 !default;
$table__tfoot-background         : $layer-01 !default;
$table__background-odd--odd-even : $layer-02 !default;
$table__background-even--odd-even: #f9f9f9 !default;
$table__th-font-weight--clean    : font-family.font-weight('semibold') !default;
$table__th-background--clean     : $layer-02 !default;
$table__background-odd--clean    : transparent !default;
$table__background-even--clean   : transparent !default;

.table {
    width: 100%;
    background: $table__background;
    margin: 1em 0;
    border: $table__border;
    border-radius: $table__border-radius;
    color: $table__color;
    border-collapse: separate;
    border-spacing: 0;
    font-size: $table__font-size;
    line-height: $table__line-height;

    &__caption,
    &__visually-hidden {
        @include visually-hidden();
    }

    thead {
        tr {
            padding: 0;
            border-bottom: 0;

            @include breakpoint(md) {
                border-top-left-radius: $table__border-radius;
                border-top-right-radius: $table__border-radius;
            }
        }

        th {
            &:first-child {
                @include breakpoint(md) {
                    border-top-left-radius: $table__border-radius;
                }
            }

            &:last-child {
                @include breakpoint(md) {
                    border-top-right-radius: $table__border-radius;
                }
            }
        }
    }

    tr {
        display: block;
        padding: $table__padding 0;
        border-bottom: $table__border;

        @include breakpoint(md) {
            padding: 0;
            border-bottom: 0;
            display: table-row;
        }
    }

    th {
        display: none;

        &:last-child {
            @include breakpoint(md) {
                border-right: 0;
            }
        }

        @include breakpoint(md) {
            display: table-cell;
            padding: $table__padding;
            background: $table__th-background;
            border-bottom: $table__border;
            border-right: $table__border;
        }
    }

    td {
        display: flex;
        padding: 5px $table__padding;
        background: $table__td-background;

        &:before {
            grid-area: before;
            content: attr(data-th);
            font-weight: bold;
            // margin-right: 10px;
            // max-width: 25%;
            // min-width: 25%;
            // width: 25%;
            display: none;

            @include breakpoint-down(md) {
                display: block;
            }
        }

        &:last-child {
            @include breakpoint(md) {
                border-right: 0;
            }
        }

        > div {
            grid-area: content;
        }

        @include breakpoint(md) {
            display: table-cell;
            padding: $table__padding;
            background: $table__td-background;
            border-bottom: $table__border;
            border-right: $table__border;
        }
    }

    @include breakpoint(md) {
        tbody tr:last-child td:first-child {
            border-bottom-left-radius: $table__border-radius;
        }

        tbody tr:last-child td:last-child {
            border-bottom-right-radius: $table__border-radius;
        }
    }

    tfoot tr:last-child {
        border-bottom: 0;

        td {
            border-bottom: 0;

            &:first-child {
                @include breakpoint(md) {
                    border-bottom-left-radius: $table__border-radius;
                }
            }

            &:last-child {
                @include breakpoint(md) {
                    border-bottom-right-radius: $table__border-radius;
                }
            }
        }

        td {
            display: block;
            padding: 5px $table__padding;
            background: $table__background;

            @include breakpoint(md) {
                display: table-cell;
                padding: $table__padding;
                background: $table__tfoot-background;
                border-right: $table__border;
            }
        }
    }

    &__td--align-right {
        text-align: center;

        @include breakpoint-down(md) {
            text-align: left;
        }
    }

    &__td--qty,
    &__th--qty {
        @include breakpoint-up(md) {
            width: 280px;
        }
    }

    &__td--vertical-middle {
        vertical-align: middle !important;
    }

    &--odd-even {
        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--odd-even;
                }

                &:nth-child(even) {
                    background: $table__background-even--odd-even;
                }

                &:first-child {
                    border-top-left-radius: $table__border-radius;
                    border-top-right-radius: $table__border-radius;

                    @include breakpoint(md) {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }

            td {
                background-color: transparent;
            }
        }
    }

    &--clean {
        margin-top: 0;
        margin-bottom: $spacing-06;
        border: 0;
        border-radius: 0;
        break-inside: avoid;
        background-color: transparent;

        .label {
            margin-bottom: 0;
            font-size: inherit;
            color: $text-primary;
        }

        .data {
            color: $text-secondary;
        }

        thead {
            tr {
                padding: 0;
                border-bottom: 0;
            }
        }

        tr {
            padding: 0;
            border-bottom: 0;
            margin-bottom: 5px;
        }

        th {
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 21px;
            border-bottom: 1px solid $table__border;
            border-right: 0;
            background: $table__th-background--clean;
            text-align: left;
            font-weight: $table__th-font-weight--clean;

            @include breakpoint-down(md) {
                display: block;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        td {
            border-bottom: 1px solid $table__border;
            border-right: 0;
            background: #fff;
            padding: 5px 16px;
            line-height: 21px;
            vertical-align: top;
        }

        &:not(.table--clean-mobile) td:before {
            display: none;
        }

        tr:last-child td {
            border-bottom: 0;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--clean;
                }

                &:nth-child(even) {
                    background: $table__background-even--clean;
                }
            }
        }
    }

    @include breakpoint-down(md) {
        &--clean-mobile {
            thead {
                display: none;
            }

            td {
                display: block;
            }

            td:before {
                content: attr(data-th) ': ';
            }
        }

        &--mobile-border {
            border: 3px solid #ededed;

            td:not(:last-child) {
                border-bottom: 3px solid #ededed !important;
            }
        }

        &--first-td-with-background-in-mobile td:first-child {
            background-color: $layer-01;
        }

        &--mobile-break-word td {
            word-break: break-word;
        }
    }

    &--responsive {
        @include breakpoint-down(md) {
            thead {
                display: none;
            }

            td {
                flex-wrap: wrap;
                padding-left: 0;
                padding-right: 0;
            }

            td:before {
                display: block;
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                margin-bottom: $spacing-03;
            }

            tr {
                padding-bottom: $spacing-05;
                margin-bottom: $spacing-05;
                border-bottom: 1px solid $layer-02;
            }
        }
    }

    &--nowrap {
        @include breakpoint-up(md) {
            white-space: nowrap;
        }
    }

    &--product-attributes {
        @include breakpoint-down(md) {
            .label {
                @include type-style('label-01');
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &__wrapper {
        @include breakpoint-up(lg) {
            column-count: 2;
            column-gap: $spacing-07;
        }
    }

    &__wrapper--responsive {
        display: flex;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &--vertical-top td {
        vertical-align: top;
    }

    &--lg td {
        padding-top: $spacing-05;
        padding-bottom: $spacing-05;

        @include breakpoint-down(md) {
            padding-top: $spacing-03;
            padding-bottom: $spacing-03;
        }
    }
}

// TODO: move up
@import 'table/product';
@import 'table/radio';
@import 'table/with-head';
