@use '../custom/components/header/variables.shell-header' as *;
@use '../movable/ui-shell/theme' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../../carbon/custom/config' as *;

$tag-bg--end-of-sale: #fe5964;
$tag-bg--promoted: #ffe066;
$tag-color--promoted: #171717;


.#{$prefix}--tag {
    &--only-for-order {
        background-color: $shell-side-nav-bg-05;
        color: $text-secondary;
    }

    &--end-of-sale {
        background-color: $tag-bg--end-of-sale;
        color: $text-on-color;
    }

    &--end-of-sale .#{$prefix}--tag__icon {
        fill: $text-on-color;
    }

    &--promoted {
        background-color: $tag-bg--promoted;
        color: $tag-color--promoted;
        cursor: pointer;
    }

    &--promoted .#{$prefix}--tag__icon {
        fill: $tag-color--promoted;
    }

    &--promoted &__text {
        color: $tag-color--promoted;
        text-decoration: none;
    }

    &--is-new {
        background-color: #29339b;
        color: #fff;
    }

    &--is-new .#{$prefix}--tag__icon {
        fill: #fff;
    }

    &--has-used {
        background-color: #35a7ff;
        color: $text-primary;
        cursor: pointer;
    }

    &--has-used .#{$prefix}--tag__icon {
        fill: $text-primary;
    }

    &--soft-bundle {
        background-color: rgb(30 129 167);
        color: #fff;
    }

    &--soft-bundle .#{$prefix}--tag__icon {
        fill: #fff;
    }

    &--soft-bundle-component {
        background-color: rgb(30 129 167);
        color: #fff;
    }

    &--soft-bundle-component .#{$prefix}--tag__icon {
        fill: #fff;
    }

    &--is-esd {
        background-color: #079b6c;
        color: #fff;
    }

    &--is-esd .#{$prefix}--tag__icon {
        fill: #cfeaff;
    }

    &__icon {
        display: block;
        margin-right: $spacing-02;
        margin-top: convert.rem(1px);
        flex: 0 0 convert.rem(14px);
        width: convert.rem(14px);
        height: convert.rem(14px);
    }

    &--has-package {
        background-color: $support-warning-inverse;
        color: $text-on-color;
    }
}
