@use '@carbon/motion' as *;
@use '../carbon/custom/components/header/variables.shell-header' as *;
@use '../carbon/custom/config' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp--countable__counter-wrapper {
    position: relative;
    display: block;
}

.hrp--countable__counter-wrapper:hover .hrp--countable__counter {
    box-shadow: 0 0 0 2px $shell-header-bg-04;
}

.hrp--countable__counter {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 25px;
    height: 1rem;
    min-height: 1rem;
    line-height: 1rem;
    min-width: 1rem;
    text-align: center;
    padding: 0 0.3rem;
    font-size: 0.6rem;
    background-color: $support-error;
    color: $text-inverse;
    box-shadow: 0 0 0 2px $focus-inset;
    transition: box-shadow $duration-fast-02;

    &--hidden {
        display: none;
    }

    &--lg {
        top: -5px;
    }
}

.hrp--countable__counter-dot {
    white-space: nowrap;
}
