@use '@carbon/styles/scss/type' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/convert';
@use '../carbon/custom/config' as *;

.list--with-icon {
    display: flex;
}

.list__icon-link {
    box-shadow: none !important;
}

.#{$prefix}--website-footer {
    @include type-style('body-short-01');
    width: 100%;
    background: $layer-02;
    color: $text-primary;
    position: relative;
    z-index: 2;
}

.#{$prefix}--website-footer .hrp--header__language {
    margin-left: -#{$spacing-05};
}

.#{$prefix}--website-footer .hrp--header__language {
    margin-left: -#{$spacing-04};
}

.#{$prefix}--website-footer .#{$prefix}--grid {
    padding-top: $spacing-06;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-up('md') {
        padding-bottom: $spacing-07;
    }
}

// Footer nav lists
.#{$prefix}--website-footer__nav {
    margin-bottom: $spacing-06;
    position: relative;

    @include breakpoint-down('md') {
        margin-top: $spacing-06;
    }
}

.#{$prefix}--website-footer__nav li {
    display: block;

    @include breakpoint('sm') {
        margin: 0 0 $spacing-03 0;
    }

    @include breakpoint('lg') {
        margin: 0;
    }
}

// Footer link styles
.#{$prefix}--website-footer a {
    color: $text-secondary;
    text-decoration: none;
}

.#{$prefix}--website-footer .#{$prefix}--website-footer__link {
    box-shadow: 0 0 currentColor;
}

.#{$prefix}--website-footer__content,
.#{$prefix}--website-footer__content p {
    @include type-style('body-short-01');
    margin-bottom: $spacing-03 !important;
    font-size: var(--#{$prefix}-body-short-01-font-size, 0.875rem) !important;
    line-height: var(--#{$prefix}-body-02-line-height, 1.5) !important;

    @include breakpoint('lg') {
        text-align: right;
    }
}

.footer__social-handler-wrapper {
    display: flex;
}

.footer__social-handler {
    display: flex;
    align-items: center;
    align-self: center;
}

.footer__creator {
    display: flex;
    align-items: center;

    @include breakpoint-down('md') {
        justify-content: start;
    }

    @include breakpoint-up('md') {
        justify-content: end;
    }

    column-gap: 5px;
}

.footer__creator-text {
    white-space: nowrap;
    font-size: 11px;
}

.footer__creator-icon {
    width: 50px;
    height: 50px;
}
