@use '@carbon/styles/scss/utilities/tooltip' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../../carbon/custom/config' as *;
// rollback old tooltips

.#{$prefix}--tooltip__trigger {
    @include tooltip--trigger();

    &:hover:after {
        content: none;
    }

    &:focus:before {
        content: none;
    }

    &:focus:hover:before {
        content: '';
    }

    &:focus .#{$prefix}--assistive-text {
        display: none;
    }

    &:focus:hover .#{$prefix}--assistive-text {
        display: block;
    }
}

.#{$prefix}--tooltip--bottom {
    @include tooltip--placement('definition', 'bottom', 'center');

    .#{$prefix}--assistive-text {
        padding: $spacing-04 $spacing-05;
    }
}

.#{$prefix}--tooltip--bottom-start {
    @include tooltip--placement('definition', 'bottom', 'start');

    .#{$prefix}--assistive-text {
        padding: $spacing-04 $spacing-05;
    }
}

.#{$prefix}--tooltip--bottom-end {
    @include tooltip--placement('definition', 'bottom', 'end');

    .#{$prefix}--assistive-text {
        padding: $spacing-04 $spacing-05;
    }
}

.#{$prefix}--tooltip--top {
    @include tooltip--placement('definition', 'top', 'center');

    .#{$prefix}--assistive-text {
        padding: $spacing-04 $spacing-05;
    }
}

.#{$prefix}--tooltip--top-start {
    @include tooltip--placement('definition', 'top', 'start');

    .#{$prefix}--assistive-text {
        padding: $spacing-04 $spacing-05;
    }
}

.#{$prefix}--tooltip--top-end {
    @include tooltip--placement('definition', 'top', 'end');
    &:before {
        left: 90% !important; // TODO: update carbon
    }

    .#{$prefix}--assistive-text {
        padding: $spacing-04 $spacing-05;
    }
}
