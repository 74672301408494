@use '@carbon/colors';
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;

//  Slick slider styles
//  _____________________________________________

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: 1rem;
}

.slick-next {
    right: 1rem;
}

.slick-prev,
.slick-next {
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 101;

    svg {
        fill: colors.$white;
    }

    &.disabled,
    &.slick-disabled {
        opacity: 0.2;
    }
}

.slick-dots {
    bottom: $spacing-06;
    display: block;
    list-style: none;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 14px;
        margin: 0 $spacing-02 !important;
        padding: 0;
        position: relative;
        vertical-align: middle;
        width: 14px;

        button {
            background: colors.$white;
            border: 1px solid $button-primary;
            border-radius: 10px;
            box-shadow: none;
            cursor: pointer;
            display: block;
            height: 14px;
            padding: 0;
            text-indent: -99999px;
            transition: 0.3s;
            width: 14px;

            &:hover {
                border: 1px solid $button-primary-hover;
            }

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }

        &.slick-active {
            button {
                background: $button-primary;
            }
        }
    }
}
