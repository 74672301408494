@use "../../../../styles/atom/variables" as *;
@use "../../../../styles/atom/mq" as *;

//
//  Default appearance styles
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    .pagebuilder-column-line {
        flex-wrap: wrap;
    }
}
