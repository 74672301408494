@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp--invoice-overview__container {
    border: 1px solid $border-subtle-01;
    display: flex;
    column-gap: $spacing-07;
    padding: $spacing-06;
}

@include breakpoint-down(md) {
    .hrp--invoice-overview__container {
        flex-wrap: wrap;
    }
}

@include breakpoint-up(md) {
    .hrp--invoice-overview__col--details {
        width: calc(3/12 * 100%);
    }

    .hrp--invoice-overview__col--shipping {
        width: calc(6/12 * 100%);
        border-left: 1px solid $border-subtle-01;
        border-right: 1px solid $border-subtle-01;
        padding: 0 $spacing-07;
    }

    .hrp--invoice-overview__col--prices {
        width: calc(3/12 * 100%);
    }
}
