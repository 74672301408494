@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../../../carbon/custom/config' as *;

.dashboard-nav {
    margin-bottom: $spacing-05;

    &__mobile {
        display: block;
        position: relative;
        border-width: 1px 0;
        border-color: $layer-02;
        border-style: solid;
        padding: $spacing-06 $spacing-05;
        cursor: pointer;

        @include breakpoint-up('md') {
            display: none;
        }

        &:after {
            content: '\25bc';
            position: absolute;
            width: 15px;
            height: 15px;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &--active {
            &:after {
                content: '\25b2';
            }
        }
    }

    &__content {
        display: none;
        //background-color: $gray-lighter;
        margin-bottom: $spacing-07;

        &--visible {
            display: block;
        }

        @include breakpoint-up('md') {
            display: block;
        }
    }

    &__list {
        padding: $spacing-06 $spacing-05;

        .nav.item {
            margin-bottom: $spacing-03;

            a {
                // TODO: add style via HTML
                @extend .#{$prefix}--link;
            }
        }
    }
}
