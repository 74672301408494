@use '@carbon/grid/scss/breakpoint' as *;

.hrp-ag--outer-wrap {
    display: flex;
    flex-wrap: wrap;

    > * {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.hrp-ag--disable-overflow .table__wrapper--responsive {
    overflow-y: visible;
    overflow-x: visible;
}

@include breakpoint-up(lg) {
    .hrp-ag__col--padding-top-lg {
        padding-top: 24px;
    }
}
