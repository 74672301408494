@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;
@use '@carbon/layout/scss/convert';

.feature-tile--margin {
  margin-bottom: $spacing-06;
}

a.feature-tile {
  display: block;
  text-decoration: none;
  width: 100%;
}

.tile__img .gatsby-resp-image-wrapper {
  background: transparent;
  margin: 0 !important; //needed to override automatic gatsby styles
  max-width: 100% !important; //needed to override automatic gatsby styles
}

.feature-tile__tile {
  @include breakpoint('sm') {
    width: 100%;
    position: relative;
  }

  @include breakpoint('md') {
    width: 50%;
    position: absolute;
    right: 1rem;
    bottom: 0;
  }

  @include breakpoint('lg') {
    width: 25%;
  }
}

a.feature-tile:hover .feature-tile__info {
  background-color: $toggle-off;
}

.feature-tile__info {
  background-color: $layer-accent-01;
  padding: $spacing-05;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.feature-tile__info p {
  padding-bottom: 0;
  line-height: 1.25;
}

.feature-tile__icon {
  position: absolute;
  bottom: convert.rem(14px);
  right: $spacing-05;
  fill: $text-primary;
}

.feature-tile__label {
  @include type-style('body-short-01');
  color: $text-secondary;
}

.feature-tile__title {
  @include type-style('expressive-heading-03', true);
  color: $text-primary;
}

a.feature-tile.feature-tile--mobile {
  display: block;

  @include breakpoint('md') {
    display: none;
  }
}

a.feature-tile.feature-tile--desktop {
  display: none;

  @include breakpoint('md') {
    display: block;
  }
}

div.feature-tile--margin.feature-tile--mobile,
div.feature-tile--mobile {
  margin-bottom: $spacing-05;

  @include breakpoint('md') {
    margin-bottom: 0;
  }
}

div.feature-tile--margin.feature-tile--desktop,
div.feature-tile--desktop {
  margin-bottom: 0;

  @include breakpoint('md') {
    margin-bottom: $spacing-05;

    & > .#{$prefix}--col-lg-12 {
      padding: 0;
    }
  }
}
