@use "../atom/variables";

@mixin focus-inline($mode: '') {
    outline: variables.$outline-focus-inline;
    text-decoration: variables.$text-decoration-focus-inline;
    @if $mode == 'dark' {
        color: variables.$color-focus-inline-dark;
        background-color: variables.$bg-focus-inline-dark;
    }
    @else {
        color: variables.$color-focus-inline;
        background-color: variables.$bg-focus-inline;
    }
}

@mixin ellipsis($width: 100%) {
    max-width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin btn--flat {
    height: 40px;
    min-height: 40px;
    padding: 0.5rem;
}
