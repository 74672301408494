@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../../carbon/custom/config' as *;

.hrp-ag--mobile-action {
    @include breakpoint-up(lg) {
        display: none;
    }
}

.hrp-ag--mobile-action--hidden {
    display: none;
}

.hrp-ag--mobile-action--paging {
    border-top: 1px solid $border-subtle-01;
}

.hrp-ag--mobile-action--sort {
    margin-bottom: 7px;
}

.hrp-ag--mobile-action--paging,
.hrp-ag--mobile-action--mobile-select-all,
.hrp-ag--mobile-action--sort {
    @include breakpoint-down(lg) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        border-bottom: 1px solid $border-subtle-01;
        display: flex;

        .hrp-ag--mobile-action__select:not(.hrp-ag--mobile-action__select--xl) {
            width: calc(100% - 1rem - 40px);
            max-width: calc(100% - 1rem - 40px);
            margin-right: auto;
        }

        .hrp-ag--mobile-action__select--xl {
            width: 100%;
            max-width: 100%;
        }
    }
}

.hrp-ag--mobile-action__button {
    background-color: $field-01;

    &:hover {
        background-color: $layer-hover-01;
    }
}
