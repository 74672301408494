@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../carbon/custom/config' as *;

@include breakpoint-down(md) {
    .breadcrumbs {
        min-height: 36px;
        max-width: 100%;
        box-sizing: content-box;
    }

    .breadcrumbs__list {
        display: flex;
        flex-wrap: wrap;
    }
}

@include breakpoint-down(lg) {
    .breadcrumbs {
        padding-top: $spacing-04;
    }

    .breadcrumbs__list--size-4,
    .breadcrumbs__list--size-5 {
        .breadcrumbs__item:nth-last-child(2):after {
            display: none;
        }

        .breadcrumbs__item:nth-last-child(1) {
            display: none !important;
        }
    }
}

.breadcrumbs__item {
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* extends carbon's breadcrumb */
.#{$prefix}--breadcrumb-item {
    display: block !important;
}

.#{$prefix}--breadcrumb-item:after {
    margin-right: $spacing-01;
}
