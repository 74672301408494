@use '@carbon/colors' as *;
@use '@carbon/styles/scss/components/tag/mixins' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../../carbon/custom/config' as *;

.#{$prefix}--tag--gray.#{$prefix}--tag--filter {
    @include tag-theme($gray-20, $gray-100);
    cursor: inherit;

    svg {
        fill: $gray-100;
        margin-left: convert.rem(4px);
        padding: convert.rem(2px);
        width: convert.rem(20px);
        height: convert.rem(20px);

        &:hover {
            border-radius: 50%;
            background-color: $button-secondary-hover;
            fill: $field-hover-01;
        }
    }
}
