@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/colors';

.hrp--amount {
    padding-left: 20px;
    position: relative;
    display: grid;
    grid-template-areas: "label button" "value button";
    grid-template-rows: min-content min-content;
    column-gap: $spacing-07;
}

.hrp--amount:before {
    content: '';
    width: 3px;
    position: absolute;
    top: 3px;
    bottom: 4px;
    left: 0;
    background-color: $interactive;
}

.hrp--amount--gray:before {
    background-color: $border-subtle-01;
}

.hrp--amount--red:before {
    background-color: #A00D27;
}

.hrp--amount--green:before {
    background-color: $support-success;
}

.hrp--amount--bg-white {
    padding-top: 8px;
    padding-bottom: 5px;
    background-color: colors.$white;
}

.hrp--amount--bg-white.hrp--amount--green {
    border-left: 3px solid $support-success;
}

.hrp--amount--bg-white.hrp--amount--green:before {
    content: none;
}

@include breakpoint-down(lg) {
    .hrp--amount--mobile-mb {
        margin-bottom: $spacing-04;
    }
}

.hrp--amount__label {
    grid-area: label;
}

.hrp--amount__value {
    grid-area: value;
    font-weight: font-family.font-weight('semibold');
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: $button-secondary;
}

.hrp--amount__value--mobile-small {
    @include breakpoint-down(lg) {
        font-size: 14px;
    }
}

.hrp--amount__button-wrapper {
    grid-area: button;
    display: flex;
    align-items: center;

    @include breakpoint-down(lg) {
        position: absolute;
        top: 0;
        right: 10px;
    }
}

.hrp--amount__button {
    font-size: 11px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #E6E6E6;
}
