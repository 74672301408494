@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../carbon/custom/config' as *;

.#{$prefix}--sidebar-block {
    padding: $spacing-05 0;
    margin-bottom: $spacing-05;
    border-radius: 2px;
}

.#{$prefix}--sidebar-block--highlighted {
    border-radius: 0;
    background-color: $field-01;
    border-top: 2px solid $button-primary;
    padding-left: $spacing-05;
    padding-right: $spacing-05;
}

.#{$prefix}--sidebar-block--strong {
    border-top: none;
    border-bottom: 3px solid $button-primary;
}

.#{$prefix}--sidebar-block--padding {
    padding-left: $spacing-05;
    padding-right: $spacing-05;
}

.#{$prefix}--sidebar-block--with-padding {
    padding-left: $spacing-05;
    padding-right: $spacing-05;
}

.#{$prefix}--sidebar-block--related-product {
    background: transparent;
    border-left: 1px solid $layer-02;
    border-right: 1px solid $layer-02;
    border-bottom: 1px solid $layer-02;
}

.#{$prefix}--sidebar-block__title {
    @include reset;
    @include type-style('heading-02');

    color: $text-primary;
    display: block;
    margin-bottom: $spacing-05;

    &--medium {
        @include type-style('heading-01');
    }
}

.#{$prefix}--sidebar-block__list-item {
    @include reset;
    @include type-style('body-short-01');

    line-height: 1.5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: convert.rem(24px);
    user-select: none;
}

.#{$prefix}--sidebar-block__list-item--lg-spaces {
    margin-bottom: convert.rem(3px);
}
