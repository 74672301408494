@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../carbon/custom/config' as *;

@forward 'helpers/flex';
@forward 'helpers/border';
@forward 'helpers/position';
@forward 'helpers/size';
@forward 'helpers/top';

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

@include breakpoint-down(md) {
    .w100--sm {
        width: 100%;
    }
}

$sides: (
    "": "all",
    "t": "top",
    "b": "bottom",
    "l": "left",
    "r": "right",
);

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function remove-spacing($string) {
    @return str-replace(str-replace($string, 'spacing-0', ''), 'spacing-', '');
}

@each $name, $breakpoint in $grid-breakpoints {
    $modifier: if($name == 'sm', '', --#{$name});
    @include breakpoint-up($name) {
        @each $spacing-name, $size in $spacing {
            @each $side-name, $side in $sides {
                $property: if($side-name == '', '', -#{$side});
                $number: remove-spacing($spacing-name);

                .m#{$side-name}-#{$number}#{$modifier} {
                    margin#{$property}: #{$size};
                }
                .p#{$side-name}-#{$number}#{$modifier} {
                    padding#{$property}: #{$size};
                }
            }
        }

        .mb-0#{$modifier} {
            margin-bottom: 0;
        }

        .pb-0#{$modifier} {
            padding-bottom: 0;
        }
    }
}
