@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../carbon/custom/variables' as *;

.form-actions {
    display: flex;

    @include breakpoint-down(lg) {
        max-width: $card-width;
        margin: 0 auto;
        flex-wrap: wrap;

        &__button {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            display: inline-block;
            margin-bottom: $spacing-04;
        }
    }

    @include breakpoint-up(lg) {
        &__button {
            margin-left: $spacing-03;
        }

        &__button--back {
            margin-right: auto;
            margin-left: 0;
        }
    }
}
