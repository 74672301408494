@use '../../../../styles/carbon/custom/config' as *;
@use '@carbon/styles/scss/components/button/vars' as btn-vars;
@use '@carbon/styles/scss/components/button/mixins' as btn;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/styles/scss/theme' as *;
@use '@carbon/themes/scss/tokens' as *;


//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;

    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }

    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @include btn.button-base;
        @include btn.button-theme(
                transparent,
                transparent,
                $link-primary,
                $layer-hover,
                currentColor,
                $layer-active
        );

        padding: btn-vars.$button-padding-ghost;

        display: inline-flex !important; // sass-lint:disable-line no-important
        box-shadow: none;
    }

    &.pagebuilder-button-primary {
        @include btn.button-base;
        @include btn.button-theme(
                $button-primary,
                transparent,
                $text-on-color,
                $button-primary-hover,
                currentColor,
                $button-primary-active
        );

        display: inline-flex !important; // sass-lint:disable-line no-important
        box-shadow: none;
        text-decoration: none;
    }

    &.pagebuilder-button-secondary {
        @include btn.button-base;
        @include btn.button-theme(
                $button-secondary,
                transparent,
                $text-on-color,
                $button-secondary-hover,
                currentColor,
                $button-secondary-active
        );

        display: inline-flex !important; // sass-lint:disable-line no-important
        box-shadow: none;
        text-decoration: none;
    }
}
