@use '@carbon/colors' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/styles/scss/components/button/vars' as *;
@use '@carbon/styles/scss/components/button/mixins' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

.#{$prefix}--btn--inverse {
    @include button-theme(
        $text-on-color,
        transparent,
        $primary__color,
        $button-secondary-hover,
        currentColor,
        $button-secondary-hover
    );

    &:hover {
        color: $text-on-color;
    }

    &:focus {
        color: $primary__color;
    }
}

.#{$prefix}--btn--sm {
    text-align: center;

    &.#{$prefix}--btn--icon-only .#{$prefix}--btn__icon {
        margin: 0 auto !important;
    }
}

.#{$prefix}--btn--lg {
    height: 40px;
    min-height: 40px;
}

.#{$prefix}--btn--w100 {
    width: 100%;
    max-width: 100%;
}

.#{$prefix}--btn--centered {
    display: inline-block;
    text-align: center;

    &:not(.#{$prefix}--btn--sm) {
        padding: calc(0.875rem - 3px) 12px;
    }

    &.#{$prefix}--btn--sm {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.#{$prefix}--btn--bold {
    font-weight: font-family.font-weight('semibold');
}

@include breakpoint-down(md) {
    .#{$prefix}--btn--mobile {
        width: 100%;
        max-width: 100%;
        display: inline-block;
        text-align: center;
        padding-right: 15px;
    }
}

.#{$prefix}--btn--flex-centered {
    padding: 0 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.#{$prefix}--btn--icon-only {
    min-width: 40px;
    justify-content: center;
}

.#{$prefix}--btn--text {
    @include button-theme(transparent, transparent, $text-primary, transparent, currentColor, $layer-active-01);

    padding: $button-padding-ghost;
    border: none;
    width: inherit !important;

    .#{$prefix}--btn__icon {
        position: static;
        margin-right: $spacing-03;

        path {
            fill: $button-primary;
        }
    }

    &:hover,
    &:active {
        color: $link-primary-hover;

        .#{$prefix}--btn__icon path {
            fill: $link-primary-hover;
        }
    }

    &:active {
        background-color: $background-active;
    }

    &:disabled,
    &:hover:disabled,
    &:focus:disabled,
    &.#{$prefix}--btn--disabled,
    &.#{$prefix}--btn--disabled:hover,
    &.#{$prefix}--btn--disabled:focus {
        color: $text-on-color-disabled;
        background: transparent;
        border-color: transparent;

        .#{$prefix}--btn__icon path {
            fill: $text-on-color-disabled;
        }
    }

    &.#{$prefix}--btn--sm {
        padding: $button-padding-ghost-sm;
    }

    &:focus {
        border-color: transparent;
        outline-color: transparent;
    }

    &:active {
        background-color: transparent;
    }
}

.#{$prefix}--btn--vertical-group {
    display: flex;
    flex-wrap: wrap;
    column-gap: $spacing-05;
    row-gap: $spacing-03;

    @include breakpoint-up(lg) {
        .#{$prefix}--btn:first-child {
            margin-left: auto;
        }
    }
    @include breakpoint-down(md) {
        .#{$prefix}--btn {
            width: 100%;
        }
    }
}

.#{$prefix}--btn--double-line {
    display: grid;
    grid-template-areas: 'light icon' 'bold icon';
    padding: $spacing-04 $spacing-05;
    column-gap: $spacing-05;
    max-width: inherit;

    .#{$prefix}--btn__icon {
        position: static;
        width: 1.5rem;
        height: 1.5rem;
    }
}

.#{$prefix}--btn__light,
.#{$prefix}--btn__bold {
    @include breakpoint-up(lg) {
        white-space: nowrap;
    }
}

.#{$prefix}--btn__light {
    grid-area: light;
}

.#{$prefix}--btn__bold {
    grid-area: bold;
    font-weight: font-family.font-weight('semibold');
}

.#{$prefix}--btn__icon {
    grid-area: icon;
}

.#{$prefix}--btn__text {
    grid-area: text;
}

.#{$prefix}--btn--low-profile {
    display: grid;
    border: 2px solid $border-subtle-01;
    grid-template-areas: 'icon text';
    padding: $spacing-05 $spacing-06;
    column-gap: $spacing-06;
    background: transparent;

    .#{$prefix}--btn__icon {
        position: static;
        fill: $button-primary;
    }

    &.#{$prefix}--btn--sm {
        column-gap: 0;
    }

    &:focus {
        border-color: $border-subtle-01;
    }

    &.#{$prefix}--btn--active {
        border-color: $button-primary;
    }

    &:hover {
        background-color: $layer-01;
    }
}
