// replace with: vendor/hrp/theme-frontend-hrp-base/node_modules/carbon-components/scss/components/ui-shell/_theme.scss

//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '@carbon/colors' as *;

//----------------------------------------------------------------------------
// Header tokens
//----------------------------------------------------------------------------
/// Header bar background
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-bg-01: $gray-100 !default;


/// Header menu trigger hover, Header nav link hover
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-bg-02: #2c2c2c !default;
 //TODO needs color var gray-100-hover

/// Header action active background
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-bg-03: $gray-80 !default;


/// Header submenu link hover
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-bg-04: #353535 !default;
 //TODO needs color var gray-90-hover

/// Header nav link active and focus background
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-bg-05: $gray-70 !default;


/// Header nav link submenu
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-bg-06: $gray-90 !default;


/// Header border bottom
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-border-01: $gray-80 !default;


/// Header focus
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-focus: $white-0 !default;


/// Primary text in header, Title text
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-text-01: $gray-10 !default;


/// Secondary text in header, Menu item nav text
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-text-02: $gray-30 !default;


/// Header menu trigger
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-icon-01: $gray-10 !default;


/// Header bar icons
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-icon-02: $white-0 !default;


/// Header bar icons
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-icon-03: $gray-30 !default;


/// Item link
/// @type Color
/// @access private
/// @group ui-shell
$shell-header-link: $blue-60 !default;


//----------------------------------------------------------------------------
// Header Panel tokens
//----------------------------------------------------------------------------

/// Header-panel background
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-bg-01: $gray-100 !default;


/// Panel item hover background
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-bg-02: #2c2c2c !default;
 //TODO needs color var gray-100-hover

/// Panel item focus and active background
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-bg-03: $gray-80 !default;


/// Panel item link selected background
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-bg-04: $gray-90 !default;


/// Panel border
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-border: $gray-80 !default;


/// Header panel text
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-text-01: $gray-30 !default;


/// Header panel secondary text
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-text-02: $gray-10 !default;


/// Header panel focus border
/// @type Color
/// @access private
/// @group ui-shell
$shell-panel-focus: $white-0 !default;


//----------------------------------------------------------------------------
// Side nav tokens
//----------------------------------------------------------------------------
/// Side-nav panel background
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-bg-01: $white-0 !default;


/// Selected category background
/// Select L2 flatted item background
/// Item active background
/// Footer-bar background
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-bg-02: $gray-80 !default;
 //TODO waiting for updated color

/// Selected L2 nested item
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-bg-03: $gray-70 !default;
 //TODO waiting for updated color

/// Side nav item hover background
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-bg-04: #e5e5e5 !default;
 //TODO needs color var gray-90-hover

/// Side nav menu item hover background
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-bg-05: #dcdcdc !default;
 //TODO needs color var gray-80-hover

/// Primary text in side-nav
/// L2 Flatten item text
/// L2 Nested item text
/// L1 title text
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-text-01: $gray-70 !default;
 //TODO waiting for updated color

/// Secondary text in side nav
/// L2 Category label
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-text-02: $gray-30 !default;


/// Side-nav icon color
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-icon-01: $gray-70 !default;


/// Item highlight bar
/// @type Color
/// @access private
/// @group ui-shell
$shell-side-nav-accent-01: $blue-60 !default;
