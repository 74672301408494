@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;

.hrp--status {
    display: flex;
    font-size: 12px;
    text-align: center;
    row-gap: $spacing-03;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint-up(md) {
        &:not(.hrp--status--inline) {
            column-gap: 0;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.hrp--status__icon {
    width: 30px;
    height: 30px;
}

.hrp--status__icon,
.hrp-status__text {
}

.hrp--status--success .hrp--status__icon {
    fill: #079b6c;
}

.hrp--status--warning .hrp--status__icon {
    fill: #fdd13a;
}
