@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;
@use '../../carbon/custom/variables' as *;

.#{$prefix}--catalog-list {
    &__sidebar {
        @include breakpoint-up(lg) {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
        }
        grid-area: sidebar;
    }

    &__sidebar-additional {
        @include breakpoint-up(lg) {
            -ms-grid-row: 2;
            -ms-grid-column: 1;
        }
        grid-area: additional;
        padding-bottom: 4rem;
    }

    &__products {
        @include breakpoint-up(lg) {
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            -ms-grid-column: 3;
            overflow-x: hidden;
        }
        grid-area: products;
    }

    &__discount-list {
        @include breakpoint-up(lg) {
            -ms-grid-row: 1;
            -ms-grid-row-span: 2;
            -ms-grid-column: 3;
        }

        grid-area: discount-list;
    }

    @include breakpoint-down(lg) {
        display: flex;
        flex-wrap: wrap;

        &__sidebar {
            flex: 0 0 100%;
            max-width: 100%;
            order: 1;
        }

        &__sidebar &__discount-list {
            display: none;
        }

        &__products {
            flex: 0 0 100%;
            max-width: 100%;
            order: 3;
        }

        &__sidebar-additional {
            display: none;
        }

        &__discount-list {
            order: 2;
        }
    }

    @include breakpoint-up(lg) {
        display: -ms-grid;
        display: grid;
        -ms-grid-rows: min-content 1fr;
        grid-template-rows: -webkit-min-content 1fr;
        grid-template-rows: min-content 1fr;
        -webkit-column-gap: $spacing-07;
        -moz-column-gap: $spacing-07;
        column-gap: $spacing-07;
        grid-template-areas: 'sidebar' 'additional' 'products';

        .toolbar {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        .page-layout-2columns-left & {
            -ms-grid-columns: $card-width 1rem 1fr;
            grid-template-columns: $card-width 1fr;
            grid-template-areas: 'sidebar products' 'additional products';
        }

        .page-layout-2columns-right & {
            -ms-grid-columns: 1fr 1rem $card-width;
            grid-template-columns: 1fr $card-width;
            grid-template-areas: 'products sidebar' 'products additional';
        }

        .page-layout-3columns & {
            -ms-grid-columns: $card-width 1rem 1fr 1rem $card-width;
            grid-template-columns: $card-width 1fr $card-width;
            grid-template-areas: 'sidebar products additional';
        }

        .page-layout-2columns-left &,
        .page-layout-2columns-right &,
        .page-layout-3columns & {
            .toolbar {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    // TODO: use modifier
    .dropdown-list__label {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .#{$prefix}--accordion__title {
        font-weight: font-family.font-weight('semibold');
    }

    .#{$prefix}--accordion__content {
        padding-right: 1rem;
        overflow: hidden;
    }

    .#{$prefix}--accordion__item:last-child {
        border-bottom: none;
    }

    .#{$prefix}--sidebar-block--highlighted {
        border-top: none;
    }
    // end of TODO: use modifier
}
