@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.search-form__input {
    border-color: $button-tertiary;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-radius: 0;
    padding-left: $spacing-05 !important;
    padding-right: 48px !important;
    background-color: $background;
    min-width: 100%;
    height: 3rem;

    @include breakpoint-up(lg) {
        &::-webkit-search-cancel-button {
            display: none;
        }
    }
    @include breakpoint-down(lg) {
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 48px;
            width: 35px;
            margin: 0;
            cursor: pointer;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyBmb2N1c2FibGU9ImZhbHNlIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBhcmlhLWhpZGRlbj0idHJ1ZSIgc3R5bGU9IndpbGwtY2hhbmdlOiB0cmFuc2Zvcm07Ij48cGF0aCBkPSJNMTIgNC43bC0uNy0uN0w4IDcuMyA0LjcgNGwtLjcuN0w3LjMgOCA0IDExLjNsLjcuN0w4IDguN2wzLjMgMy4zLjctLjdMOC43IDh6Ij48L3BhdGg+PC9zdmc+');
        }
    }
}

.search-form__input-wrapper,
.search-form__input,
.search-form {
    width: 100%;
    max-width: 100%;
}

.search-form__button {
    top: 0;
    width: 3rem;
    height: 3rem;
    outline: 2px solid transparent;
    outline-offset: -2px;
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none;
    z-index: 1;

    &:hover {
        background-color: $button-primary;
    }
}

.search-form__button:hover .search-form__button-icon * {
    fill: $text-on-color !important;
}

.search-form__button-icon * {
    fill: $button-primary !important;
}

.search-form__autocomplete-results {
    display: none;
    height: 100%;
    width: inherit !important;
    left: 0;
    right: 0;
    top: 0;
}

.smile-elasticsuite-autocomplete-result .product [role=option] {
    display: flex;
}
