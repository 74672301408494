@use '../../carbon/custom/config' as *;
@use '@carbon/layout/scss/spacing' as *;

.#{$prefix}--overflow-menu--simple {
    width: auto;

    &:hover {
        background: none;
    }
}

.#{$prefix}--overflow-menu-options--filter-column {
    top: 100%;
    width: 250px;
    padding: $spacing-05;
}

.#{$prefix}--overflow-menu-options.bx--overflow-menu-options--filter-column {
    right: 0;
    left: inherit;
}
