//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '@carbon/styles/scss/components/ui-shell/mixins' as *;
@use '@carbon/styles/scss/components/ui-shell/functions' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/styles/scss/motion' as *;
@use '@carbon/styles/scss/theme' as *;
@use '@carbon/styles/scss/type' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/styles/scss/utilities/button-reset';
@use '@carbon/styles/scss/utilities/convert' as *;
@use '@carbon/styles/scss/utilities/z-index' as *;

$prefix-brand: 'hrp';

/// UI shell header
/// @access private
/// @group ui-shell
@mixin header {
    .#{$prefix-brand}--header {
        position: fixed;
        z-index: z('header');
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        height: mini-units(6);
        align-items: center;
        border-bottom: 1px solid $border-subtle;
        background-color: $background;
    }

    .#{$prefix-brand}--header__action {
        @include button-reset.reset();

        display: inline-flex;
        width: mini-units(6);
        height: mini-units(6);
        border: convert.rem(1px) solid transparent;
        transition: background-color $duration-fast-02,
        border-color $duration-fast-02;
    }

    .#{$prefix-brand}--header__action
    > svg.#{$prefix-brand}--navigation-menu-panel-collapse-icon,
    .#{$prefix-brand}--header__action--active
    > svg.#{$prefix-brand}--navigation-menu-panel-expand-icon {
        display: none;
    }

    .#{$prefix-brand}--header__action--active
    > svg.#{$prefix-brand}--navigation-menu-panel-collapse-icon {
        display: inline;
    }

    .#{$prefix-brand}--header__action:hover {
        background-color: $layer-hover-01;
    }

    .#{$prefix-brand}--header__action--active {
        border-right: 1px solid $border-subtle;
        border-bottom: 1px solid transparent;
        border-left: 1px solid $border-subtle;
        background: $layer;
    }

    .#{$prefix-brand}--header__action--active > svg {
        fill: $icon-primary;
    }

    .#{$prefix-brand}--header__action:focus {
        border-color: $focus;
        outline: none;
    }

    .#{$prefix-brand}--header__action:active {
        background-color: $background-active;
    }

    .#{$prefix-brand}--header__action.#{$prefix-brand}--btn--icon-only {
        align-items: center;
        justify-content: center;
    }

    .#{$prefix-brand}--btn.#{$prefix-brand}--btn--icon-only.#{$prefix-brand}--header__action svg {
        fill: $icon-secondary;
    }

    .#{$prefix-brand}--btn.#{$prefix-brand}--btn--icon-only.#{$prefix-brand}--header__action:hover
    svg,
    .#{$prefix-brand}--btn.#{$prefix-brand}--btn--icon-only.#{$prefix-brand}--header__action:active
    svg,
    .#{$prefix-brand}--btn.#{$prefix-brand}--btn--icon-only.#{$prefix-brand}--header__action--active
    svg {
        fill: $icon-primary;
    }

    .#{$prefix-brand}--header__menu-trigger > svg {
        fill: $icon-primary;
    }

    .#{$prefix-brand}--header__menu-trigger:hover > svg {
        fill: $icon-primary;
        //TO-DO: ask design if this color changes on hover, missing spec for it
    }

    .#{$prefix-brand}--header__menu-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .#{$prefix-brand}--header__menu-toggle__hidden {
        @include breakpoint('lg') {
            display: none;
        }
    }

    //--------------------------------------------------------------------------
    // Header - Name
    //--------------------------------------------------------------------------
    a.#{$prefix-brand}--header__name {
        @include type-style('body-compact-01');

        display: flex;
        height: 100%;
        align-items: center;
        padding: 0 mini-units(4) 0 mini-units(2);
        border: convert.rem(2px) solid transparent;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 1.25rem;
        outline: none;
        text-decoration: none;
        transition: border-color $duration-fast-02;
        user-select: none;
    }

    a.#{$prefix-brand}--header__name:focus {
        border-color: $focus;
        //TO-DO: follow up with design to see if this is correct, missing focus spec for it
    }

    .#{$prefix-brand}--header__name--prefix {
        font-weight: 400;
    }

    a.#{$prefix-brand}--header__name,
    a.#{$prefix-brand}--header__name:hover {
        color: $text-primary;
    }

    .#{$prefix-brand}--header__menu-toggle:not(.#{$prefix-brand}--header__menu-toggle__hidden)
    ~ .#{$prefix-brand}--header__name {
        padding-left: convert.rem(8px);
    }

    //--------------------------------------------------------------------------
    // Header - Navigation
    //--------------------------------------------------------------------------
    .#{$prefix-brand}--header__nav {
        position: relative;
        display: none;
        height: 100%;
        padding-left: mini-units(2);

        @include breakpoint('lg') {
            display: block;
        }

        // header nav divider
        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: convert.rem(1px);
            height: convert.rem(24px);
            background-color: $border-subtle;
            content: '';
            transform: translateY(-50%);
        }
    }

    .#{$prefix-brand}--header__menu-bar {
        display: flex;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    a.#{$prefix-brand}--header__menu-item {
        position: relative;
        display: flex;
        // Used for links that are directly in the menubar to span the full height
        height: 100%;
        align-items: center;
        padding: 0 mini-units(2);
        // Used for focus styles
        border: 2px solid transparent;
        background-color: $background;
        color: $text-secondary;
        // Text styles
        font-size: convert.rem(14px);
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.125rem;
        // Reset link styles and make sure the text isn't selectable
        text-decoration: none;
        transition: background-color $duration-fast-02,
        border-color $duration-fast-02, color $duration-fast-02;
        user-select: none;
    }

    a.#{$prefix-brand}--header__menu-item:hover {
        background-color: $layer-hover-01;
        color: $text-primary;
    }

    .#{$prefix-brand}--header__action:active,
    a.#{$prefix-brand}--header__menu-item:active {
        background-color: $background-active;
        color: $text-primary;
    }

    a.#{$prefix-brand}--header__menu-item:focus {
        border-color: $focus;
        outline: none;
    }

    a.#{$prefix-brand}--header__menu-item:hover > svg,
    a.#{$prefix-brand}--header__menu-item:active > svg {
        fill: $icon-primary;
    }

    // Styles for selected state

    a.#{$prefix-brand}--header__menu-item[aria-current='page'],
    .#{$prefix-brand}--header__menu-item--current {
        color: $text-primary;
    }

    a.#{$prefix-brand}--header__menu-item[aria-current='page']::after,
    .#{$prefix-brand}--header__menu-item--current::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: -2px;
        left: 0;
        width: 100%;
        border-bottom: 3px solid $border-interactive;
        content: '';
    }

    a.#{$prefix-brand}--header__menu-item[aria-current='page']:focus::after,
    .#{$prefix-brand}--header__menu-item--current:focus::after {
        border: 0;
    }

    a.#{$prefix-brand}--header__menu-item[aria-current='page']:focus,
    a.#{$prefix-brand}--header__menu-item.#{$prefix-brand}--header__menu-item--current:focus {
        border: 2px solid $focus;
    }

    .#{$prefix-brand}--header__submenu {
        position: relative;
    }

    .#{$prefix-brand}--header__submenu--current::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 3px solid $border-interactive;
        content: '';
    }

    .#{$prefix-brand}--header__submenu--current:focus {
        border: 2px solid $focus;
    }

    .#{$prefix-brand}--header__submenu--current:focus::after {
        border: 0;
    }

    .#{$prefix-brand}--header__menu-title[aria-haspopup='true'] {
        position: relative;
    }

    .#{$prefix-brand}--header__menu-title[aria-expanded='true'] {
        // Note: needs to be higher than menu. Adding 1 here instead of moving to
        // the next level.
        z-index: 100;
        background-color: $layer;
        color: $text-secondary;
    }

    .#{$prefix-brand}--header__menu-title[aria-expanded='true']
    > .#{$prefix-brand}--header__menu-arrow {
        transform: rotate(180deg);
    }

    .#{$prefix-brand}--header__menu {
        display: none;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .#{$prefix-brand}--header__menu-title[aria-expanded='true']
    + .#{$prefix-brand}--header__menu {
        position: absolute;
        z-index: 101;
        bottom: 0;
        left: 0;
        display: flex;
        width: mini-units(25);
        flex-direction: column;
        background-color: $layer;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 50%);
        transform: translateY(100%);
    }

    .#{$prefix-brand}--header__menu-title[aria-expanded='true']
    + .#{$prefix-brand}--header__menu
    .#{$prefix-brand}--header__menu-item {
        background-color: $layer;
    }

    .#{$prefix-brand}--header__menu-title[aria-expanded='true']
    + .#{$prefix-brand}--header__menu
    .#{$prefix-brand}--header__menu-item:hover {
        background-color: $layer-hover;
        color: $text-primary;
    }

    .#{$prefix-brand}--header__menu-title[aria-expanded='true']
    + .#{$prefix-brand}--header__menu
    .#{$prefix-brand}--header__menu-item:active {
        background-color: $layer-active;
        color: $text-primary;
    }

    .#{$prefix-brand}--header__menu .#{$prefix-brand}--header__menu-item {
        height: mini-units(6);
    }

    .#{$prefix-brand}--header__menu-arrow {
        margin-left: mini-units(1);
        fill: $icon-secondary;
        transition: transform $duration-fast-02, fill $duration-fast-02;
    }

    //--------------------------------------------------------------------------
    // Header - Global
    //--------------------------------------------------------------------------
    .#{$prefix-brand}--header__global {
        display: flex;
        height: 100%;
        flex: 1 1 0%;
        justify-content: flex-end;
    }

    //--------------------------------------------------------------------------
    // Header - Skip to content
    //--------------------------------------------------------------------------
    .#{$prefix-brand}--skip-to-content {
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;
        padding: 0;
        border: 0;
        margin: -1px;
        clip: rect(0, 0, 0, 0);
        visibility: inherit;
        white-space: nowrap;
    }

    .#{$prefix-brand}--skip-to-content:focus {
        z-index: 9999;
        top: 0;
        left: 0;
        display: flex;
        width: auto;
        height: 3rem;
        align-items: center;
        padding: 0 1rem;
        border: 4px solid $focus;
        background-color: $background;
        clip: auto;
        color: $text-secondary;
        //TO-DO: double check with design that these tokens are correct, missing spec for it
        outline: none;
    }
}
