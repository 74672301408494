@use 'sass:math';
@use '../variables.overrides' as *;
@use '../custom/components/header/variables.shell-header' as *;
@use '@carbon/motion' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../custom/config' as *;
@use '../custom/tokens' as *;

.hrp--header {
    position: relative;

    &__main {
        padding: $spacing-05 0;
        box-sizing: content-box;
        z-index: 898 !important;
    }

    &__logo {
        width: $header-logo-width--sm;
        height: $header-logo-height--sm;
    }

    &__catalog {
        display: flex;
        flex-grow: 2;

        @include breakpoint-up(lg) {
            position: relative;
        }
    }

    &__catalog-toggle {
        height: convert.rem(48px);
    }

    &__global {
        flex: inherit;
        align-items: center;
    }

    &__name {
        padding: 0 !important;
    }

    &__name:focus,
    &__action:focus,
    &__labelled-action:focus {
        &:not(.focus-visible) {
            border-color: transparent !important;
        }
    }

    &__menu-trigger {
        margin-right: 0;

        &--active {
            position: relative;
        }

        &--active:before {
            position: absolute;
            z-index: 1;
            content: "";
            top: convert.rem(24px);
            left: convert.rem(29px);
            width: convert.rem(5px);
            height: convert.rem(5px);
            border-radius: 50%;
            background-color: $support-error;
            box-shadow: 0px 0px 0px 2px $focus-inset;
            transition: box-shadow $duration-fast-02;
        }

        &--active:hover:before {
            box-shadow: 0px 0px 0px 2px $shell-header-bg-04;
        }
    }

    &__compare-wrapper,
    &__notification-wrapper {
        display: flex;
        align-items: center;
    }

    &__nav {
        padding-left: 0;

        &:before {
            display: none;
        }
    }

    &__language, &__currency {
        background-color: transparent;
        height: auto;
    }

    &__currency {
        margin-right: $spacing-03;
    }

    &__wrapper {
        position: relative;

        .ui-dialog {
            outline: none;
            box-shadow: none;
        }
    }

    &__language-divider {
        display: none;
    }

    &__compare {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__main--active:after {
        top: var(--element-height);
        bottom: calc(-1 * var(--document-height) + var(--offset-top));
    }

    @include breakpoint-between(sm, lg) {
        &__nav {
            display: block;
        }
    }

    @include breakpoint-down(md) {
        &__catalog .#{$prefix}--btn__collapsible {
            padding-left: 0;
            padding-right: convert.rem(48px - 6px);

            &-text {
                display: none;
            }

            .#{$prefix}--btn__icon {
                right: convert.rem( math.div(48px, 2)  - math.div(16px, 2) - 3px );
            }
        }

        &__cart-button:hover {
            background-color: $layer-hover-01;
        }

        &-wrapper {
            position: static;
        }
    }

    @include breakpoint(lg) {
        &__main--active:after {
            position: absolute;
            left: -1 * $spacing-05;
            right: -1 * $spacing-05;
        }
    }

    @include breakpoint-up(md) {
        &__logo {
            width: $header-logo-width--md;
            height: $header-logo-height--md;
        }

        &__name {
            margin-right: $spacing-07;
        }
    }

    @include breakpoint-up(xlg) {
        &__main--active:after {
            position: absolute;
            left: calc((-100vw + 1248px) / 2);
            right: calc((-100vw + 1248px) / 2);
        }
    }

    @include breakpoint-down($mobile-menu-breakpoint) {
        flex-wrap: wrap;
        height: inherit;
        background-color: $background;

        a.hrp--header__menu-item {
            height: 35px;
        }

        .hrp--header__sub-mobile[data-role="bookmark-list"] > a {
            pointer-events: none;

            svg {
                margin-right: $spacing-03;
            }
        }

        &__sub {
            z-index: 901 !important;
        }

        &__container {
            padding-left: 0;
            padding-right: 0;
        }

        &__main {
            padding: $spacing-04;
        }

        &__catalog {
            flex: 0 0 100%;
            order: 3;
            margin-top: $spacing-03;
        }

        &__global {
            margin: -#{$spacing-05} 0 -#{$spacing-05} auto;
        }

        &__language-divider {
            display: none;
        }

        &__cart-button-icon {
            padding-right: 0;
        }

        &__cart-button-label {
            display: none;
        }

        &__bookmarks-trigger,
        &__bookmarks-trigger-divider {
            display: none;
        }

        &__menu-bar {
            width: 100%;
            flex-wrap: wrap;
        }

        &__submenu {
            display: block;
            max-width: 100%;
            flex: 0 0 100%;
        }

        &--mobile-dropdown-opened #maincontent {
            position: relative;
        }

        &--mobile-dropdown-opened #maincontent:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.75);
        }
    }

    @include breakpoint-up($mobile-menu-breakpoint) {
        border: none;
        background-color: transparent;

        &__sub {
            z-index: 897 !important;
        }

        &__nav {
            margin-left: -#{$spacing-04};
        }

        &__sub-footer {
            margin-left: auto;
        }

        &__main--active:after,
        &__sub--active:after {
            content: '';
            z-index: -1;
            background-color: rgba(0, 0, 0, 0.75);
        }

        &__sub--active:after {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__sub--active:after {
            top: 81px;
        }

        &__decorate-wrapper {
            border-top: 1px solid $border-subtle-01;
            border-bottom: 1px solid $border-subtle-01;
        }

        &__submenu-trigger, &__submenu-trigger-divider {
            display: none;
        }
    }
}

@include breakpoint-up($mobile-menu-breakpoint) {
    body.fixdirect {
        .hrp--header__nav {
            margin-left: -#{$spacing-04};
        }

        .hrp--header__global {
            margin-left: auto;
        }
    }
}

// Override carbon defaults
@media all and (max-width: 725px) {
    .hrp--header {
        .hrp--search,
        .hrp--header__action--search {
            display: inline-block;
        }
    }
}
