@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

.catalog-product-view {
    .page-top-wrapper {
        margin-top: $spacing-08;
        margin-bottom: $spacing-09;
        padding-right: var(--pv-gutter);
        padding-left: var(--pv-gutter);
        display: flex;

        .breadcrumbs {
            flex-grow: 1;
            padding: 0;
        }

        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
}
