@use '@carbon/grid/scss/breakpoint' as *;

:root {
    --card-width: 304px;

    @include breakpoint-up(lg) {
        --card-width: 288px;
    }
}

$card-width: var(--card-width);
