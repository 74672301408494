@use '@carbon/styles/scss/type' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;

@use '../carbon/custom/config' as *;

.page-header {
    color: $text-primary;

    .breadcrumbs {
        margin: $spacing-05 0;
    }

    .heading--page {
        margin-top: $spacing-06;
        margin-bottom: $spacing-05;
        @include type-style('fluid-heading--main', true);
    }
}

.#{$prefix}--grid.page-header__label-wrapper {
    padding-top: $spacing-06;
    margin-bottom: auto;
}

.page-header__label {
    @include type-style('expressive-heading-03', true);
}

.page-header__title {
    margin-bottom: $spacing-06;
    margin-top: $spacing-04;
}
