@use '@carbon/styles/scss/utilities/box-shadow' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';

.hrp--floated {
    &__wrapper {
        @include box-shadow;
        padding: convert.rem(20px);
        background-color: $background;
        border-radius: 72px;

        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 3;

        @media (max-width: 320px) and (max-height: 568px) {
            position: static;
            width: 100%;
        }

        @media (max-width: 600px) {
            // TODO review mobile positioning
            bottom: 5px !important;
            right: 5px !important;
        }
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__compare-wrapper {
        display: flex;
        align-items: center;
    }

    &__divider {
        margin: 0 $spacing-03;
    }

    &__label {
        display: none;
    }

    &__icon {
        fill: $text-primary;
        width: 32px;
        height: 32px;
    }
}
