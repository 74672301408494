@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../../../carbon/custom/config' as *;

$dashboard-table-margin: 16px !default;

.dashboard-table {
    overflow: hidden;

    &--tab {
        padding: 10px;
        margin-bottom: $dashboard-table-margin;
        border: 1px solid $layer-01;

        .dashboard-table__content {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        margin-bottom: $dashboard-table-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $layer-01;
    }

    &__content {
        margin-bottom: $dashboard-table-margin;
        overflow-x: auto;
    }

    &__table {
        margin: 0;
    }

    &__link {
        display: inline-flex;
        &:focus {
            @include focus-inline();
        }
    }

    &__spacer {
        margin-right: 8px;
    }

    &__title {
        margin-bottom: 0;
        margin-right: $dashboard-table-margin;
    }
}
