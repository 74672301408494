@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;
@use '../carbon/custom/variables' as *;

.smile-elasticsuite-autocomplete-result {
    display: grid;
    color: $text-primary;

    &:empty {
        display: none;
    }

    .term {
        @include breakpoint-up(xlg) {
            -ms-grid-row: 3;
            -ms-grid-column: 1;
        }
        grid-area: term;
    }

    .product {
        @include breakpoint-up(xlg) {
            -ms-grid-row: 1;
            -ms-grid-row-span: 3;
            -ms-grid-column: 3;
        }
        grid-area: product;
    }

    .category {
        @include breakpoint-up(xlg) {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
        }
        grid-area: category;
    }

    .autocomplete-list-title {
        @include type-style('heading-02');
        margin-bottom: $spacing-03;
    }

    .title-product {
        display: none;
    }

    .category-mini-crumb,
    .qs-option-name {
        margin-right: $spacing-02;
    }

    [role="option"] {
        margin-bottom: $spacing-03;
        cursor: pointer;
        line-height: 1.3;

        .amount:before {
            content: '(';
        }

        .amount:after {
            content: ')';
        }
    }

    .product [role="option"]:not(:last-child) {
        padding-bottom: $spacing-03;
        border-bottom: 1px solid $border-subtle-01;
    }

    .product-image-box {
        margin-right: $spacing-03;

        img {
            width: 90px;
            height: auto;
        }
    }

    .product-primary {
        line-height: 1.3;
        margin-bottom: $spacing-03;
    }

    .price-box {
        font-weight: font-family.font-weight('semibold');
    }

    .product-name {
        word-break: break-all;
    }

    @include breakpoint-up(xlg) {
        grid-template-areas: 'category product' 'term product';
        grid-template-columns: calc(190px - $spacing-06) 1fr;
        grid-template-rows: min-content 1fr;
        gap: $spacing-06;
    }

    @include breakpoint-down(xlg) {
        grid-template-areas: 'category' 'product' 'term';

        .autocomplete-list {
            border-bottom: 1px solid $border-subtle-01;
            padding: $spacing-05;
        }
    }
}
