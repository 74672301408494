@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../../../styles/carbon/custom/variables' as *;

.blog-post {
    border: 1px solid $border-subtle-01;
    padding: $spacing-05;
    display: flex;
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
    flex-wrap: wrap;
    flex: 0 0 $card-width;
    max-width: $card-width;

    &__image {
        width: 254px;
        height: 160px;
        object-fit: contain;
    }

    &__title {
        @include reset;
        @include type-style('body-short-02');

        color: $text-primary;
        margin-top: $spacing-05;
        margin-bottom: $spacing-05;
        text-decoration: none;
        display: block;

        * {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &__container {
        display: grid;
        grid-template-areas: "header" "content" "actions";
        grid-template-rows: min-content auto min-content;
    }

    &__header {
        grid-area: header;
    }

    &__content {
        grid-area: content;
        flex: 0 0 100%;
        max-width: 100%;
    }

    &__actions {
        grid-area: actions;
        margin-top: $spacing-04;
        text-align: right;
    }

    &__excerpt,
    &__excerpt * {
        font-size: 0.9rem;
        line-height: 1.3rem;
    }

    &__excerpt img {
        max-width: 254px;
        height: auto;
    }

    &__description {
        @include type-style('body-short-01');

        a {
            color: $link-primary;
            text-decoration: none;
        }
    }

    &__meta {
        color: $text-secondary;
        margin: $spacing-04 0;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list--sidebar & {
        margin-left: 0;
        margin-right: 0;
    }

    &__list--centered {
        justify-content: center;
    }

    @include breakpoint-down(xlg) {
        flex-direction: row;
        position: relative;

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    @include breakpoint-down(md) {
        width: 100%;
        max-width: $card-width;
        flex: none !important;
    }

    @include breakpoint-up(md) {
        &__list:not(&__list--sidebar) {
            margin-top: -#{$spacing-05};
            margin-left: -#{$spacing-05};
            margin-right: -#{$spacing-05};
        }
    }

    @media (min-width: convert.rem(450px)) {
        margin-left: $spacing-05;
        margin-right: $spacing-05;
    }
}
