@use '@carbon/themes/scss/tokens';
@forward '@carbon/themes/scss/tokens';
@use 'config';

// copied from carbon
@function _get($token) {
    @return var(--#{config.$prefix}-#{$token});
}

$text-tertiary: _get('text-tertiary') !default;
$header-logo-width--sm: _get('header-logo-width--sm') !default;
$header-logo-height--sm: _get('header-logo-height--sm') !default;
$header-logo-width--md: _get('header-logo-width--md') !default;
$header-logo-height--md: _get('header-logo-height--md') !default;
$footer-logo-width--sm: _get('header-logo-width--sm') !default;
$footer-logo-height--sm: _get('header-logo-height--sm') !default;
$footer-logo-width--md: _get('header-logo-width--md') !default;
$footer-logo-height--md: _get('header-logo-height--md') !default;
