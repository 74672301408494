@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../../carbon/custom/tokens' as *;
@use '@carbon/layout/scss/convert';
// @use '@carbon/themes/scss/tokens' as *;

.hrp-ag--filters-form {
    display: none;

    @include breakpoint-down(lg) {
        margin-bottom: $spacing-05;
    }

    border: convert.rem(1.5px) solid $border-subtle-01;
    padding: $spacing-05;
}

.hrp-ag--filters-form--visible {
    margin-top: $spacing-05;
    display: block;

    @include breakpoint-up(lg) {
        margin-bottom: $spacing-05;
    }
}



// TODO Aron review


.admin__control-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.action-menu-item {
    position: relative;
}

.admin__action-multiselect-label:before {
    border-radius: 1px;
    content: '';
    font-size: 0;
    transition: font-size 0.1s ease-out, color 0.1s ease-out, border-color 0.1s linear;
    background-color: #ffffff;
    border: 1px solid #adadad;
    color: transparent;
    float: left;
    height: 1rem;
    text-align: center;
    vertical-align: top;
    width: 1rem;
    margin-right: 7px;
    margin-top: 1px;
}

.admin__action-multiselect-label:after {
    content: "";
    position: absolute;
    display: none;
}

.admin__control-checkbox:checked ~ .admin__action-multiselect-label:after {
    display: block;
}

.admin__action-multiselect-label:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 9px;
    border: solid #161616;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
