@use '@carbon/grid/scss/breakpoint' as *;

$dashboard-actions-toolbar__margin             : 0 !default;
$dashboard-actions-toolbar__item-margin        : 8px 0 0 0 !default;
$dashboard-actions-toolbar__item-margin\@medium: 0 8px 0 0 !default;

.dashboard-actions-toolbar {
    display: flex;
    flex-direction: column;
    margin: $dashboard-actions-toolbar__margin;

    @include breakpoint-down('md') {
        flex-direction: row;
    }

    &__item {
        margin: $dashboard-actions-toolbar__item-margin;

        @include breakpoint-down('md') {
            margin: $dashboard-actions-toolbar__item-margin\@medium;
        }
    }
}
