@use '@carbon/layout/scss/spacing' as *;

.hrp-separator {
    position: relative;
    margin-right: $spacing-06;
}

.hrp-separator:after {
    content: '';
    position: absolute;
}

.hrp-separator--point:after {
    background-color: #565656;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    top: 50%;
    right: calc(($spacing-04 * -1) - 2px);
}
