@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '../../carbon/custom/tokens' as *;
@use '../../carbon/custom/config' as *;

.hrp-ag--overflow-menu {
    height: 40px;
    min-height: 40px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &.#{$prefix}--btn--low-profile {
        border: convert.rem(1.5px) solid $border-subtle-01;
    }
}

.hrp-ag--overflow-menu:hover {
    background: transparent !important;
}

.hrp-ag--overflow-menu .#{$prefix}--btn__icon {
    color: $button-primary;
}

@include breakpoint-up(lg) {
    .hrp-ag--overflow-menu--mr-0 {
        margin-left: -$spacing-04 !important;
    }
}
