@use '../custom/config' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;

.#{$prefix}--tile--h100 {
    height: 100%;
}

.#{$prefix}--tile--highlighted {
    border-left: 3px solid $button-primary;
    padding: $spacing-05 $spacing-05 $spacing-03 $spacing-05;

    @include breakpoint-down(md) {
        padding: $spacing-05 $spacing-05 $spacing-01 $spacing-05;
    }
}
