@use '@carbon/styles/scss/type' as *;

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    @include type-style('body-short-01');
}
