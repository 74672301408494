@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/styles/scss/components/form/form' as *;

@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;
@use '@carbon/themes/scss/tokens' as *;

// From Alpaca

// TODO: move visually-hidden
@mixin visually-hidden($usePseudo: '') {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    @if $usePseudo != '' {
        &:focus,
        &:active {
            clip:     auto;
            height:   auto;
            margin:   0;
            overflow: visible;
            position: static;
            width:    auto;
        }
    }
}

$swatch__transition                  : all 0.3s ease-in-out, outline-offset 1ms !default;

$swatch__title-margin-bottom         : 1rem !default;
$swatch__title-font-size             : 14px !default;

$swatch__option-size                 : 40px !default;
$swatch__option-size--image          : 48px !default;
$swatch__option-margin               : 1rem !default;
$swatch__option-background           : $background !default;
$swatch__option-color                : $text-primary !default;
$swatch__option-border               : 2px solid $border-subtle-01 !default;
$swatch__option-border--white        : 1px solid $layer-02 !default;
$swatch__option-border-color--active : $button-primary !default;

.swatch {
    display: flex;
    flex-wrap: wrap;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: auto;
    }

    &__option-container {
        box-sizing: border-box;
        border: $swatch__option-border;
        margin-right: $swatch__option-margin;
        margin-bottom: $swatch__option-margin;
        transition: $swatch__transition;
        cursor: pointer;

        &:hover,
        &:focus,
        &.selected {
            outline: none;
            border-color: $swatch__option-border-color--active;
            .swatch__option--white {
                border: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__selected-option {
        display: none;
    }

    &__title {
        flex: 0 0 100%;
        margin-bottom: $swatch__title-margin-bottom;
        font-size: $swatch__title-font-size;
    }

    &__option {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: $swatch__option-size;
        min-height: $swatch__option-size;
        background-color: $swatch__option-background;
        color: $swatch__option-color;

        &--image {
            min-height: $swatch__option-size--image;
            background-size: cover;
            background-position: top center;
        }

        &--white {
            border: $swatch__option-border--white;
        }
    }

    &__input {
        @include visually-hidden;
    }
}

// extend

.swatch__title {
    @include reset;
    @include type-style('label-01');

    color: $text-secondary;
    font-weight: $input-label-weight;
    display: inline-block;
    vertical-align: baseline;
    line-height: convert.rem(16px);
    pointer-events: none;
}
