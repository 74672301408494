@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

.product-view__head {
    .breadcrumbs {
        grid-area: breadcrumb;
    }

    display: grid;

    @include breakpoint-down(md) {
        grid-template-areas: 'breadcrumb' 'actions';
    }

    @include breakpoint-between(md, lg) {
        grid-template-columns: 2fr 1fr;
        grid-template-areas: 'breadcrumb actions';
    }

    @include breakpoint-down(lg) {
        margin-top: $spacing-04 !important;
        margin-bottom: 0 !important;
    }

    @include breakpoint-up(lg) {
        grid-template-areas: 'breadcrumb actions';
        grid-template-columns: 1fr 1fr;
        grid-column-gap: $spacing-07;
    }
}

// elements of product main
.product-view__wrapper {
    display: grid;
    grid-column-gap: $spacing-07;

    @include breakpoint-down(md) {
        grid-template-areas: 'title' 'sku-wrapper' 'gallery' 'main-details' 'secondary-details';
    }

    @include breakpoint(md) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'gallery title'
            'gallery sku-wrapper'
            'gallery main-details'
            'gallery secondary-details';
    }
}

.product-view__gallery {
    grid-area: gallery;
    position: relative;
    overflow: hidden;
}

.product-view__main-details {
    grid-area: main-details;
    display: grid;
    grid-column-gap: $spacing-07;


    @include breakpoint-down(md) {
        grid-template-areas:
            'main-details-labels'
            'main-details-price'
            'main-details-sidebar'
            'main-details-stock-status'
            'main-details-rating'
            'main-details-form'
            'main-details-bundle-products'
            'main-details-related--crossell'
            'main-details-related--used'
            'main-details-related--bundle'
            'main-details-related--bundle-parent'
            'main-details-promotions'
            'main-details-short-description';
    }

    @include breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
        align-items: end;
    }

    @include breakpoint-between(md, lg) {
        grid-template-areas:
            'main-details-labels                    main-details-labels'
            'main-details-sidebar                   main-details-sidebar'
            'main-details-price                     main-details-price'
            'main-details-form                      main-details-form'
            'main-details-bundle-products           main-details-bundle-products'
            'main-details-related--crossell         main-details-related--crossell'
            'main-details-related--used             main-details-related--used'
            'main-details-related--bundle           main-details-related--bundle'
            'main-details-related--bundle-parent    main-details-related--bundle-parent'
            'main-details-promotions                main-details-promotions'
            'main-details-short-description         main-details-short-description';
    }

    @include breakpoint-up(lg) {
        grid-template-areas:
            'main-details-labels                    main-details-labels'
            'main-details-price                     main-details-sidebar'
            'main-details-form                      main-details-sidebar'
            'main-details-bundle-products           main-details-bundle-products'
            'main-details-related--crossell         main-details-related--crossell'
            'main-details-related--used             main-details-related--used'
            'main-details-related--bundle           main-details-related--bundle'
            'main-details-related--bundle-parent    main-details-related--bundle-parent'
            'main-details-promotions                main-details-promotions'
            'main-details-short-description         main-details-short-description';
    }
}

.product-view__extra-actions {
    grid-area: actions;
    display: flex;
    @include breakpoint-down(md) {
        justify-content: flex-start;
    }

    @include breakpoint-up(md) {
        justify-content: flex-end;
    }
}

.product-view__action {
    white-space: nowrap;
    margin: 0 $spacing-03;
    color: $text-primary;
    height: 18px;
}

.product-view__action:not(.product-view__action--active) {
    .product-view__action-icon--active {
        display: none;
    }
}

.product-view__action--active {
    .product-view__action-icon--default {
        display: none;
    }

    .product-view__action-icon--active {
        display: block;
    }
}

.product-view__action-label {
    font-size: 0.875rem;
}

.product-view__labels {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}

.product-view__description {
    margin-top: $spacing-04;
    @include type-style('body-short-01');

    p {
        margin-bottom: 1rem;
    }
}

.product-view__secondary-details {
    grid-area: secondary-details;
}

.product-view__title {
    grid-area: title;
    @include type-style('fluid-heading--main', true);
    font-weight: font-family.font-weight('semibold');
}

.product-view__sku-wrapper {
    grid-area: sku-wrapper;
}

// elements of right main
.product-view__main-details-sidebar {
    grid-area: main-details-sidebar;

    @include breakpoint-up(md) {
        margin-bottom: 0 !important;

        .#{$prefix}--btn {
            @include btn--flat;
        }
    }
}

.product-view__right-labels {
    grid-area: main-details-labels;
}

.product-view__price {
    grid-area: main-details-price;
    display: flex;
    align-items: flex-end;

    @include breakpoint-up(md) {
        height: 100%;
        margin-bottom: 0 !important;
    }

    @include breakpoint-between(md, lg) {
        padding-top: $spacing-05;
        padding-bottom: $spacing-05;
    }
}

.product-view__quantity-unit {
    margin-left: $spacing-02;
    margin-bottom: $spacing-02;
    white-space: nowrap;

    &:before {
        content: '/';
        margin: 0 $spacing-02;
    }

    font-size: 16px;
    @include font-family.font-weight('semibold');
}

.product-view__stock-status {
    grid-area: main-details-stock-status;
    color: $support-success;
}

.product-view__rating {
    grid-area: main-details-rating;
    margin-bottom: $spacing-05;

    .rating {
        margin-right: $spacing-06;
        margin-top: $spacing-04;
        margin-bottom: $spacing-04;
    }

    .review__amount, .review__amount-text, .review__summary-add {
        margin-right: $spacing-03;
        margin-top: $spacing-03;
        margin-bottom: $spacing-03;
    }

    .review__summary-handle {
        white-space: nowrap;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.product-view__form {
    grid-area: main-details-form;
}

.product-view__related--used {
    grid-area: main-details-related--used;
}

.product-view__promotions {
    grid-area: main-details-promotions;
}

.product-view__related--bundle {
    grid-area: main-details-related--bundle;
}

.product-view__related--bundle-parent {
    grid-area: main-details-related--bundle-parent;
}

.product-view__related--crossell {
    grid-area: main-details-related--crossell;
}

.product-view__description {
    grid-area: main-details-short-description;
}

.hrp--bundle-products {
    grid-area: main-details-bundle-products;
}

// actions
.product-view__main-actions {
    flex-wrap: wrap;
}

// social/print
.product-view__action-elements {
    float: right;
    margin-top: -1.1rem;
}

.product-view__action-button,
.product-view__action--a {
    padding: $spacing-02;
}

.product-view__price {
    .price__value {
        display: block;
    }

    .price__value--crossed-price {
        font-size: 1rem;
        line-height: 1;
        color: $text-placeholder;
        text-decoration: line-through;
        font-weight: font-family.font-weight('regular');
    }

    .price > .price__value--old {
        @include type-style('body-short-01');
    }

    .price > .price__value:not(.price__value--old):not(.price__value--crossed-price),
    .normal-price {
        @include type-style('productive-heading-05');
        font-weight: font-family.font-weight('semibold');
    }
}

.product-view__sku-wrapper {
    line-height: 1.2;
}

.product-view .swatch-opt .swatch {
    margin-bottom: $spacing-05;
}

// Tabs
.product-view__tabs {
    margin-top: $spacing-08;
    margin-bottom: $spacing-08;

    .tab__content {
        background-color: $background;
        padding: $spacing-07 0;

        .product.description .value {
            @include type-style('body-long-02');

            p {
                margin: 0 0 $spacing-06;

                &:empty {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

.product-view__catalog-grid .review__summary-handle {
    .review__amount, .review__amount-text, .review__summary-add {
        display: none;
    }
}

.product-view__title,
.product-view__sku-wrapper,
.product-view__price,
.product-view__main-details-sidebar {
    margin-bottom: $spacing-05;
}

@include breakpoint-up(lg) {
    .product-view__sku-wrapper {
        margin-bottom: $spacing-09;
    }

    .product-view__right-labels {
        margin-top: -35px;
        padding-bottom: $spacing-04;
    }

    .product-view__price .product-view__right-labels {
        padding-bottom: 0;
    }
}

@include breakpoint-down(lg) {
    .product-view__action-label {
        display: none !important;
    }
}
