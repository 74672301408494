@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;

.hrp--tile {
    padding: $spacing-07;
    border: 1px solid #dcdcdc;
}

@include breakpoint-down(md) {
    .hrp--tile--sm-no-border {
        padding-left: 0;
        padding-right: 0;
        border: none;
    }
}
