@use '../../styles/carbon/custom/config' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.#{$prefix}--accordion__item {
    padding-left: 0 !important;
}

[data-content-type="accordion"] {
    .#{$prefix}--accordion__item .#{$prefix}--accordion {
        background-color: $border-strong-01;
    }

    .#{$prefix}--accordion__item:not(.#{$prefix}--accordion__item--active) > .#{$prefix}--accordion__content {
        display: none;
    }

    .#{$prefix}--accordion__item--active > .#{$prefix}--accordion__content {
        display: block;
    }

    .#{$prefix}--accordion__content {
        padding-right: $spacing-05;

        > .cms-content {
            //background: $background;
            // padding: $spacing-05;
            margin-bottom: $spacing-05;

            &:empty {
                display: none;
            }
        }
    }
}
