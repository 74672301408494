@use '@carbon/themes/scss/tokens' as *;
@use '../../carbon/custom/config' as *;

.#{$prefix}--pagination {
    border-bottom: 1px solid $border-subtle-01;
    height: auto;
    flex-wrap: wrap;

    &__left, &__right {
        height: 3rem;
    }
}
