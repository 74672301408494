@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

$minicart-product__attributes-list-padding        : 0.06rem 0 0 0 !default;
$minicart-product__attributes-list-color          : $text-primary !default;

.header__minicart-content {
    z-index: 2;
    padding: $spacing-05;

    .popup__content {
        outline: none !important;
    }
}

.minicart-content {
    height: inherit !important;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__edit {
        margin-right: auto;
    }

    &__total {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 200px;
    }

    &__summary {
        text-align: right;
    }

    &__products-list {
        margin-bottom: 2rem;
    }

    &__actions {
        display: flex;
    }

    &__edit {
        flex-grow: 1;
    }

    @include breakpoint-down(md) {
        &__total, &__actions {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &__actions {
            margin-top: 10px;
            flex-wrap: wrap;
        }

        &__actions .minicart-content__action {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: $spacing-04;
        }

        &__action .#{$prefix}--btn {
            width: 100%;
            max-width: 100%;
        }
    }

    @include breakpoint-up(md) {
        &__action .#{$prefix}--btn {
            width: 160px;
        }
    }

    @include breakpoint-down(lg) {
        &__header {
            flex-wrap: wrap;
        }
    }
}

.minicart-product {
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    flex-wrap: nowrap;

    &:first-child {
        padding-top: 0;
    }

    &:not(:first-child) {
        border-top: 1px solid $border-subtle-01;
    }

    &__image,
    &__image img {
        width: 78px;
        height: 78px;
    }

    &__info {
        flex-grow: 1;
        padding-left: 10px;
        padding-right: 10px;
    }

    > * {
        vertical-align: top;
    }

    &__name {
        margin-bottom: $spacing-02;
        @include type-style('label-01');
    }

    &__link {
        color: $text-primary;
        text-decoration: none;
    }

    &__options {
        margin-bottom: $spacing-02;
        @include type-style('label-01');
    }

    &__option {
        margin-right: $spacing-02;

        .list__label:after {
            content: ':';
            margin-left: -3px;
        }
    }

    &__price {
        font-weight: font-family.font-weight('semibold');
    }

    &__field {
        min-width: 40px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    &__attributes {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }

    &__attributes-content {
        height: auto;
        max-height: 0;
        transition: 0.3s max-height;
        overflow: hidden;

        &[aria-hidden="false"] {
            max-height: 600px;
        }
    }

    &__attributes-button {
        .up, .down {
            display: none;
        }
    }

    &__attributes-button[aria-expanded="true"] .down,
    &__attributes-button[aria-expanded="false"] .up {
        display: inline-block;
    }

    &__attributes-list {
        padding: $minicart-product__attributes-list-padding;
        color: $minicart-product__attributes-list-color;
        margin-top: 10px;
        margin-bottom: 10px;

        &:after {
            content: '';
            display: table;
            clear: both;
        }

        .list__label {
            clear: left;
            float: left;
            padding: 0 5px 5px 0;

            &:after {
                content: ':';
            }
        }

        .list__value {
            float: left;
            padding: 0 5px 5px 0;
        }
    }

    &__qty .#{$prefix}--btn {
        margin: 0 !important;
        width: 40px;
        padding-left: 0;
        padding-right: 0;

        &__icon {
            margin: 0 auto !important;

            path {
                fill: $button-primary;
            }
        }
    }

    @include breakpoint-down(md) {
        &__actions  > div {
            display: inline-block;
        }

        &__qty {
            display: flex;
            align-items: flex-end;
        }

        &__update {
            height: 40px;
            min-height: 40px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        &__edit, &__actions {
            align-self: flex-end;
        }

        &__actions {
            margin-left: auto;
        }
    }
}
