@use '../variables.overrides' as vars;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;

@include breakpoint-down(vars.$mobile-menu-breakpoint) {
    .hrp--header__sub,
    .hrp--header__menu-arrow {
        display: none;
    }

    .hrp--header__sub {
        position: absolute !important;
        z-index: 7;
        padding-bottom: convert.rem(48px);
        border-top: 1px solid $border-subtle-01;
        border-bottom: 1px solid $border-subtle-01;

        &.hrp--header__action--active {
            display: block;
        }
    }

    .hrp--header__sub-footer,
    .hrp--header__sub-mobile-item {
        display: flex !important;
        align-items: center !important;
    }

    .hrp--header__sub-footer {
        position: absolute;
        bottom: 0;
        height: convert.rem(48px);
        padding-left: $spacing-02;
    }

    .hrp--header__sub .ui-dialog {
        width: 100%;
        display: block !important;

        > .ui-dialog-content {
            display: block !important;
        }
    }

    .hrp--header__nav:before {
        bakcground: transparent;
    }
}

@include breakpoint-up(vars.$mobile-menu-breakpoint) {
    .hrp--header__sub {
        display: flex !important;
        z-index: inherit !important;
    }

    .hrp--header__sub-mobile {
        display: none;
    }

    .hrp--header__sub-header-wrapper > .ui-dialog {
        position: static;
        display: block !important;
    }

    .hrp--header__nav {
        height: 100%;
        position: relative;
        display: block;
    }

    .hrp--header__menu-arrow-wrapper {
        width: 40px;
        height: 48px;
        margin-right: -18px;
        display: flex;
        align-items: center;
    }

    .hrp--header__menu-arrow {
        margin-left: auto;
        margin-right: auto;
    }
}

@include breakpoint-down(md) {
    .hrp--header__sub {
        top: 55px !important;
    }
}

@include breakpoint-between(md, vars.$mobile-menu-breakpoint) {
    .hrp--header__sub {
        top: 72px !important;
    }
}
