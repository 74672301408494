@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;

.hrp--info {
    padding: $spacing-05;
    border: 1px solid #ededed;
    overflow: hidden;
    display: grid;

    grid-template-areas: 'icon label' 'icon value';
    grid-template-columns: 30px 1fr;
    column-gap: $spacing-05;
}

.hrp--info--lg {
    padding: $spacing-06;
    column-gap: $spacing-06;
    grid-template-areas: 'icon value';
    align-items: center;

    .hrp--info__value {
        overflow: inherit;
        text-overflow: inherit;
        white-space: inherit;
    }
}

.hrp--info__wrapper {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: $spacing-06;
    grid-template-columns: repeat(5, 1fr);

    &.hrp--info__wrapper--col-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint-between(sm, lg) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: $spacing-04 !important;
    }

    @include breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr) !important;
        row-gap: $spacing-04 !important;
    }
}

.hrp--info__icon {
    grid-area: icon;
    align-self: center;
    width: 30px;
    height: 30px;
    fill: $button-primary;
}

.hrp--info__label {
    grid-area: label;
    margin-bottom: 0;
}

.hrp--info__value {
    grid-area: value;
}

.hrp--info__label,
.hrp--info__value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
