@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../carbon/custom/variables' as *;

.checkout__container {
    display: grid;
    padding-top: $spacing-05;

    @include breakpoint-down(lg) {
        grid-template-areas: 'sidebar' 'content';
    }

    @include breakpoint-up(lg) {
        grid-template-areas: 'sidebar content';
        grid-template-columns: 1fr $card-width;
        column-gap: $spacing-07;
    }
}

.checkout__content {
    grid-area: 'content';

    @include breakpoint-down(md) {
        max-width: 100%;
        overflow: hidden;
    }
}

.checkout__sidebar {
    grid-area: 'sidebar';

    @include breakpoint-up(lg) {
        .cart-summary {
            position: sticky;
            top: $spacing-05;
        }
    }
}

.checkout__terms {
    margin-bottom: $spacing-06;
}

.checkout__timer + .checkout__summary {
    border-top: none;
}

.checkout__progress {
    display: flex;

    @include breakpoint-down(md) {
        overflow-x: auto;
    }

    @include breakpoint-up(sm) {
        &[data-step-count="2"] .checkout__progress-step {
            flex: 0 0 calc(100% / 2);
        }

        &[data-step-count="3"] .checkout__progress-step {
            flex: 0 0 calc(100% / 3);
        }

        &[data-step-count="4"] .checkout__progress-step {
            flex: 0 0 calc(100% / 4);
        }

        &[data-step-count="5"] .checkout__progress-step {
            flex: 0 0 calc(100% / 5);
        }
    }
}

.checkout__progress-step {
    flex-grow: 1;
    width: inherit;
    min-width: inherit;
}

.checkout__progress-line {
    width: 100%;
}

.checkout__progress-button {
    display: grid;
    grid-template-areas: 'icon text' 'icon description';
    grid-template-columns: $spacing-05 1fr;
    padding-top: $spacing-04;
    padding-right: $spacing-04;
    column-gap: $spacing-05;
}

.checkout__progress-icon {
    grid-area: icon;
    margin: 0 !important;
}

.checkout__progress-text {
    grid-area: text;
}

.checkout__progress-text-label {
    margin-top: 0;
    max-width: inherit;
}

.checkout__progress-text-label:hover,
.checkout__progress-text-label:active {
    box-shadow: none !important;
    color: inherit !important;
}

.checkout__progress-description {
    grid-area: description;
    position: static;
    margin: 0;

    @include breakpoint-down(md) {
        white-space: nowrap;
    }
}

.checkout__progress-line {
    top: 0;
}
