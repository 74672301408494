@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../../carbon/custom/config' as *;

.#{$prefix}--filter {
    &__search {
        margin-top: $spacing-04;
        margin-bottom: $spacing-04;
    }

    &__actions {
        margin-top: $spacing-04;
    }
}

// Elastic's price range
.#{$prefix}--slider {
    &-container--filter {
        display: block;
        padding-top: $spacing-08;
        position: relative;
    }

    &--without-margin {
        margin: 0;
    }

    &__range-label {
        position: absolute;
        top: 0;
        margin: 0;

        &[data-role="from-label"] {
            left: 0;
        }

        &[data-role="to-label"] {
            right: 0;
        }
    }

    &__actions-toolbar {
        margin-bottom: 0;
        justify-content: space-between;
    }
}

.active-filters__label {
    margin-bottom: $spacing-02;
    margin-top: $spacing-02;
    display: block;
}

.filters__switcher {
    &-icon {
        display: none;
    }

    &[data-state-current="hidden"] .filters__switcher-icon--open {
        display: block;
    }

    &[data-state-current="display"] .filters__switcher-icon--close {
        display: block;
    }
}

.filters__list--mobile-hidden,
.filters__switcher {
    display: none;
}

@include breakpoint-up(lg) {
    .filters__list--mobile-hidden {
        display: block;
    }

    .active-filters__clear-all {
        margin-top: 1rem;
    }
}

@include breakpoint-down(lg) {
    .filters__list {
        margin-top: $spacing-05;
    }

    .#{$prefix}--sidebar-block--with-padding {
        padding: 0;
    }
    .filters__switcher {
        display: inline-flex;
    }

    .active-filters {
        display: flex;
        flex-wrap: wrap;
        margin: 0 #{-$spacing-04};

        &__item {
            margin: 0 $spacing-04;
        }
    }
}

@include breakpoint-between(md, lg) {
    .#{$prefix}--sidebar-block--with-padding {
        position: relative;
    }

    .active-filters__clear-all {
        position: absolute;
        top: -2px;
        right: 0;
    }

    .filters__list {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@include breakpoint-down(md) {
    .filters__switcher {
        width: 100%;
        max-width: 100%;
    }

    .active-filters {
        &__clear-all {
            margin: $spacing-04 $spacing-04 0 $spacing-04;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
