@use "../../../../styles/atom/variables" as *;
@use "../../../../styles/atom/mq" as *;

//
//  Contained row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important; // sass-lint:disable-line no-important
    margin-right: auto !important; // sass-lint:disable-line no-important
    max-width: $max-content-width;

    [data-element='inner'] {
        box-sizing: border-box;
    }
}

//
//  Mobile
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important; // sass-lint:disable-line no-important
        }
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
    }
}

//
//  Full Width row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-width'] {
    > .row-full-width-inner {
        margin-left: auto;
        margin-right: auto;
        max-width: $max-content-width;
        width: 100%;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
    }
}

