.pager__item--current {
    padding: calc(.375rem - 3px) 12px;
}

.pager__list {
    display: flex;
}

.pager__link {
    padding: 0 !important;
    width: 100%;
    text-align: center !important;
    text-indent: 0;
    display: inline-flex;
    line-height: 32px;
    justify-content: center;

    svg {
        margin: 0 auto;
    }
}

.pager__item {
    width: 32px;
    line-height: 32px;
    height: 32px;
    padding: 0 !important;
    display: block;
    text-align: center;
    overflow: hidden;

    svg {
        margin-top: 2px;
    }
}
