@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp--success {
    text-align: center;

    @include breakpoint-down(lg) {
        padding: $spacing-06 0;
    }

    @include breakpoint-up(lg) {
        min-height: 600px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;

        > * {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.hrp--success__page-title {
    font-size: 16px;
}

.hrp--success__message {
    margin-top: $spacing-05;
    margin-bottom: $spacing-08;
    line-height: 1.25;

    @include breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
    }
}

.hrp--success__message .hrp--status__icon {
    margin-bottom: -3px;
}

.hrp--success__container {
    border: 1px solid $border-subtle-01;
    max-width: 600px !important;
    margin: 0 auto;
}

.hrp--success__paragraph {
    margin-top: $spacing-06;
    margin-bottom: $spacing-03;
    padding-left: $spacing-05;
    padding-right: $spacing-05;
}

.hrp--success__highlighted {
    margin-top: $spacing-03;
    margin-bottom: $spacing-06;
    padding-left: $spacing-05;
    padding-right: $spacing-05;
    font-size: 20px;
}

.hrp--success__identifier {
    font-size: 20px;
}

.hrp--success__container-footer.hrp--success__container-footer--sm {
    font-size: 12px;
}

.hrp--success__container-footer.hrp--success__container-footer--lg {
    font-size: 20px;
}

.hrp--success__container-footer {
    border-top: 1px solid $border-subtle-01;
    padding: $spacing-03 $spacing-05;
}

.hrp--success__footer {
    padding-top: $spacing-07;
}
