@use '@carbon/styles/scss/motion' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;

.hrp--promotion-label {
    min-height: convert.rem(48px);
    line-height: convert.rem(25px);
    padding-left: $spacing-05;
    padding-right: $spacing-05;
    background-color: $support-success;
    transition: background-color $transition-base;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        background-color: $background-inverse;
    }

    &__wrapper {
        margin-bottom: $spacing-05;
    }

    &__text {
        color: $text-on-color;
    }

    &__icon {
        fill: $text-on-color;
    }
}
