@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

.toolbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include breakpoint-down(lg) {
        margin-top: $spacing-04;
    }

    @include breakpoint-down(md) {
        > * {
            margin-bottom: $spacing-04;
        }

        &__sorter-label,
        &__limiter-label {
            display: none;
        }
    }

    &__mode {
        display: flex;
    }

    &__amount {
        color: $text-secondary;
    }

    @include breakpoint-down(sm) {
        .toolbar__amount-content span {
            display: none;
        }
    }

    &__sorter-select {
        max-width: 100px;
    }

    &__sorter-select,
    &__limiter-select {
        min-width: inherit !important;
        width: inherit !important;
        height: 18px !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    &__sorter-label {
        @include type-style('body-short-01');
        color: $text-primary;
    }

    &__sorter-select {
        font-weight: font-family.font-weight('semibold') !important;
    }

    &__sort-order {
        display: block;
        padding-left: 0 !important;
        padding-right: 0 !important;

        svg {
            margin: 0 auto;
        }
    }

    &__sort-order-icon {
        color: $text-primary;
    }

    // wishlist toolbar
    &.wishlist-toolbar {
        margin: 0;
    }

    .wishlist-toolbar & {
        justify-content: space-between;
        width: calc(100% + #{$spacing-05});
        margin-top: $spacing-05;

        @include breakpoint-down(md) {
            display: block !important;
            text-align: center;

            .pager__list {
                justify-content: center;
            }
        }
    }

    // catalog list
    .#{$prefix}--catalog-list__products & {
        &__mode,
        &__limiter,
        &__pager {
            display: none;
        }

        &__amount {
            order: 1;
            flex-grow: 10;
            text-align: left;
        }

        &__sorter,
        &__sort-order {
            order: 10;
        }

        &__result-export {
            order: 20;
        }

        &__result-export .bx--overflow-menu-options {
            left: inherit;
            right: 0;
            width: 200px;
        }

        &__result-export .bx--overflow-menu-options__btn {
            overflow: visible;
            min-width: 100%;
        }

        ~ .toolbar {
            .toolbar {
                &__sorter,
                &__sort-order,
                &__amount,
                &__result-export {
                    display: none;
                }

                &__limiter,
                &__pager {
                    display: block;
                }
            }
        }
    }
}
