@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '../carbon/custom/config' as *;

.hrp--widget__title {
    margin: $spacing-06 0;
}

@include breakpoint-down(xlg) {
    .hrp--widget__content .#{$prefix}--catalog-item__wrapper--grid {
        justify-content: center;
    }
}
