@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp--icon {
    margin-right: $spacing-02;

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &--green, &--success {
        fill: $support-success;
    }

    &--red, &--error {
        fill: $support-error;
    }
}
