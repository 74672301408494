@use 'sass:math';

@use '@carbon/motion' as *;
@use '../custom/components/header/variables.shell-header' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/scale';
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;

.hrp--header {
    &__action {
        flex: 0 0 3rem;
        display: inline-block;
        text-align: center;

        svg {
            fill: $text-primary;
        }

        &--active {
            border-color: transparent;
        }
    }

    &__labelled-action {
        background: transparent;
        display: flex;
        align-items: center;
        height: 3rem;
        border: convert.rem(2px) solid transparent;
        padding: 0 convert.rem( math.div(48px - 20px, 2) );
        transition: background-color $duration-fast-02;
        text-decoration: none;

        &:focus {
            outline: none;
            border-color: $text-primary;
        }

        &:hover {
            background-color: $shell-header-bg-04;
        }
    }

    &__labelled-action-icon {
        box-sizing: content-box;

        @include breakpoint-up(lg) {
            padding-right: $spacing-05;
        }
    }

    &__labelled-action-icon--half-spacing {
        @include breakpoint-up(lg) {
            padding-right: $spacing-03;
        }
    }

    &__labelled-action-label {
        text-align: left;
        padding: 0 $spacing-02;

        @include breakpoint-down(lg) {
            display: none;
        }
    }

    &__labelled-action-name,
    &__labelled-action-amount {
        display: block;
        font-size: scale.type-scale(1);
        line-height: convert.rem(18px);
        white-space: nowrap;
    }

    &__labelled-action-name {
        color: $text-secondary;
    }

    &__labelled-action-amount {
        color: $text-primary;
        font-weight: font-family.font-weight('semibold');
        min-width: 60px;
    }
}
