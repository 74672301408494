@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

.multi-cart {
    &__header {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $spacing-05;
        align-items: center;
    }

    &__save {
        display: flex;
    }

    &__save-toggle {
        margin-right: $spacing-04;
    }

    &__select {
        width: inherit !important;
    }

    &__selector {
        display: flex;
        align-items: center;
    }

    &__selector-form {
        flex-grow: 2;
    }

    &__selector-label {
        margin-right: $spacing-03;
    }

    &__action {
        padding: 0 !important; // TODO: refactor bx--btn classes
        margin-right: $spacing-04;
    }

    @include breakpoint-down('md') {
        &__select {
            width: 100% !important;
            max-width: inherit !important;
        }

        &__selector {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: $spacing-04;
        }

        &__select-wrapper {
            flex-grow: 2;
        }

        &__save {
            flex-wrap: wrap;
        }
    }

    @include breakpoint-up('md') {
        &__selector {
            margin-right: $spacing-03;
        }
    }
}
