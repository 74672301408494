@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';

$compare__border                   : 1px solid $border-subtle-01 !default;
$compare__margin-bottom            : $spacing-04 !default;
$compare__table-width              : 100% !default;
$compare__label-width              : 240px !default;
$compare__label-background         : transparent !default;
$compare__label-padding            : $spacing-02 !default;
$compare__label-padding\@medium    : $spacing-03 !default;
$compare__attributes-margin-bottom : $spacing-03 !default;
$compare__cell-padding             : $spacing-03 !default;
$compare__cell-line-height         : $spacing-05 !default;
$compare__cell-list-padding-left   : $spacing-04 !default;
$compare__cell-item-line-height    : 1.4em !default;
$compare__image-size               : auto !default;
$compare__image-max-size           : 100% !default;
$compare__form-margin              : 0 !default;
$compare__cart-width               : 120px !default;
$compare__cart-padding             : 0 !default;
$compare__cart-padding\@medium     : 0 $spacing-04 !default;
$compare__wishlist-size            : 48px !default;
$compare__wishlist-padding         : 0 !default;
$compare__wishlist-background      : transparent !default;
$compare__wishlist-border          : none !default;
$compare__wishlist-cursor          : pointer !default;
$compare__wishlist-icon-size       : 18px !default;
$compare__wishlist-icon-fill       : $button-primary !default;
$compare__wishlist-icon-fill--hover: $button-secondary !default;
$compare__wishlist-icon-transition : 0.3s !default;

.compare {
    margin-bottom: $compare__margin-bottom;
    overflow-x: auto;

    &__table {
        border-collapse: collapse;
        border-spacing: 0;

        @include breakpoint-down(lg) {
            width: $compare__table-width;
            table-layout: fixed;
        }
    }

    &__label {
        width: $compare__label-width;
        text-align: left;
        padding: $compare__label-padding;
        background-color: $compare__label-background;
        border-bottom: $compare__border;

        @include breakpoint-down(lg) {
            padding: $compare__label-padding\@medium;
        }
    }

    &__cell {
        position: relative;
        padding: $compare__cell-padding;
        line-height: $compare__cell-line-height;
        width: $compare__label-width;
        border-bottom: $compare__border;
        word-wrap: break-word;

        ul {
            padding-left: $compare__cell-list-padding-left;
        }

        li {
            line-height: $compare__cell-item-line-height;
        }
    }

    &__product {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .image {
        width: $compare__image-size;
        height: $compare__image-size;
        max-width: $compare__image-max-size;
        max-height: $compare__image-max-size;
    }

    &__link {
        margin-bottom: $compare__attributes-margin-bottom;
        color: $text-primary;
        text-decoration: none;
        display: block;
        min-height: 55px;

        @include type-style('body-short-01');
    }

    &__rating {
        display: block;
        margin-bottom: $compare__attributes-margin-bottom;
    }

    &__price {
        width: 100%;
        margin-bottom: $compare__attributes-margin-bottom;

        .price__value--old {
            @include type-style('body-short-01');
            color: $text-primary;
        }

        .price__value:not(.price__value--old) {
            @include type-style('productive-heading-03');
            font-weight: font-family.font-weight('semibold');
            color: $text-primary;
        }
    }

    &__sku-wrapper {
        @include type-style('label-01');
        color: $text-secondary;
        width: 100%;
    }

    &__buttons-container {
        width: 100%;
        align-self: flex-end;
    }

    &__secondary-buttons {
        display: flex;
        margin-top: $compare__attributes-margin-bottom;
        margin-bottom: $compare__attributes-margin-bottom;
    }

    &__form {
        margin: $compare__form-margin;
    }

    &__cart-icon {
        @include breakpoint-down(lg) {
            display: none;
        }
    }

    &__wishlist {
        padding-left: 0 !important;
    }

    &__remove {
        position: absolute !important;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px !important;
        min-height: inherit !important;
        border-radius: 20px;
        background-color: #fff;

        &:hover,
        &:active {
            background-color: $layer-01;
        }
    }

    &__data-row--hidden {
        display: none;
    }

    &__data-row--highlighted td,
    &__data-row--highlighted th {
        background-color: $button-primary;
        color: $text-on-color;
    }

    &__label--product {
        opacity: 0;
        visibility: hidden;
        border: none;
    }

    &__cell--product {
        border: none;
        background-color: transparent;
    }
}
