@use '@carbon/layout/scss/spacing' as *;
@use '../carbon/custom/config' as *;

.hrp--modal.hrp--modal--blur {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
}

.hrp--modal--blur .hrp--modal__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hrp--modal--blur .hrp--modal__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    row-gap: $spacing-06;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .hrp--modal__text {
        color: #fff;
    }

    .#{$prefix}--loading {
        width: 50px;
        height: 50px;
    }

    .#{$prefix}--loading__stroke {
        stroke: #fff;
    }
}
