@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/components/link/link' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/config' as *;

@mixin mobile-cart-item {
    .cart-list-item {
        flex-wrap: wrap;
    }

    .cart-list-item__wrapper {
        flex-wrap: wrap;
        margin: 0 !important;
    }

    .cart-list-item__qty {
        margin-top: $spacing-03;
    }

    .cart-list-item__quantity {
        margin: 0 0 $spacing-03 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .cart-list-item__price-wrapper {
        flex-grow: 1;
    }

    .cart-list-item__price {
        margin-bottom: 0 !important;
    }

    .cart-list-item__additional {
        margin-top: $spacing-04;
    }
}

.cart-empty {
    text-align: center;
    padding: $spacing-08 0;
}

.cart-empty__icon {
    width: 56px;
    height: 56px;
    margin-bottom: $spacing-06;
}

.cart-list-item__wrapper {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    max-width: 100%;
}

.cart-list-item__qty-input {
    width: 60px !important;
    margin-left: auto;
}

.cart-list-item__actions {
    width: 40px;
    justify-content: center;
    margin-left: $spacing-04;
}

.cart-list-item__quantity {
    margin: 0 $spacing-04;
    white-space: nowrap;
}

.cart-list-item__price {
    @include breakpoint-down(lg) {
        display: flex;
    }
}

.cart-list-item__price-label {
    @include type-style('label-01');
    color: $text-secondary;
    display: block;
    margin-bottom: $spacing-02;
    white-space: nowrap;
}

.cart-list-item__price--unit {
    @include breakpoint-down(lg) {
        margin-right: $spacing-04;
    }
}

.cart-list-item__price--unit,
.cart-list-item__price--row {
    text-align: right;
    margin-bottom: $spacing-03;
    @include font-family.font-weight('semibold');

    @include breakpoint-up(lg) {
        text-align: right;
    }

    @include breakpoint-down(lg) {
        text-align: left;
    }

    li {
        display: inline;
    }
}

.cart-list-item__price--unit-big .cart-list-item__price-value {
    font-size: 14px;
    @include font-family.font-weight('semibold');
}

.cart-list-item__price--unit-big {
    margin: 0 $spacing-05 0 0;
    width: 120px;
    flex: 0 0 120px;
}

.cart-list-item__price-wrapper {
    margin-left: $spacing-04;
    min-width: 150px;
    text-align: right;
}

.cart-list-item__stock {
    font-weight: font-family.font-weight(semibold);
}

.cart-list-item__stock--in-stock {
    color: $support-success;
}

.cart-list-item__stock--out-of-stock {
    color: $support-warning;
}

.cart-list-item__stock-status {
    color: $support-success;
}

.cart-list-item__additional {
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 400px) and (max-width: 889px) {
    .cart-list--fast-checkout {
        margin: 0;

        .cart-list-item {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .cart-list-item__wrapper--checkout {
            grid-template-columns: 100px 1fr 40px;
        }

        .cart-list-item__wrapper--checkout .lazyload-wrapper .catalog-grid-item__image {
            width: 100px;
            height: auto;
        }
    }

    .cart-list-item__actions {
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .button.action.delete {
            width: 48px;
        }
    }
}

@media (min-width: 890px) and (max-width: 1056px) {
    .cart-list--fast-checkout {
        margin: 0;

        & > .cart-list-item {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .cart-list-item__wrapper--checkout {
            grid-template-columns: 100px 1fr 40px;
        }

        .cart-list-item__wrapper--checkout .lazyload-wrapper .catalog-grid-item__image {
            width: 100px;
            height: auto;
        }

        .cart-list-item__price--unit-big {
            flex-wrap: wrap;
        }

        .cart-list-item__wrapper {
            justify-content: space-between;
        }

        .#{$prefix}--catalog-item__image-wrapper,
        .#{$prefix}--catalog-item__main,
        .#{$prefix}--catalog-item__add-to-cart-wrapper {
            flex: none;
            max-width: none;
        }

        .#{$prefix}--catalog-item__additional-info {
            display: block;
        }

        .#{$prefix}--catalog-item__separator {
            display: none;
        }

        .cart-list-item__wrapper {
            flex-wrap: wrap;
        }
    }
}

@include breakpoint-down(lg) {
    .cart-list:not(.cart-list--fast-checkout) {
        @include mobile-cart-item;
    }
}

@media (max-width: 889px) {
    .cart-list--fast-checkout {
        @include mobile-cart-item;
    }

    .cart-list-item__wrapper--checkout {
        display: grid;
        grid-template-areas: 'thumbnail data data' 'thumbnail quantity quantity' 'thumbnail price actions';
        grid-template-columns: 57px 1fr 40px;
        grid-auto-rows: auto;
        grid-column-gap: $spacing-05;
        align-items: start;

        .lazyload-wrapper {
            grid-area: thumbnail;

            .catalog-grid-item__image {
                width: 57px;
                height: auto;
            }
        }

        .cart-list-item__data {
            grid-area: data;
        }

        .cart-list-item__quantity {
            grid-area: quantity;
        }

        .cart-list-item__price {
            grid-area: price;
            display: block;
            margin: 0;
        }

        .cart-list-item__actions {
            grid-area: actions;
            margin: 0;
        }
    }
}

@include breakpoint-up(md) {
    .cart-list-item__quantity--140 {
        width: 140px;
        min-width: 140px;
    }

    .cart-list-item__wrapper {
        .lazyload-wrapper {
            width: 100px;
            max-width: 100px;
            flex: 0 0 100px;
            display: block;
        }

        .image {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.cart-totals__row {
    display: flex;
    justify-content: space-between;
    margin: $spacing-04 0;
}

.cart-totals__row--summary {
    padding-top: $spacing-04;
    border-top: 1px solid $border-subtle-01;
    display: block;
}

.cart-totals__row-value--total {
    @include type-style('expressive-heading-04', true);
}

.cart-totals__shipping-price {
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
}

.cart-summary__checkout-button {
    width: 100%;
    text-align: center;
    padding-right: 12px;
    display: inline-block;
    margin-bottom: $spacing-04;
}

.cart-summary .cart-summary__multiple-addresses {
    color: $link-inverse;
}

.checkout__content--cart .cart-list-item {
    margin-top: 0;
}

.shipping-estimation {
    background-color: $background;
    padding: $spacing-04;
    margin-bottom: $spacing-04;
}
