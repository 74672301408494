@use "../../../../styles/atom/variables" as *;
@use "../../../../styles/atom/mq" as *;

//
//  Default appearance styles
//  _____________________________________________

[data-content-type="slide"] {
    min-height: inherit;
    overflow: hidden;

    > [data-element='link'],
    > [data-element='empty_link'] {
        color: inherit;
        min-height: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }
}

.pagebuilder-slide-wrapper {
    border-radius: inherit;
    min-height: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        border-radius: inherit;
        padding: $spacer--large;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: inherit;
        }

        &:not(.pagebuilder-poster-overlay) {
            max-width: 540px;
        }
    }

    [data-element='content'] {
        min-height: 50px;
    }

    .pagebuilder-slide-button {
        margin: $spacer--medium  0 0 0;
        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m) {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}

//
//  Poster appearance styles
//  _____________________________________________

.pagebuilder-slide-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}

//
//  Collage right appearance styles
//  _____________________________________________

[data-appearance="collage-right"] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}

//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance="collage-left"] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}

//
//  Collage center appearance styles
//  _____________________________________________

[data-appearance="collage-centered"] {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
