@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../carbon/custom/config' as *;
@use '../carbon/custom/variables' as *;

.#{$prefix}--css-grid {
    &__container {
        display: grid;
        grid-gap: $spacing-06;

        @include breakpoint-down(lg) {
            grid-template-areas: 'sidebar' 'content';
        }

        @include breakpoint-up(lg) {
            grid-template-areas: 'sidebar content' 'additional content';
            -ms-grid-columns: $card-width 1fr;
            grid-template-columns: $card-width 1fr;
            -ms-grid-rows: min-content 1fr;
            grid-template-rows: -webkit-min-content 1fr;
            grid-template-rows: min-content 1fr;
        }
    }

    &__sidebar {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-area: sidebar;
    }

    &__sidebar-additional {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        grid-area: additional;
        padding-bottom: 4rem;

        @include breakpoint-down('lg') {
            display: none;
        }
    }

    &__content {
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        -ms-grid-column: 2;
        grid-area: content;
    }
}
