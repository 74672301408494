@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;

.filedrag {
    border: 2px dashed #AAA;
    border-radius: 7px;
    cursor: default;
    padding: $spacing-06 $spacing-06 0 $spacing-06;
    margin-bottom: 20px;
    width: 100%;
}

.filedrag .drag-label {
    font-weight: bold;
    text-align: center;
    display: block;
    color: #AAA;
    margin-top: 10px;
    margin-bottom: -10px;
}

.filedrag.hover {
    border-color: #F00;
    border-style: solid;
    box-shadow: inset 0 3px 4px #888;
}

.filedrag.hover .drag-label {
    color: #F00;
}

.custom-file-input-wrapper {
    position: relative;
}

.custom-file-input-wrapper .custom-file-input-button {
    margin-right: $spacing-04;
    margin-bottom: $spacing-06;
}

.custom-file-input-file-name {
    width: 100%;
}

.custom-file-input-wrapper .custom-file-input-button * {
    cursor: pointer;
    display: block;
}
.custom-file-input-wrapper .custom-file-input-clear-button {
    cursor: pointer;
    margin-bottom: $spacing-06;
}

.custom-file-input-wrapper .custom-file-input-button input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
}

.custom-file-input-file-name {
    display: none;
}

.custom-file-input-button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
