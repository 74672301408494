@use '@carbon/styles/scss/type' as *;
@use '@carbon/layout' as *;
@use '@carbon/styles/scss/theme' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/grid/scss/config' as grid;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;
@use 'sass:math';

@mixin make-row($gutter: grid.$grid-gutter) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1 * $gutter * 0.5;
    margin-left: -1 * $gutter * 0.5;
}

@mixin -make-col($span, $columns) {
    @if $span == 0 {
        display: none;
    } @else {
        // Explicitly include `display: block` to override
        display: block;
        // Add a `max-width` to ensure content within each column does not blow out
        // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
        // do not appear to require this.
        max-width: math.percentage(math.div($span, $columns));
        flex: 0 0 math.percentage(math.div($span, $columns));
    }
}

@mixin -make-col-ready(
    $gutter: grid.$grid-gutter,
    $condensed-gutter: grid.$grid-gutter-condensed
) {
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    padding-right: ($gutter * 0.5);
    padding-left: ($gutter * 0.5);

    // For our condensed use-case, our gutters collapse to 2px solid, 1px on each
    // side.
    .#{$prefix}--row--condensed &,
    .#{$prefix}--grid--condensed & {
        padding-right: ($condensed-gutter * 0.5);
        padding-left: ($condensed-gutter * 0.5);
    }

    // For our narrow use-case, our container hangs 16px into the gutter
    .#{$prefix}--row--narrow &,
    .#{$prefix}--grid--narrow & {
        padding-right: ($gutter * 0.5);
        padding-left: 0;
    }
}

.dashboard-items {
    &__address {
        @include type-style('body-short-01');

        p {
            margin-bottom: $spacing-02;
        }

        a {
            @extend .#{$prefix}--link;
        }
    }

    &__item h4 {
        @include type-style('heading-02');
        margin-bottom: $spacing-05;
    }

    &__content {
        margin-bottom: $spacing-08;

        &.row {
            @include make-row();
        }

        .col-sm-6 {
            @include breakpoint-up('sm') {
                @include -make-col(6, map-get(map-get($grid-breakpoints, lg), 'columns'));
                @include -make-col-ready();
            }
        }
    }
}

.dashboard-table {
    &__link {
        @extend .#{$prefix}--link;
    }

    &__header {
        margin-bottom: $spacing-04;
    }
}

.dashboard-table__title,
.dashboard-items__title,
.dashboard-form__title-form {
    @include type-style('productive-heading-03');
    margin-bottom: $spacing-05;
}

.dashboard-form {
    &__title {
        margin-bottom: $spacing-03;
    }

    &__select {
        margin-bottom: $spacing-03;
    }

    &__divider {
        margin-bottom: $spacing-08;
    }
}

div.action,
div.actions-toolbar {
    display: flex;
    align-items: center;
    margin-top: $spacing-04;
    margin-bottom: $spacing-04;
}

.action__button,
.action.primary {
    @extend .#{$prefix}--btn;
    @extend .#{$prefix}--btn--primary;
}

.action__handler,
.actions-toolbar .action.primary {
    margin-right: $spacing-04;
}

.action__link,
.actions-toolbar .secondary .action {
    @extend .#{$prefix}--link;
}

.actions .link {
    @extend .#{$prefix}--link;
}

.field.password-info {
    @include type-style('helper-text-01');
    font-style: italic;
    color: $text-secondary;
    z-index: 0;
    opacity: 1;
    margin-bottom: $spacing-03;
}

.field.choice {
    display: flex;

    .checkbox {
        flex: inherit;
        margin-right: $spacing-03;
    }
}

.fieldset {
    margin-bottom: $spacing-04;

    .field,
    > .input {
        margin-bottom: $spacing-03;
    }
}
