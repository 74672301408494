@use '@carbon/styles/scss/type' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/styles/scss/components/form/form' as *;

.bfb-element-label {
    @include reset;
    @include type-style('label-01');

    display: inline-block;
    margin-bottom: $spacing-03;
    color: $text-secondary;
    font-weight: $input-label-weight;
    line-height: 1rem;
    vertical-align: baseline;
}

.bfb-element-control-inner .iti--allow-dropdown {
    width: 100%;
}

.magezon-builder {
    margin-top: $spacing-06;

    .mgz-container {
        width: 100% !important;
    }

    .mgz-container .mgz-element-inner {
        padding: 0 !important;
        margin: 0 !important;
        text-align: left;
    }

    > .mgz-element-row > .mgz-element-inner {
        border-top: 0 !important;
        padding-top: 0 !important;
        margin: 0 !important;
    }
}

.empty > .block.bfb {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 750px;
}
