@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../custom/config' as *;

@include breakpoint-up(lg) {
    .#{$prefix}--btn-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: $spacing-06;
    }
}

.#{$prefix}--btn-group .#{$prefix}--btn {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;

    @include breakpoint-down(lg) {
        margin-bottom: $spacing-04;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.#{$prefix}--btn.#{$prefix}--skeleton:before {
    top: 0;
    left: 0;
}
