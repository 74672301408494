@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

.hrp--box {
    padding: 30px;
    border: 2px solid $border-subtle-01;
}

.hrp--box__title {
    font-width: bold;
    margin-bottom: $spacing-05;
}

.hrp--box--filled {
    background-color: $layer-01;
    border: none;
}
