@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

.cms-content {
    color: $text-primary;
    margin-bottom: 4rem;
}

.cms-content--without-margin {
    margin-bottom: 0;
}

.cms-content {
    h1[data-content-type="heading"],
    > h1 {
        @include type-style('expressive-heading-05', true);
        margin-bottom: $spacing-05;
    }

    h2[data-content-type="heading"],
    > h2 {
        @include type-style('expressive-heading-04', true);
        margin-bottom: $spacing-05;
    }

    h3[data-content-type="heading"],
    > h3 {
        @include type-style('expressive-heading-03', true);
        margin-bottom: $spacing-05;
    }

    h4[data-content-type="heading"],
    > h4 {
        @include type-style('expressive-heading-02', true);
        margin-bottom: $spacing-05;
    }

    h5[data-content-type="heading"],
    > h5 {
        @include type-style('expressive-heading-01', true);
        margin-bottom: $spacing-05;
    }

    > .small {
        font-size: 0.75rem;
        line-height: 125%;
    }
}

.cms-content > , [data-element="main"] > {
    a:not([class*="pagebuilder-button-"]) {
        color: $link-primary;
        text-decoration: none;

        &:visited {
            color: $link-visited;
        }
    }

    p {
        @include type-style('body-long-02');
        margin-bottom: $spacing-04;
    }

    p:empty {
        display: none;
    }

    ul, ol, li, table {
        margin-bottom: $spacing-05;
    }

    img, figure, figcaption, video, audio {
        box-sizing: border-box;
        max-width: 100%;
        display: block;
    }

    img, figure, video, audio {
        margin: $spacing-06 0;
        height: auto;
    }

    figure, figcaption {
        position: relative;
        font-size: 0.75rem;
        line-height: 125%;
    }

    figure img {
        margin: 0;
    }

    figcaption {
        background-color: $layer-02;
        padding: $spacing-03 $spacing-04;
        margin: 0;
    }

    table {
        @include main-table;
    }

    ul {
        padding-left: $spacing-08;
        list-style: disc;
    }

    ol {
        padding-left: $spacing-08;
        list-style-type: decimal;
    }

    ul li, ol li {
        margin: $spacing-03 0;
        padding: 0;
        @include type-style('body-long-02');
    }

    i, em {
        font-style: italic;
    }
}

.cms-content [data-content-type="banner"] {
}

.cms-content [data-content-type="slider"] {
    position: relative;
}
