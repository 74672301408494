@use "../../../../styles/atom/variables" as *;
@use "../../../../styles/atom/mq" as *;

//
//  Collage center appearance styles
//  _____________________________________________

[data-appearance='collage-centered'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

//
//  Collage left appearance styles
//  _____________________________________________

[data-appearance='collage-left'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-right: auto;
        }
    }
}

//
//  Collage right appearance styles
//  _____________________________________________

[data-appearance='collage-right'] {
    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            margin-left: auto;
        }
    }
}

//
//  Default appearance styles
//  _____________________________________________

[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

.pagebuilder-banner-wrapper {
    background-clip: padding-box;
    border-radius: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;

    .pagebuilder-overlay {
        padding: $spacer--large;
        transition: background-color 500ms ease;

        &.pagebuilder-poster-overlay {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        &:not(.pagebuilder-poster-overlay) {
            max-width: 540px;
        }
    }

    [data-element='content'] {
        min-height: 50px;
    }

    .pagebuilder-banner-button {
        margin: $spacer--medium 0 0 0;
        max-width: 100%;
        text-align: inherit;
        transition: opacity 500ms ease;
        word-break: break-word;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    .pagebuilder-banner-wrapper {
        background-attachment: scroll !important; // sass-lint:disable-line no-important

        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}

//
//  Poster appearance styles
//  _____________________________________________

.pagebuilder-banner-wrapper {
    .pagebuilder-poster-content {
        width: 100%;
    }
}

