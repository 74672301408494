@use '@carbon/styles/scss/type' as *;
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

body {
    --pv-gutter: #{$spacing-05};
    --content-padding: 1.5rem 1rem;

    @include breakpoint-up('lg') {
        --pv-gutter: #{$spacing-06};
        --content-padding: 0 3rem 4rem 3rem;
    }
}

.page-heading {
    &__h1 {
        @include type-style('productive-heading-05');
    }

    &__h2 {
        @include type-style('productive-heading-04');
    }

    &__h3 {
        @include type-style('productive-heading-03');
    }

    &__h4 {
        @include type-style('heading-02');
    }

    &__h2, &__h3, &__h4 {
        margin-bottom: 2rem;
    }
}
