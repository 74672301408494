@use '@carbon/type/scss/font-family';
@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/styles/scss/components/button/tokens' as *;
@use '../../carbon/custom/tokens' as *;
@use '../../carbon/custom/config' as *;

// tabs
.#{$prefix}--tabs--products {
    .#{$prefix}--tabs__nav {
        width: 100%;
    }

    .#{$prefix}--tabs__nav-item {
        max-width: 100% !important;
        display: inline-flex !important;
        text-align: center !important;
        width: fit-content !important;
        justify-content: center;
    }

    @include breakpoint-down(md) {
        .#{$prefix}--tabs {
            overflow-x: auto;
        }
    }
}

.#{$prefix}--tabs--ag {
    margin-top: $spacing-06;
    margin-bottom: $spacing-04;

    @include breakpoint-down(lg) {
        margin-top: $spacing-04;
        margin-bottom: 0;
    }
}

.#{$prefix}--tabs--ag .#{$prefix}--tabs__nav-link {
    padding-top: $spacing-06;
    padding-bottom: $spacing-06;
    font-weight: font-family.font-weight('semibold');
}

.#{$prefix}--tabs__nav-item--selected {
    border-color: $button-primary !important;

    .#{$prefix}--nav-item--icon {
        fill: $button-primary;
    }
}

.#{$prefix}--tabs--ag .#{$prefix}--tabs__nav-link:not(.#{$prefix}--tabs__nav-item--selected) {
    color: #a4a4a4;
}

.#{$prefix}--tabs--w100 {
    width: 100% !important;
}

.#{$prefix}--tabs__nav-item--simple {
    max-width: inherit !important;
}

.#{$prefix}--tabs__nav-item--placeholder {
    width: auto;
    min-width: auto;
    max-width: 100% !important;
    flex: 100 0 min-content !important;
    border-bottom: 2px solid $border-subtle-01;
    margin-left: 1px;
}

.#{$prefix}--tabs__nav-item--icon-and-label {
    display: flex !important;
    align-items: center !important;
    gap: $spacing-05 !important;
    max-width: inherit !important;
}

.#{$prefix}--tabs--ag.#{$prefix}--tabs--contained {
    margin-bottom: 0;
}

.#{$prefix}--tabs--ag ~ .hrp-ag--outer-wrap {
    .hrp-ag--header-actions {
        margin-top: 0;
    }

    .hrp-ag--mobile-action {
        border-top: none;
    }
}

.#{$prefix}--tag__description {
    padding-bottom: $spacing-05;
}
