@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/type/scss/font-family';
@use '../../carbon/custom/config' as *;
@use '../../common/mixins' as *;

.#{$prefix}--tooltip__trigger.#{$prefix}--label {
    font-size: 0.75rem;
}

.#{$prefix}--text-area {
    min-height: 50px;
    resize: none;
}

.#{$prefix}--number input[type='number'] {
    border-color: $field-01 !important;
}

.#{$prefix}--checkbox {
    &-label {
        padding-left: convert.rem(25px);
    }

    &-label__w100 {
        width: 100%;
        justify-content: space-between;
    }

    &-label-additional {
        white-space: nowrap;
        font-weight: font-family.font-weight('semibold');
    }

    &-label-count {
        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }

    &-label--filter {
        display: block;
    }
}

.#{$prefix}--fieldset--nomargin {
    margin-bottom: 0;
}


.#{$prefix}--legend--heading {
    margin-bottom: $spacing-06;
}

.#{$prefix}--select--w100 {
    .#{$prefix}--select-input__wrapper {
        flex-wrap: wrap;
    }

    &, .#{$prefix}--select-input__wrapper, select {
        width: 100%;
        max-width: 100%;
    }
}

.#{$prefix}--catalog-item .#{$prefix}--form-item_col-50:first-child,
.#{$prefix}--form-item_col-auto:first-child,
.table__td--qty  .#{$prefix}--form-item_col-50:first-child,
.product-list-cart-form .#{$prefix}--form-item_col-50:first-child,
.#{$prefix}--form-item--with-gap > .#{$prefix}--form-item:first-child,
.product-view__qty {
    padding-right: convert.rem(8px);
}

.#{$prefix}--catalog-item .#{$prefix}--form-item_col-50:last-child,
.#{$prefix}--form-item_col-auto:last-child,
.table__td--qty  .#{$prefix}--form-item_col-50:last-child,
.product-list-cart-form .#{$prefix}--form-item_col-50:last-child,
.#{$prefix}--form-item--with-gap > .#{$prefix}--form-item:last-child,
.product-view__add-to-cart {
    padding-left: convert.rem(8px);
}

.#{$prefix}--form-inline--same-size > * {
    flex-grow: 1 !important;
}

.#{$prefix}--form-items-wrapper {
    .#{$prefix}--form-item {
        margin-bottom: $spacing-06;
    }
}

.#{$prefix}--form-item-wrapper--mobile-last {
    @include breakpoint-up(lg) {
        margin-left: auto;
    }

    .#{$prefix}--form-item {
        margin-bottom: 0;
    }

    @include breakpoint-down(lg) {
        .#{$prefix}--label {
            display: none;
        }
    }
}

.#{$prefix}--form-inline {
    display: flex;
    align-items: flex-end;
    width: 100%;
    position: relative;

    &--sm {
        max-width: convert.rem(280px);
    }

    &--md {
        max-width: convert.rem(560px);
    }

    .#{$prefix}--btn {
        @include btn--flat;

    }

    .#{$prefix}--number__input-wrapper {
        width: 100%;
    }

    &:not(&--with-simple-error) div.mage-error {
        position: absolute;
        top: 100%;
        background: white;
        padding: convert.rem(5px);
        border: 1px solid $support-error;
        z-index: 1;
    }

    &--without-mage-error div.mage-error {
        display: none !important;
    }

    &--without-mage-error .has-error .#{$prefix}--tooltip__trigger {
        display: block !important;
    }

    &--without-mage-error .mage-error ~ .#{$prefix}--form-requirement {
        display: block !important;
        color: $text-error;
        max-height: inherit;

        svg {
            color: $text-error;
            margin-bottom: -3px;
        }
    }
}

.#{$prefix}--form-item--icon-button {
    flex: 0 0 40px;
    max-width: 40px;
    box-sizing: content-box;
}

.#{$prefix}--form-item--element {
    margin-bottom: $spacing-04;
}

.#{$prefix}--form-item--grow {
    flex-grow: 100;
    margin-right: $spacing-05;
}

.#{$prefix}--form-item_col-auto:first-child {
    margin-left: auto;
    flex: 0 0 auto;
}

.#{$prefix}--form-item_col-auto:last-child {
    flex: 0 0 min-content;
    max-width: min-content;
}

.#{$prefix}--form-item_col-50 {
    flex: 0 0 50%;
    max-width: 50%;
}

.#{$prefix}--form-item_col-50 .#{$prefix}--btn {
    width: 100%;
}

@include breakpoint-up(lg) {
    .#{$prefix}--catalog-item.#{$prefix}--catalog-item--list .#{$prefix}--form-item_col-50:first-child {
        flex: 0 0 65%;
        max-width: 65%;
    }

    .#{$prefix}--catalog-item.#{$prefix}--catalog-item--list .#{$prefix}--form-item_col-50:last-child {
        flex: 0 0 35%;
        max-width: 35%;
    }
}
