@use '@carbon/styles/scss/type' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;
@use '@carbon/type/scss/font-family';

.#{$prefix}--catalog-item--grid .#{$prefix}--catalog-item {
    &__price {
        .price__value--old {
            @include type-style('body-short-01');
            color: $text-primary;
        }

        .price__value:not(.price__value--old) {
            @include type-style('productive-heading-03');
            font-weight: font-family.font-weight('semibold');
            color: $text-primary;
        }
    }
}

.#{$prefix}--catalog-item--list .#{$prefix}--catalog-item {
    &__tag-wrapper {
        .#{$prefix}--tag {
            margin: 0 $spacing-03 $spacing-04 0;
        }
    }

    &__price {
        .price-label {
            display: none;
        }

        .price__value {
            display: block;
        }

        .price__value--crossed-price {
            font-size: 1rem;
            line-height: 1;
            color: $text-placeholder;
            text-decoration: line-through;
            font-weight: font-family.font-weight('regular');
        }

        .price__value--old {
            order: 2;
            @include type-style('body-short-01');
            color: $text-primary;
        }

        .price__value:not(.price__value--old):not(.price__value--crossed-price) {
            @include type-style('productive-heading-03');
            font-weight: font-family.font-weight('semibold');
            color: $text-primary;
        }
    }
}

.sidebar-block {
    &__title {
        @include type-style('expressive-heading-03', true);
        margin-top: 2rem;
        margin-bottom: 1rem;

        .sidebar-block__counter {
            padding: 0 0.5rem;
            margin: 0 0 5px 0;
        }
    }

    &--hidden, &__list--hidden, &__hidden {
        display: none;
    }
}
