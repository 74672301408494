@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/layout/scss/convert';
@use '@carbon/themes/scss/tokens' as *;

.fast-checkout {
    .table--product + .table--product {
        margin-top: $spacing-09;
        position: relative;

        &:before {
            content: '';
            border: solid #d3d3d3;
            border-width: 0 0 convert.rem(2px) 0;
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            top: -$spacing-06;
        }
    }

    .cms-content {
        h1, h2, h3, h4, h5, h6 {
            &:empty {
                display: none;
            }
        }
    }
}

.fast_checkout-success-index .page-wrapper {
    height: 100%;
    background-color: $layer-01;
}

.fast_checkout-success-index .page-main {
    margin-bottom: 0;
}

.fast_checkout-success-index .hrp--header__wrapper {
    background-color: $background;
}
