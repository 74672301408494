@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '@carbon/type/scss/font-family';
@use '../carbon/custom/variables' as *;

.hrp--card__wrapper {
    display: flex;
    column-gap: $spacing-07;
    row-gap: $spacing-07;
    flex-wrap: wrap;

    @include breakpoint-down(md) {
        justify-content: center;
    }
}

.hrp--card {
    flex: 0 0 $card-width;
    max-width: $card-width;
    min-height: 415px;

    padding: $spacing-07;
    border: 1px solid $border-subtle-01;

    display: grid;
    grid-template-areas:
        "icon"
        "content"
        "actions";
    grid-template-rows: auto min-content min-content;
}

.hrp--card__icon {
    fill: #c9c9c9;
    width: 60px;
    height: 60px;
}

.hrp--card__icon-wrapper {
    grid-area: icon;
}

.hrp--card__content {
    grid-area: content;
}

.hrp--card__actions-wrapper {
    grid-area: actions;
    margin-top: $spacing-05;
}

.hrp--card__actions-wrapper--one-line {
    display: flex;
    column-gap: $spacing-04;
}

.hrp--card__actions-wrapper--one-line .bx--btn {
    width: calc(50% - $spacing-04 * 0.5);
}

.hrp--card__title-wrapper {
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
}

.hrp--card__title {
    font-size: 22px;
    font-weight: font-family.font-weight('semibold');
}

.hrp--card__description {
    color: #565656;
    font-size: 14px;

    ul li {
        margin-bottom: $spacing-05;
        position: relative;
        padding-left: 30px;
    }

    ul li:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 3px;
        top: 8px;
        left: 0;
        background: $text-primary;

    }
}

.hrp--card__description--error {
    color: #dd2d4a;
    margin-bottom: $spacing-04;
}

.hrp--card__status {
    background-color: #f3f3f3;
    border-left: 3px solid;
    padding-left: calc($spacing-07 - 3px);
    padding-right: $spacing-07;
    height: 40px;
    line-height: 40px;
    margin: -1 * $spacing-07 -1 * $spacing-07 $spacing-05 -1 * $spacing-07;
}

.hrp--card__status--inactive {
    border-color: #dd2d4a;
}

.hrp--card__status--active {
    border-color: $support-success;
}

.hrp--card--password {

}
.hrp--card--password-open {
    .hide-if-open {
        display: none !important;
    }

    .show-if-open {
        display: block !important;
    }
}
