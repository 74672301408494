@use '@carbon/layout/scss/spacing' as *;

.hrp--text-fade-out {}

.hrp--text-fade-out--open .hrp--text-fade-out__container {
    overflow: visible;
    line-clamp: 999;
    -webkit-line-clamp: 999;
}

.hrp--text-fade-out__container {
    line-clamp: 5;
    box-orient: vertical;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: $spacing-04;
    word-break: break-word;

    * {
        margin-bottom: 0 !important;
    }
}

.hrp--text-fade-out__button {
    display: none;
}

.hrp--text-fade-out--needed .hrp--text-fade-out__button {
    display: inline;
}

.hrp--text-fade-out--open .hrp--text-fade-out__button {
    display: none;
}
