@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;
@use '../carbon/custom/config' as *;

.hrp-layout--container {
    margin: 0 auto;

    @include breakpoint-down(md) {
        padding-left: $spacing-04;
        padding-right: $spacing-04;

        // TODO: remove --row style and replace with .hrp-layout--without-container
        .#{$prefix}--row,
        .hrp-layout--without-container {
            margin-left: -$spacing-04;
            margin-right: -$spacing-04;
        }

        .hrp-layout--cols > [class*="bx--col"] {
            padding-left: $spacing-04;
            padding-right: $spacing-04;
        }
    }

    @include breakpoint-up(md) {
        padding-left: $spacing-05;
        padding-right: $spacing-05;
    }

    @include breakpoint-up(xlg) {
        max-width: 1280px;
    }
}

.hrp-layout--gray {
    background-color: $layer-01;
}

.hrp-layout--white {
    background-color: $background;
}

.hrp-layout--flex {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-07;
    margin-bottom: $spacing-08;
}

.hrp-layout__head {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: $spacing-05;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0 !important;
    }
}
