@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/grid/scss/breakpoint' as *;

.hrp-ag--paging {
    order: 3;
    margin-top: $spacing-05;
}

.hrp-ag--paging__wrapper {
    display: flex;
    flex-wrap: wrap;
    order: 3;
}

.hrp-ag--paging__pages {
    margin-left: auto;
    text-align: right;
    max-width: 100%;
    overflow-x: auto;

    @include breakpoint-down(lg) {
        margin-right: auto;
    }
}

.hrp-ag--paging-total {
    font-size: 20px;

    @include breakpoint-down(lg) {
        flex: 0 0 100%;
        max-width: 100%;
        order: -1;
        margin-bottom: $spacing-02;
    }

    @include breakpoint-up(lg) {
        margin-left: $spacing-03;
    }
}

.hrp-ag--paging__sizes {
    justify-content: center;

    @include breakpoint-down(md) {
        align-items: center;
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: $spacing-05;

        .toolbar__limiter-wrapper {
            justify-content: center;
        }
    }
}

@include breakpoint-down(lg) {
    .hrp-ag--mobile-action--paging {
        display: none;
    }
}
