@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/styles/scss/theme' as *;
@use '../carbon/custom/variables' as *;

.page-layout-2columns-right {
    .columns {
        @include breakpoint-up('lg') {
            display: -ms-grid;
            display: grid;
            grid-template-areas: 'content sidebar' 'content additional';
            -ms-grid-columns: 1fr $spacing-06 $card-width;
            grid-template-columns: 1fr $card-width;
            -ms-grid-rows: min-content 1fr;
            grid-template-rows: -webkit-min-content 1fr;
            grid-template-rows: min-content 1fr;
            -webkit-column-gap: $spacing-07;
            -moz-column-gap: $spacing-07;
            column-gap: $spacing-07;
        }
    }

    .column.main {
        grid-area: content;
        overflow: hidden;

        @include breakpoint-down(md) {
            max-width: 100%;
        }
    }

    .sidebar-additional {
        grid-area: additional;
    }

    .sidebar-main {
        grid-area: sidebar;
    }
}

.page-layout-brand {
    @extend .page-layout-2columns-left;
}

.page-layout-discount {
    .filters__list {
        display: none;
    }
}

body .page-main {
    margin-bottom: 4rem;
}
