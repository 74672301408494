@use '@carbon/grid/scss/breakpoint' as *;
@use '@carbon/layout/scss/spacing' as *;
@use '../carbon/custom/config' as *;
@use '../carbon/custom/variables' as *;

.category-view {
    margin-bottom: $grid-gutter;
}

@include breakpoint-up(md) {
    .category-view {
        display: -ms-grid;
        display: grid;
        grid-template-areas: "image description";
        column-gap: $spacing-07;
    }

    .category-view--full-screen {
        display: block !important;
    }

    .category-view--full-screen .category-image {
        margin-bottom: 1rem;
    }
}

@include breakpoint(md) {
    .category-view {
        -ms-grid-columns: 1fr 1rem 1fr;
        grid-template-columns: 1fr 1fr;
    }

    .category-image {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }

    .category-description {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }
}

@include breakpoint-up(lg) {
    .category-view {
        -ms-grid-columns: $card-width 1fr;
        grid-template-columns: $card-width 1fr;
    }
}

.category-image {
    grid-area: 'image';

    img {
        width: 100%;
        height: auto;
    }

    @include breakpoint-down(md) {
        margin-bottom: $grid-gutter;
    }
}

.category-description {
    grid-area: 'description';
    @extend .cms-content;
    margin-bottom: 0;

    img {
        max-width: 100%;
    }
}
