@forward '@carbon/styles/scss/themes';
@use '@carbon/styles/scss/themes';

// Predefine carbon variables
$theme--hrp: map-merge(themes.$white, (
    button-primary: #0d4c8b,
    button-secondary: #171717,
    button-tertiary: #0d4c8b,
    interactive: #0d4c8b,
    background: #ffffff,
    layer-01: #f3f3f3,
    layer-02: #f3f3f3,
    layer-accent-01: #dcdcdc,
    border-subtle-01: #dcdcdc,
    link-primary: #0090FF,
    support-error: #dd2d4a,
    support-success: #079b6c,
    support-warning: #fdd13a,
    support-info: #0d4c8b,
    focus: #0d4c8b,
    button-danger-primary: #da1e28,
    button-danger-secondary: #da1e28,
    border-strong-01: #f3f3f3,
    border-interactive: #0d4c8b,
    text-placeholder: rgba(22, 22, 22, 0.2),
    text-tertiary: #757575,

    header-logo-width--sm: 55px,
    header-logo-height--sm: 32px,
    header-logo-width--md: 80px,
    header-logo-height--md: 48px,
    footer-logo-width--sm: 80px,
    footer-logo-height--sm: 32px,
    footer-logo-width--md: 80px,
    footer-logo-height--md: 48px,
)) !default;

$theme--fixdirect: map-merge($theme--hrp, (
    button-primary: #027a2c,
    button-secondary: #171717,
    button-tertiary: #027a2c,
    interactive: #027a2c,
    background: #ffffff,
    layer-01: #f3f3f3,
    layer-02: #f3f3f3,
    layer-accent-01: #dcdcdc,
    border-subtle-01: #dcdcdc,
    link-primary: #3aa54b,
    button-primary-hover: #1e9131,
    button-primary-active: #046324,
    link-primary-hover: #046324,
    button-tertiary-hover: #1e9131,
    support-error: #dd2d4a,
    support-success: #079b6c,
    support-warning: #fdd13a,
    support-info: #0d4c8b,
    focus: #027a2c,
    highlight: #027a2c,

    header-logo-width--sm: 120px,
    header-logo-height--sm: 32px,
    header-logo-width--md: 180px,
    header-logo-height--md: 32px,
    footer-logo-width--sm: 200px,
    footer-logo-height--sm: 32px,
    footer-logo-width--md: 200px,
    footer-logo-height--md: 32px,
)) !default;

@use '@carbon/styles/scss/theme' with (
  $theme: $theme--hrp
);

:root {
    @include theme.theme();
}
body.fixdirect {
    @include theme.theme($theme--fixdirect);
}
