@use '@carbon/layout/scss/spacing' as *;

.hrp-ag--table-action {

}

.hrp-ag--table-action__list {
    display: flex;
    gap: $spacing-05;
}

.hrp-ag--table-action__list-item {

}
