@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

$_password-default                   : $layer-01 !default;
$_password-weak                      : #ffafae !default;
$_password-medium                    : #ffd6b3 !default;
$_password-strong                    : #c5eeac !default;
$_password-very-strong               : #81b562 !default;

// TODO: dart-sass fix [data-role="password-strength-meter"],
.password-strength-meter {
    background-color: $_password-default;
    position: relative;
    z-index: 1;
    padding: $spacing-02 $spacing-05;

    &-container {
        width: 100%;
    }

    &:before {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .password-none &,
    &.password-none {
        &:before {
            background-color: $_password-default;
            width: 100%;
        }
    }

    .password-weak &,
    &.password-weak {
        &:before {
            background-color: $_password-weak;
            width: 25%;
        }
    }

    .password-medium &,
    &.password-medium {
        &:before {
            background-color: $_password-medium;
            width: 50%;
        }
    }

    .password-strong &,
    &.password-strong {
        &:before {
            background-color: $_password-strong;
            width: 75%;
        }
    }

    .password-very-strong &,
    &.password-very-strong {
        &:before {
            background-color: $_password-very-strong;
            width: 100%;
        }
    }
}
