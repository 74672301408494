@use '@carbon/layout/scss/spacing' as *;
@use '@carbon/themes/scss/tokens' as *;

@mixin main-table {
    box-sizing: border-box;
    width: 100%;
    border-collapse: collapse;
    background-color: $layer-01;
    font-size: 14px;
    line-height: 20px;

    th, td {
        border: 1px solid $border-strong-02;
        padding: $spacing-02 $spacing-03;
        vertical-align: middle;
        text-align: left;
    }

    th {
        font-weight: 500;
    }
}
